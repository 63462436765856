import styled from "styled-components";
import Masonry from 'react-masonry-component';

export const PostPageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  color: white;
`;

export const PostsCardWrapper = styled(Masonry)`
  /* display: flex; */
  /* gap: 20px; */
  /* grid-template-columns: repeat(3, 1fr); */
  /* flex-wrap: wrap; */
  position: relative;
  width: 100%;
  
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  background-color: #182335;
  /* margin-top: 5px; */
  border-radius: 100px;
  padding: 5px;
  box-shadow: 0 0 0 1px white;
  gap: 5px;
  margin-bottom: 20px;
  user-select: none;
`;

export const FilterItem = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ active }) => (active ? "black" : "transparent")};
  box-shadow: ${({ active }) => (active ? "0 0 0 1px white" : "0")};
  /* margin-bottom: 5px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg,
  path {
    stroke: white;
  }
  &:last-child {
    /* margin-right: 0; */
  }
`;

export const ChannelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  background-color: #182335;
  /* margin-top: 5px; */
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 0 0 1px white;
  gap: 5px;
  margin-bottom: 20px;
  user-select: none;
`;

export const ChannelItem = styled.div`
  width: fit-content;
  height: 30px;
  background-color: ${({ active }) => (active ? "black" : "transparent")};
  box-shadow: ${({ active }) => (active ? "0 0 0 1px white" : "0")};
  /* margin-bottom: 5px; */
  border-radius: 50px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg,
  path {
    stroke: white;
  }
  &:last-child {
    /* margin-right: 0; */
  }
`;
