import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

export const getPosts = ({ statusTypes, ...filter }) => ({
  type: actionTypes.GET_POSTS,
  payload: httpRequest({
    method: "GET",
    path: "post-list",
    params: {
      statusType: statusTypes,
      ...filter,
      size: 15,
    },
  }),
});
