import styled from "styled-components";

export const Row = styled.tr`
  text-align: center;
  .mw-100px {
    max-width: 100px;
  }
  td {
    img {
      height: 50px;
    }
  }
`;

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  margin: auto;
  img {
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
  }
  span {
    width: fit-content;
    margin: auto;
    display: block;
    font-size: 18px;
  }
`;
