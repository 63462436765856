import React from "react";

import { ReactComponent as ErrorSign } from "../../assets/icons/error-sign.svg";
import {
  ErrorSignBox,
  ErrorText,
  ErrorWrapper,
  LoadingSignBox,
  LoadingText,
  LoadingWrapper,
} from "./styles";

function LoadingBox() {
  return (
    <LoadingWrapper>
      <LoadingSignBox/>
      <LoadingText>Loading...</LoadingText>
    </LoadingWrapper>
  );
}

export default LoadingBox;
