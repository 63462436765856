import React from "react";
import { useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useHttpRequest from "../../hooks/useHttpRequest";
import { LoginPageWrapper } from "./styles";
import TelegramLoginButton from "react-telegram-login";

function LoginPage() {
  const navigate = useNavigate();
  const [requestProcess, makeRequest] = useHttpRequest({
    onSuccess: (res) => {
      console.log(res);
      localStorage.setItem("access_token", res.data.accessToken);
      localStorage.setItem("refresh_token", res.data.refreshToken);
      navigate("/");
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    // console.log();
    // console.log();
    const chatID = searchParams.get("chatID");
    const secretKey = searchParams.get("secretKey");
    if (chatID && secretKey) {
      makeRequest({
        method: "GET",
        path: "login",
        params: { chatID, secretKey },
      });
    }
  }, []);

  const handleTelegramResponse = (response) => {
    console.log(response);
    makeRequest({
      method: "GET",
      path: "login/tg-callback",
      params: response,
    });
  };

  return (
    <LoginPageWrapper>
      {/* {JSON.stringify(requestProcess.data)} <br /> */}
      <Link to={"/events"}>Events</Link> <br />
      <TelegramLoginButton
        dataOnauth={handleTelegramResponse}
        botName="it_events_uz_bot"
      />
    </LoginPageWrapper>
  );
}

export default LoginPage;
