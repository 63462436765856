import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { getMe } from "../../redux/modules/getMe";
import AddEventPage from "../addEventPage";
import AddressesPage from "../addressesPage";
import AnalyzeEventsPage from "../analyzeEvents";
import ChannelsPage from "../channelsPage";
import DashboardPage from "../dashboardPage";
import EventsPage from "../eventsPage";
import JamesBondPage from "../jamesBondPage";
import LoginPage from "../loginPage";
import OrganizersPage from "../organizersPage";
import PostPage from "../postPage";
import UsersPage from "../usersPage";
import { ContentPart, MainPageWrapper, SidebarPart } from "./styles";
import TextClassification from "../textClassification";

function MainPage() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const me = state.me;

  useEffect(() => {
    dispatch(getMe());
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainPageWrapper>
            <SidebarPart>
              <Sidebar />
            </SidebarPart>
            <ContentPart>
              <Outlet />
            </ContentPart>
          </MainPageWrapper>
        }
      >
        {/* <Routes> */}
        <Route path="/" element={<DashboardPage />} />
        <Route path="/channels" element={<ChannelsPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/post-list" element={<PostPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/addresses" element={<AddressesPage />} />
        <Route path="/organizers" element={<OrganizersPage />} />
        <Route path="/james-bond" element={<JamesBondPage />} />
        {/* <Route path="/analyze-posts" element={<AnalyzeEventsPage />} /> */}
        <Route path="/analyze-posts" element={<TextClassification />} />
        {/* </Routes> */}
      </Route>
      <Route path="/events/tg-add-event" element={<AddEventPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="*"
        element={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <h1 style={{ color: "white" }}>404 Page not found</h1>
            <Link to="/">Go Home</Link>
          </div>
        }
      />
    </Routes>
  );
}

export default MainPage;
