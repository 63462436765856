import styled from "styled-components";
import tgLogo from "../../assets/photo/telegram.png";

export const Wrapper = styled.div`
  color: #fff;
  width: 50%;
  height: fit-content;
  border-radius: 10px;
  position: relative;
  overflow: auto;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${tgLogo});
    background-size: 100px 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #229ed9;
    z-index: 0;
    opacity: 0.5;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 10px;
`;

export const InputBtnWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
`;

export const ListWrapper = styled.div``;

export const ListItem = styled.div`
  border-bottom: 1px solid lightgray;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  .text {
    display: flex;
    b {
      display: block;
      margin-right: 5px;
    }
    a {
      cursor: pointer;
      color: white;
      display: block;
    }
  }
  .button-wrapper {
    background-color: #6045e2;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  &:last-child {
    border: 0;
  }
`;

export const FoundChannel = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  .channel-name {
    margin-right: 10px;
    color: white;
  }
  .join-channel {
    background-color: #6045e2;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
  }
`;
