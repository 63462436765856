import { Button, Checkbox, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import EditEventPopUp from "../../components/editEventPopUp";
import EventCard from "../../components/eventCard";
import useHttpRequest from "../../hooks/useHttpRequest";
import { getCategories } from "../../redux/modules/getCategories";
import { getEvents } from "../../redux/modules/getEvents";
import {
  DateBox,
  DatePickerWrapper,
  EventCardPoster,
  EventCardText,
  EventCategoriesWrapper,
  EventsCard,
  EventsCardWrapper,
  EventsPageWrapper,
  FilterBtn,
  FilterList,
  FilterListWrapper,
  FormatBtn,
} from "./styles";
import { DateRangePicker } from "react-date-range";
import GoogleMapReact from "google-map-react";
import { addDays } from "date-fns";
import { displayDate, displayDays } from "../../utils/dateTimeManager";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function EventsPage() {
  const [edit, setEdit] = useState({ edit: false, data: {} });
  const [addPopUp, setAddPopUp] = useState(false);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(Date.now()),
      endDate: null,
      // endDate: new Date(Date.now()+50000000),
      // addDays(new Date(), 7)
      key: "selection",
    },
  ]);

  const [filter, setFilter] = useState({
    format: { open: false, type: "all" }, // all | online | offline | hybrid
    status: { open: false, type: "published" }, // published | admin review |
  });

  const [changeStatusProcess, makeChangeStatusRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(getCategories());
    },
  });

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  useEffect(() => {
    getNewEvents();
    dispatch(getCategories());
  }, []);

  const events = state.events;
  const categories = state.categories;

  const changeStatus = ({ id, active }) => {
    makeChangeStatusRequest({
      method: "PUT",
      path: `category/status/${id}`,
      params: {
        status: active,
      },
    });
  };

  const changeOpen = (name, visible) => {
    setFilter((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], open: visible },
    }));
  };

  const changeType = (name, type) => {
    setFilter((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], type },
    }));
  };

  const getNewEvents = () => {
    dispatch(
      getEvents({
        fromDate: date[0]?.startDate,
        toDate: date[0].endDate,
        format: filter.format.type,
        status: filter.status.type,
      })
    );
  };

  useEffect(() => {
    getNewEvents();
  }, [date, filter.format.type, filter.status.type]);

  return (
    <EventsPageWrapper>
      <EventCategoriesWrapper>
        {categories?.data?.map(({ id, name, active }) => (
          <Checkbox
            key={id}
            onChange={(e) => changeStatus({ id, active: e.target.checked })}
            checked={active}
          >
            {name}
          </Checkbox>
        ))}
      </EventCategoriesWrapper>
      <Button
        style={{ marginTop: "10px" }}
        type="primary"
        onClick={() => setAddPopUp(true)}
      >
        Add Event +
      </Button>
      <FilterListWrapper>
        <Popover
          content={
            <DateRangePicker
              onChange={(item) => {
                console.log(item);
                setDate([item.selection]);
              }}
              // showSelectionPreview={true}
              // moveRangeOnFirstSelection={false}
              months={1}
              ranges={date}
              direction="horizontal"
              editableDateInputs={true}
            />
          }
          trigger="click"
          open={datePickerOpen}
          onOpenChange={setDatePickerOpen}
        >
          <DateBox>
            <div className="display-date">
              {date[0]?.startDate ? displayDate(date[0]?.startDate) : ""}{" "}
              <b>{"->"}</b>{" "}
              {date[0]?.endDate ? displayDate(date[0]?.endDate) : "Continuous"}
            </div>
          </DateBox>
        </Popover>
        <Popover
          content={
            <FilterList>
              <div
                onClick={() => changeType("format", "all")}
                className={`item ${filter.format.type === "all" && "active"}`}
              >
                All
              </div>
              <div
                onClick={() => changeType("format", "online")}
                className={`item ${
                  filter.format.type === "online" && "active"
                }`}
              >
                Online
              </div>
              <div
                onClick={() => changeType("format", "offline")}
                className={`item ${
                  filter.format.type === "offline" && "active"
                }`}
              >
                Offline
              </div>
              <div
                onClick={() => changeType("format", "hybrid")}
                className={`item ${
                  filter.format.type === "hybrid" && "active"
                }`}
              >
                Hybrid
              </div>
            </FilterList>
          }
          open={filter?.format?.open}
          onVisibleChange={(visible) => changeOpen("format", visible)}
        >
          <FilterBtn>Format: {filter.format.type.toUpperCase()}</FilterBtn>
        </Popover>
        <Popover
          content={
            <FilterList>
              <div
                onClick={() => changeType("status", "published")}
                className={`item ${
                  filter.status.type === "published" && "active"
                }`}
              >
                Published
              </div>
              <div
                onClick={() => changeType("status", "admin review")}
                className={`item ${
                  filter.status.type === "admin review" && "active"
                }`}
              >
                Admin Review
              </div>
            </FilterList>
          }
          open={filter?.format?.open}
          onVisibleChange={(visible) => changeOpen("format", visible)}
        >
          <FilterBtn>Status: {filter.status.type.toUpperCase()}</FilterBtn>
        </Popover>
      </FilterListWrapper>
      <EventsCardWrapper>
        {events?.data?.events?.map((event) => (
          <EventCard
            addPopUp={addPopUp}
            setAddPopUp={setAddPopUp}
            edit={edit}
            setEdit={setEdit}
            key={event.id}
            getNewEvents={getNewEvents}
            {...event}
          />
        ))}
      </EventsCardWrapper>
      {edit.edit || addPopUp ? (
        <EditEventPopUp
          addPopUp={addPopUp}
          setAddPopUp={setAddPopUp}
          edit={edit}
          setEdit={setEdit}
        />
      ) : (
        ""
      )}
      {/* <div style={{ height: '100vh', width: '100%',backgroundColor:"white" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "YOUR KEY HERE" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onClick={(e) => {
            console.log("latitude = ", e.lat)
            console.log("longitude = ", e.lng);
        }}
        >
          <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          />
        </GoogleMapReact>
      </div> */}
    </EventsPageWrapper>
  );
}

export default EventsPage;
