import styled from "styled-components";
import { css } from "styled-components";

export const Card = styled.div`
  width: 30%;
  /* margin: calc(8% / 8); */
  background-color: #182335;
  color: white;
  /* padding: 15px; */
  border-radius: 20px;
  position: relative;
  &:hover {
    .action-box {
      display: flex;
    }
  }
`;

export const ActionBox = styled.div`
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
  /* background-color: red; */
`;

export const Action = styled.div`
  width: 50px;
  height: 50px;
  /* padding: 10px; */
  background-color: ${({ editIcon, deleteIcon }) =>
    editIcon ? "#28a745" : deleteIcon ? "#dc3545" : "#007bff"};
  border-radius: 50%;
  display: flex;
  cursor: pointer;
  margin-left: 5px;
  svg {
    margin: auto;
    path {
      stroke: white;
    }
  }
`;

export const Poster = styled.div`
  width: 100%;
  /* padding-top: 90%; */
  border-radius: 20px 20px 0 0;
  /* background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  /* background-color: lightblue; */
  /* border-radius: 50px; */
  /* overflow: hidden; */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  &::after {
    content: "";
    background-color: #000000aa;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition-duration: 0.5s;
    /* border-radius: 20px; */
  }
  &:hover {
    &::after,
    .creator {
      opacity: 1;
    }
  }
`;

export const EventPosterImg = styled.div`
  width: ${({itemsLength})=>(itemsLength>1?"50%":"100%")};
  padding-top: ${({itemsLength})=>(itemsLength>1?"45%":"90%")};
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const CreatorBox = styled.div`
  opacity: 0;
  transition-duration: 0.5s;
  width: fit-content;
  /* height: 70px; */
  background-color: #00000099;
  padding: 10px 15px 10px 10px;
  /* padding-right: 50px; */
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  border-radius: 0 20px 0 0;
`;

export const AvatarBox = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
`;

export const CreatorData = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.div`
  padding: 15px;
`;

export const Title = styled.p`
  font-weight: bold;
`;

export const DataWrapper = styled.div`
  margin: 10px 0;
`;

export const Data = styled.div``;

export const Description = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

export const publishedStatus = css`
  background-color: rgba(125, 212, 147, 0.5);
  border: 1px solid rgb(125, 212, 147);
  color: rgb(125, 212, 147);
`;

export const editingStatus = css`
  background-color: #6045e255;
  border: 1px solid rgb(144, 143, 244);
  color: rgb(144, 143, 244);
`;

export const StatusBell = styled.div`
  display: flex;
`;

export const Status = styled.div`
  width: fit-content;
  border-radius: 5px;
  padding: 5px 10px;

  margin-bottom: 10px;
  ${({ published }) => (published ? publishedStatus : editingStatus)}
`;

export const LikeCommentSubscribers = styled.div`
  display: flex;
  margin-left: auto;
  width: fit-content;
`;

export const Subscribers = styled.a`
  text-decoration: none;
  color: white;
  position: relative;
  display: flex;
  height: 30px;
  width: fit-content;
  margin-left: 10px;
  cursor: pointer;
  img {
    height: 100%;
  }
  .number {
    width: fit-content;
    background-color: red;
    padding: 2px 5px;
    border-radius: 50px;
    font-size: 14px;
    position: absolute;
    top: -3px;
    left: 20px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    border: 2px solid #182335;
  }
`;

export const Likes = styled(Subscribers)`
  .number {
    /* right: -5px;
      left: auto; */
  }
`;
export const Comments = styled(Likes)``;

export const Telegram = styled(Likes)`
  img {
    filter: grayscale(${({ posted }) => (posted ? "0%" : "100%")});
  }
`;

export const Instagram = styled(Likes)`
  img {
    filter: grayscale(${({ posted }) => (posted ? "0%" : "100%")});
  }
`;

export const MenuBox = styled.div`
  position: absolute;
  top: 100px;
  right: 100px;
`;

export const MenuCenterButton = styled.div`
  width: 50px;
  height: 50px;
  background-color: blue;
  border-radius: 50%;
`;
export const MenuButton = styled.div`
  width: 50px;
  height: 50px;
  background-color: green;
  border-radius: 50%;
`;
