import { actionTypes } from "../../constants/actionTypes";

const defaultValue = {
  loading: false,
  error: false,
  success: false,
  data: null,
};

export const promiseReducerPagination =
  (actionConst) =>
  (state = defaultValue, action) => {
    switch (action.type) {
      case `${actionConst}${actionTypes._PENDING}`:
        return {
          loading: true,
          error: false,
          success: false,
          data: state.data,
        };
      case `${actionConst}${actionTypes._FULFILLED}`:
        const res = action.payload.data;
        return {
          loading: false,
          error: false,
          success: true,
          data:
            res?.page > 0
              ? [...new Set([...state?.data, ...res?.data])]
              : [...res.data],
        };
      case `${actionConst}${actionTypes._REJECTED}`:
        return {
          loading: false,
          error: true,
          success: false,
          data: [],
        };
      default:
        return state;
    }
  };
