import moment from "moment";
import { dateInNumRegExp, dateRegExp } from "../components/postCard";

const monthList = [
  {
    regex: /yanv/gi,
    value: 1,
  },
  {
    regex: /fev/gi,
    value: 2,
  },
  {
    regex: /mart/gi,
    value: 3,
  },
  {
    regex: /apr/gi,
    value: 4,
  },
  {
    regex: /may/gi,
    value: 5,
  },
  {
    regex: /iyun/gi,
    value: 6,
  },
  {
    regex: /iyul/gi,
    value: 7,
  },
  {
    regex: /avg/gi,
    value: 8,
  },
  {
    regex: /sent/gi,
    value: 9,
  },
  {
    regex: /okt/gi,
    value: 10,
  },
  {
    regex: /noy/gi,
    value: 11,
  },
  {
    regex: /dek/gi,
    value: 12,
  },
];

const monthInText = (monthInNumber) => {
  const months = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentabr",
    "Oktabr",
    "Noyabr",
    "Dekabr",
  ];
  return months[monthInNumber - 1];
};

const weekInText = (weekInNumber) => {
  const weekdays = [
    "Yakshanba",
    "Dushanba",
    "Seshanba",
    "Chorshanba",
    "Panshanba",
    "Juma",
    "Shanba",
  ];
  return weekdays[weekInNumber];
};

const addZeroIfNeeded = (number) => {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number;
  }
};

const displayDays = ({ startDate, endDate }) => {
  const currentDate = new Date();
  let [startYear, startMonth, startDay] = startDate.split("-");
  startMonth = Number(startMonth);
  if (endDate) {
    let [endYear, endMonth, endDay] = endDate.split("-");
    endMonth = Number(endMonth);
    if (startMonth === endMonth && startYear === endYear) {
      return `${startDay} ${monthInText(startMonth)} - ${endDay} ${monthInText(
        startMonth
      )} ${String(currentDate.getFullYear()) !== startYear ? startYear : ""}`;
    } else if (startYear === endYear && startMonth !== endMonth) {
      return `${startDay} ${monthInText(startMonth)} - ${endDay} ${monthInText(
        endMonth
      )}  ${String(currentDate.getFullYear()) !== startYear ? startYear : ""}}`;
    } else if (startYear !== endYear) {
      return `${startDay} ${monthInText(
        startMonth
      )} ${startYear} - ${endDay} ${monthInText(endMonth)} ${endYear}`;
    }
  }
  const eventDate = new Date(0);
  eventDate.setFullYear(startYear);
  eventDate.setMonth(startMonth - 1);
  eventDate.setDate(startDay);
  return `${startDay} ${monthInText(startMonth)} (${weekInText(
    eventDate.getDay()
  )}) ${String(currentDate.getFullYear()) !== startYear ? startYear : ""}`;
};

const displayTime = ({ startTime, endTime }) => {
  const [sHour, sMinute] = startTime.split(":");
  if (endTime) {
    const [eHour, eMinute] = endTime.split(":");
    return `${sHour}:${sMinute} - ${eHour}:${eMinute}`;
  }
  return `${sHour}:${sMinute}`;
};

const displayDate = (date) => {
  return `${date.getDate()} - ${monthInText(
    date.getMonth() + 1
  )} ${date.getFullYear()}`;
};

const getDateData = (date, option) => {
  // date = JSON.stringify(date);
  const currentDate = new Date(Date.now());
  date.match(dateRegExp);
  const firstIf = dateRegExp.test(date);
  const secondIf = dateInNumRegExp.test(date);
  // console.log();
  // console.log(
  //   "OutsideIF: ",
  //   option,
  //   date,
  //   typeof date,
  //   "\nif1: ",
  //   firstIf,
  //   "\nif2: ",
  //   secondIf,
  //   // "\nMatch: ",
  //   // match
  // );

  if (firstIf) {
    // console.log(option, "firstIF");
    let month = -1;
    let day = -1;
    monthList.forEach(({ regex, value }) => {
      if (regex.test(date)) {
        month = value;
      }
    });
    if (month === -1) {
      month = currentDate.getMonth() + 1;
    }
    if (/[0-9][0-9]/gi.test(date)) {
      day = Number(date.match(/[0-9][0-9]/gi)[0]);
    } else if (/[0-9]/gi.test(date)) {
      day = Number(date.match(/[0-9]/gi)?.[0]);
    }
    const dateResult = {
      day,
      month,
      year: currentDate.getFullYear(),
      currentYear: new Date(Date.now()).getFullYear(),
    };
    // console.log("DateResult: ", dateResult, option);
    return dateResult;
  } else if (secondIf) {
    // console.log(option, "secondIF");
    return {
      day: -1,
      month: -1,
      year: -1,
    };
  } else {
    // console.log(option, "thirdIF");
    const eventDate = moment(date);
    // console.log("date is Valid: ", date, eventDate);
    date = Number(date);
    if (date) {
      const rDate = new Date(Date.now());

      rDate.setDate(date);

      if (currentDate <= rDate) {
        return {
          day: rDate.getDate(),
          month: rDate.getMonth() + 1,
          year: rDate.getFullYear(),
        };
      } else {
        if (rDate.getMonth() === 11) {
          rDate.setMonth(0);
          rDate.setFullYear(rDate.getFullYear + 1);
          return {
            day: rDate.getDate(),
            month: rDate.getMonth() + 1,
            year: rDate.getFullYear(),
          };
        } else {
          rDate.setMonth(rDate.getMonth() + 1);
          return {
            day: rDate.getDate(),
            month: rDate.getMonth() + 1,
            year: rDate.getFullYear(),
          };
        }
      }
    } else if (eventDate.isValid()) {
      return {
        day: eventDate.date(),
        month: eventDate.month() + 1,
        year: eventDate.year(),
      };
    }

    return {
      day: -1,
      month: -1,
      year: -1,
    };
  }
};

export {
  monthInText,
  weekInText,
  addZeroIfNeeded,
  displayDays,
  displayTime,
  displayDate,
  getDateData,
};
