import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserTableRow from "../../components/userTableRow";
import { getUsers } from "../../redux/modules/getUsers";
import { UsersPageWrapper, UsersTable } from "./styles";

function UsersPage() {
  const state = useSelector((state) => state);
  const users = state.users;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <UsersPageWrapper>
      <UsersTable>
        <thead>
          <tr>
            <th>avatar</th>
            {/* <th>id</th> */}
            <th>Full Name</th>
            <th>
              Username <br />
              Phone Number
            </th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {users?.data?.map((user) => (
            <UserTableRow {...user} />
          ))}
        </tbody>
      </UsersTable>
    </UsersPageWrapper>
  );
}

export default UsersPage;
