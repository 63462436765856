import React, { useEffect } from "react";
import {
  DashboardWrapper,
  StatImgPart,
  StatTextPart,
  UserAmountChart,
  UserAmountStat,
} from "./styles";
import tgLogo from "../../assets/photo/telegram.png";
import igLogo from "../../assets/photo/instagram.png";
import tgBotLogo from "../../assets/photo/bot.png";
import upward from "../../assets/photo/up-right-arrow.png";
import downward from "../../assets/photo/down-right-arrow.png";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { getChannelMemberAmountStat } from "../../redux/modules/getChannelMemberAmountStat";
import CountUp from "react-countup";
import { getBotUsersStat } from "../../redux/modules/getBotUsersStat";

const renderActiveShape = (total) => (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"white"}>
        {total||0}
      </text>
      {/* <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      /> */}
      {/* <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      /> */}
      {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text> */}
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

function DashboardPage() {
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const data01 = [
    // { name: "All Users", value: 400 },
    // { name: "Have registered", value: 200 },
    // { name: "Have not registered", value: 120 },
    // { name: "Organizers", value: 30 },
    // { name: "Non-organizers", value: 230 },
    // { name: 'Group C', value: 300 },
    // { name: 'Group D', value: 200 },
    // { name: 'Group E', value: 278 },
    // { name: 'Group F', value: 189 },
  ];

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const channelsMembersAmount = state.channelsMembersAmountStat;
  const ms = channelsMembersAmount?.data;
  const botUsersStat = state.botUsersStat;

  useEffect(() => {
    dispatch(getChannelMemberAmountStat());
    dispatch(getBotUsersStat());
  }, []);

  return (
    <DashboardWrapper>
      <UserAmountStat grid={"telegram"}>
        <StatImgPart>
          <img src={tgLogo} alt="tg logo" />
        </StatImgPart>
        <StatTextPart>
          <p className="amount">
            <CountUp end={ms?.tg_channel} duration={0.5} />
          </p>
          <p className="diff">
            <img src={upward} alt="arrow" />
            +23
          </p>
        </StatTextPart>
      </UserAmountStat>
      <UserAmountStat grid={"instagram"}>
        <StatImgPart>
          <img src={igLogo} alt="tg logo" />
        </StatImgPart>
        <StatTextPart>
          <p className="amount">
            <CountUp end={ms?.instagram} duration={0.5} />
          </p>
          <p className="diff">
            <img src={downward} alt="arrow" />
            -23
          </p>
        </StatTextPart>
      </UserAmountStat>
      <UserAmountStat grid={"tg-bot"}>
        <StatImgPart>
          <img src={tgBotLogo} alt="tg logo" />
        </StatImgPart>
        <StatTextPart>
          <p className="amount">
            <CountUp end={ms?.tg_bot} duration={0.5} />
          </p>
          <p className="diff">
            <img src={upward} alt="arrow" />
            +23
          </p>
        </StatTextPart>
      </UserAmountStat>
      <UserAmountChart grid="amount-stat-chart">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            //   width={180} height={30}
            data={data}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fill: "white" }} color={"white"} />
            {/* <XAxis dataKey="name" tick={{stroke: 'red', strokeWidth: 2}} /> */}
            <YAxis tick={{ fill: "white" }} />
            <Tooltip
              cursor={false}
              wrapperStyle={{ backgroundColor: "black" }}
            />
            {/* <Legend /> */}
            <Bar dataKey="uv" fill="#6045e2" radius={50} barSize={40} />
          </BarChart>
        </ResponsiveContainer>
      </UserAmountChart>
      <UserAmountStat grid={"bot-user-stat"}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={[
                { name: "", value: 0 },
                ...Object.entries(botUsersStat?.data || {})
                  .filter(([name, value]) => name !== "total")
                  .map(([name, value]) => ({ name, value })),
              ]}
              cx="50%"
              cy="50%"
              outerRadius={80}
              innerRadius={50}
              startAngle={90}
              endAngle={450}
              fill="#6045e2"
              label={{ fill: "white" }}
              activeIndex={0}
              activeShape={renderActiveShape(botUsersStat?.data?.total)}
            />
            {/* <Pie dataKey="value" data={data02} 
        //   cx={500} cy={200}
           innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
            {/* <Tooltip /> */}
          </PieChart>
        </ResponsiveContainer>
      </UserAmountStat>
      {/* <UserAmountStat>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, accusamus
        odio, quidem facilis assumenda ratione hic nam, pariatur recusandae
        placeat dolor debitis itaque ex ad. Magni excepturi quas fugiat
        repellat?
      </UserAmountStat> */}
      DashboardPage
    </DashboardWrapper>
  );
}

export default DashboardPage;
