import styled from "styled-components";

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: lightcoral;
  position: relative;
  z-index: 999;
`;

export const ErrorSignBox = styled.div`
  width: 100px;
  height: 100px;
  svg{
    width: 100%;
    height: 100%;
  }
`;

export const ErrorText = styled.p`
  margin: 0;
`;
