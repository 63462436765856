import { combineReducers } from "redux";
import { actionTypes } from "../../constants/actionTypes";
import { promiseReducer } from "../modules/promiseReducer";
import { promiseReducerPagination } from "../modules/promiseReducerPagination";

const rootReducer = combineReducers({
  channelsMembersAmountStat: promiseReducer(
    actionTypes.GET_CHANNELS_MEMBERS_AMOUNT_STAT
  ),
  events: promiseReducer(actionTypes.GET_EVENTS),
  users: promiseReducer(actionTypes.GET_USERS),
  categories: promiseReducer(actionTypes.GET_CATEGORIES),
  igProfile: promiseReducer(actionTypes.GET_INSTAGRAM_PROFILE),
  reservedWords: promiseReducer(actionTypes.GET_RESERVED_WORDS),
  addresses: promiseReducer(actionTypes.GET_ADDRESSES),
  organizers: promiseReducer(actionTypes.GET_ORGANIZERS),
  channels: promiseReducer(actionTypes.GET_CHANNEL_LIST),
  botUsersStat: promiseReducer(actionTypes.GET_BOT_USERS_STAT),
  me: promiseReducer(actionTypes.GET_ME),
  posts: promiseReducerPagination(actionTypes.GET_POSTS),
});

export default rootReducer;
