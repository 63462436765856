import styled from "styled-components";
import Masonry from "react-masonry-component";

export const MainWrapper = styled.div`
  /* border: 2px solid white; */
  /* background-color: #182335; */
  /* border-radius: 20px; */
  /* color: white; */
  position: absolute;
  width: ${({ preview }) => (preview ? "350px" : "calc((100% - 40px) / 3)")};
  /* height: fit-content; */
  z-index: 2;
  /* display: inline; */
  margin: 0 0 20px;

  ${({ preview }) =>
    preview &&
    `
  position: relative;
  &::after {
    content: "";
    width: 10px;
    height: calc(100% - 40px);
    margin:auto;
    // background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
  }
  height: fit-content; 
  margin: auto 0 auto 10px;
  // border-left: 10px solid black;
  max-height: 90vh;
  // overflow-y: auto;
  @media only screen and (max-height: 700px) {
    // height: 90%;
  }

  @media only screen and (max-width:600px){
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  `}
`;

export const Wrapper = styled.div`
  /* border: 2px solid white; */
  background-color: #182335;
  border-radius: 20px;
  color: white;
  /* position: relative; */
  width: 100%;
  /* z-index: 2; */

  ${({ preview }) =>
    preview &&
    `
  height: fit-content; 
  // margin: auto 0 auto 10px;
  // border-left: 10px solid black;
  max-height: 90vh;
  overflow-y: auto;
  @media only screen and (max-height: 700px) {
    // height: 90%;
  }

  @media only screen and (max-width:600px){
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  `}
`;

export const Poster = styled.div`
  width: 100%;
  height: auto;
  /* padding-top: 80%; */
  position: relative;
  img {
    width: 100%;
    /* height: 100%; */
    /* object-fit: cover;
    object-position: center; */
    /* position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
  }
`;

export const TextBox = styled.div`
  width: 100%;
  padding: 15px;
  white-space: pre-line;
  /* line-break: anywhere; */
  /* word-break: break-all; */
   b{
background-color: #E3DFFD;
color: black;

  }
  
`;

export const ViewBtn = styled.a`
  width: 100%;
  border: 1px solid #6045e2;
  display: block;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin-top: 10px;
`;

export const MakePostBtn = styled.div`
  width: 100%;
  padding: 10px 20px;
  text-align: center;
  background-color: #6045e2;
  margin-top: 10px;
  border-radius: 10px;
  cursor: pointer;
`;

export const ActionWrapperCorner = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: fit-content;
`;

export const ActionBtnDots = styled.div`
  width: 40px;
  height: 40px;
  background-color: #6045e2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  svg,
  path {
    fill: white;
  }
`;

export const ActionListBtn = styled.div`
  width: 100%;
  background-color: #182335;
  margin-top: 5px;
  border-radius: 100px;
  padding: 5px;
  box-shadow: 0 0 0 1px white;
  gap: 5px;
  display: flex;
  flex-direction: column;
`;

export const ActionBtn = styled.div`
  width: 30px;
  height: 30px;
  background-color: black;
  /* margin-bottom: 5px; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg,
  path {
    stroke: white;
  }
  &:last-child {
    /* margin-bottom: 0; */
  }
`;

export const SelectionWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  max-width: 150px;
  max-height: 300px;
  background-color: lightblue;
  position: fixed;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  z-index: 999999999;
  overflow: auto;
  padding: /* 0 5px  */ 10px 5px;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  gap: 5px;
  border-radius: 10px;
  background-color: black;
  border: 1px solid white;
  user-select: none;
`;

export const SelectionHeader = styled.div`
  width: 100%;
  height: 20px;
  background-color: white;
`;

export const SelectItem = styled.div`
  width: fit-content;
  padding: 0 5px;
  cursor: pointer;
  color: white;
`;
