import styled from "styled-components";

export const OrganizerWrapper = styled.div`
  width: 100%;
  padding: 15px 25px;
`;

export const ReservedWordsWrapper = styled.div`
  width: 30%;
  background-color: #182335;
  padding: 10px;
  border-radius: 10px;
`;

export const WordsWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const Word = styled.div`
  margin: 0 8px 0 0;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  /* border: 1px solid black; */
  padding: 0px 10px;
  background-color: lightgrey;
  border-radius: 10px;
  span {
    display: flex;
    align-items: center;
  }
  &:hover {
    border-radius: 10px 0 0 10px;
    .x {
      display: block;
    }
  }
`;

export const DeleteWord = styled.div`
  height: 100%;
  position: absolute;
  right: -26px;
  top: 0;
  bottom: 0;
  background-color: red;
  padding: 1px;
  border-radius: 0 10px 10px 0;
  z-index: 999;
  display: none;
  svg {
    height: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  background-color: #182335;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 20px;
  p.title {
    font-weight: bold;
    color: white;
  }
`;

export const InputBox = styled.div`
  margin: 10px 0;
  display: flex;
`;

export const ImageBoxWrapper = styled.div`
  width: 100%;
  height: 150px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  .img-wrapper {
    width: fit-content;
    height: fit-content;
    position: relative;
  }
`;

export const CloseBox = styled.div`
  width: 40px;
  height: 40px;
  border: 2px solid red;
  position: absolute;
  top: -20px;
  right: -20px;
  border-radius: 20px;
  background-color: white;
  padding: 5px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    stroke: red;
  }
`;

export const Label = styled.label`
  width: 60px;
  height: 60px;
  /* background-color: red; */
  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: white;
    }
  }
  input {
    display: none;
  }
`;


export const OrganizerListWrapper = styled.div`
  width: 100%;
  background-color: #182335;
  border-radius: 20px;
  padding: 15px;
`;

export const OrganizerBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  background-color: #00000040;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &:hover{
    background-color: black;
  .action {
    display:flex;
  }
  }
  

  /* &:hover {
    background-color: black;
    .action {
      display: flex;
    }
  } */

  .logo {
    width: 50px;
    height: auto;
    border-radius: 10px;
  }
  .address-name {
    width: calc(100% - 60px);
  }
`;

export const ActionBox = styled.div`
  display: none;
  position: absolute;
  top: -15px;
  right: -15px;
`;

export const ActionButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: white;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid lightgreen;
  display: flex;
  margin-left: 5px;
  svg {
    width: 100%;
    height: 100%;
    margin: auto;
    path {
      stroke: lightgreen;
    }
  }
`;

export const EditButton = styled(ActionButton)`
  border-color: #02b902;
  &:hover {
    background-color: #02b902;
    svg {
      path {
        stroke: white;
      }
    }
  }
  svg {
    path {
      stroke: #02b902;
    }
  }
`;

export const DeleteButton = styled(ActionButton)`
  border-color: red;
  &:hover {
    background-color: red;
    svg {
      path,
      line {
        stroke: white;
      }
    }
  }
  svg {
    path,
    line {
      stroke: red;
    }
  }
`;


export const SwitchBox = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`;