import styled from "styled-components";

export const EventsPageWrapper = styled.div`
  width: 100%;
  padding: 20px;
`;

export const EventsCardWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 20px;
  /* grid-template-columns: repeat(3, 1fr); */
  flex-wrap: wrap;

`;

export const EventCategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  span {
    color: white !important;
  }
`;

export const DateBox = styled.div`
  width: fit-content;
  padding: 10px 20px;
  border: 1px solid #6045e2;
  background-color: #6045e2;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  .display-date {
    color: white;
  }
`;

export const DatePickerWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 50px;
  left: 0px;
  background-color: #6045e2;
  padding: 3px;
`;

export const FilterListWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
`;

export const FilterBtn = styled.div`
  color: white;
  background-color: #6045e2;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
`;

export const FilterList = styled.div`
  .item{
    cursor: pointer;
    padding: 5px 15px;
    &:hover{
      background-color: lightgrey;
    }
  }
  .active{
    color: white;
    background-color: #6045e2;
    &:hover{
      background-color: #6045e2;
    }
  }
`;