import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditEventPopUp from "../../components/editEventPopUp";
import PostCard from "../../components/postCard";
import { getPosts } from "../../redux/modules/getPosts";
import {
  ChannelItem,
  ChannelWrapper,
  FilterItem,
  FilterWrapper,
  PostPageWrapper,
  PostsCardWrapper,
} from "./styles";

import { ReactComponent as YesIcon } from "../../assets/icons/add-circle.svg";
import { ReactComponent as NoIcon } from "../../assets/icons/minus-circle.svg";
import { ReactComponent as RepeatIcon } from "../../assets/icons/refresh-circle.svg";
import { Button, message } from "antd";
import { getOrganizers } from "../../redux/modules/getOrganizers";
import { getChannelList } from "../../redux/modules/getChannelList";
import { httpRequest } from "../../utils/httpRequest";

function PostPage() {
  const dispatch = useDispatch();
  const [makePost, setMakePost] = useState({ popUp: false, data: {} });
  const [filter, setFilter] = useState({
    // statusTypes: ["UNKNOWN"],
    statusTypes: ["UNKNOWN", "EVENT", "NOT_EVENT", "REPEAT"],
    channels: [],
  });
  const [createdBefore, setCreatedBefore] = useState(new Date(Date.now()));
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(15);

  const [deletable, setDeletable] = useState([]);
  const [onlyDeletable, setOnlyDeletable] = useState(false);

  // const [hashtags, setHashtags] = useState([]);

  const state = useSelector((state) => state);
  const posts = state.posts;
  const channels = state.channels;

  useEffect(() => {
    setPage(0);
    dispatch(getPosts({ ...filter, createdBefore, page: 0, size }));
  }, [filter]);

  useEffect(() => {
    dispatch(getChannelList());
    dispatch(getOrganizers());
  }, []);

  const toggleST = (type) => {
    if (type === "ALL") {
      if (
        filter.statusTypes.includes("UNKNOWN") &&
        filter.statusTypes.includes("EVENT") &&
        filter.statusTypes.includes("NOT_EVENT") &&
        filter.statusTypes.includes("REPEAT")
      ) {
        deactivateAllST();
      } else {
        activateAllST();
      }
    } else {
      const exist = filter.statusTypes.find((t) => t === type);
      if (exist) {
        removeST(type);
      } else {
        addST(type);
      }
    }
  };

  const addST = (type) => {
    setFilter((prev) => ({
      ...prev,
      statusTypes: [...new Set([...prev.statusTypes, type])],
    }));
  };

  const removeST = (type) => {
    setFilter((prev) => ({
      ...prev,
      statusTypes: prev.statusTypes.filter((t) => t !== type),
    }));
  };

  const activateOnlyST = (type) => {
    setFilter((prev) => ({
      ...prev,
      statusTypes: [type],
    }));
  };

  const activateAllST = () => {
    setFilter((prev) => ({
      ...prev,
      statusTypes: ["UNKNOWN", "EVENT", "NOT_EVENT", "REPEAT"],
    }));
  };

  const deactivateAllST = () => {
    setFilter((prev) => ({
      ...prev,
      statusTypes: [],
    }));
  };

  const toggleChannel = (channelId) => {
    const exist = filter.channels.includes(channelId);
    if (exist) {
      removeChannel(channelId);
    } else {
      addChannel(channelId);
    }
  };

  const addChannel = (type) => {
    setFilter((prev) => ({
      ...prev,
      channels: [...new Set([...prev.channels, type])],
    }));
  };

  const removeChannel = (channelId) => {
    setFilter((prev) => ({
      ...prev,
      channels: prev.channels.filter((t) => t !== channelId),
    }));
  };

  const activateOnlyChannel = (channelId) => {
    setFilter((prev) => ({
      ...prev,
      channels: [channelId],
    }));
  };

  const deleteAllDeletable = async () => {
    for (let i = 0; i < deletable.length; i++) {
      const id = deletable[i];
      try {
        await httpRequest({
          method: "DELETE",
          path: `post-list/${id}`,
        });
        message.success("Deleted post: " + id);
      } catch (err) {
        message.error(JSON.stringify(err));
      }
    }
    dispatch(getPosts(filter));
  };

  // useEffect(() => {
  //   console.log("Deletable: ", deletable);
  // }, [deletable]);

  return (
    <PostPageWrapper>
      <FilterWrapper>
        <FilterItem
          active={filter.statusTypes.includes("UNKNOWN")}
          onClick={() => toggleST("UNKNOWN")}
          onDoubleClick={() => activateOnlyST("UNKNOWN")}
        >
          UN
        </FilterItem>
        <FilterItem
          active={filter.statusTypes.includes("EVENT")}
          onClick={() => toggleST("EVENT")}
          onDoubleClick={() => activateOnlyST("EVENT")}
        >
          <YesIcon />
        </FilterItem>
        <FilterItem
          active={filter.statusTypes.includes("NOT_EVENT")}
          onClick={() => toggleST("NOT_EVENT")}
          onDoubleClick={() => activateOnlyST("NOT_EVENT")}
        >
          <NoIcon />
        </FilterItem>
        <FilterItem
          active={filter.statusTypes.includes("REPEAT")}
          onClick={() => toggleST("REPEAT")}
          onDoubleClick={() => activateOnlyST("REPEAT")}
        >
          <RepeatIcon />
        </FilterItem>
        <FilterItem
          active={
            filter.statusTypes.includes("UNKNOWN") &&
            filter.statusTypes.includes("EVENT") &&
            filter.statusTypes.includes("NOT_EVENT") &&
            filter.statusTypes.includes("REPEAT")
          }
          onClick={() => toggleST("ALL")}
        >
          ALL
        </FilterItem>
      </FilterWrapper>
      <ChannelWrapper>
        {channels?.data?.map(({ id, title, username }) => (
          <ChannelItem
            active={filter.channels.includes(id)}
            onClick={() => toggleChannel(id)}
            onDoubleClick={() => activateOnlyChannel(id)}
            key={id}
          >
            {title}
          </ChannelItem>
        ))}
      </ChannelWrapper>
      {/* <Masonry
        className={"my-gallery-class"} // default ''
        elementType={"div"} // default 'div'
        options={{ transitionDuration: 1000, gutter: 200 }} // default {}
        // disableImagesLoaded={false} // default false
        // updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        // imagesLoadedOptions={imagesLoadedOptions} // default {}
      /> */}
      {/* {JSON.stringify(hashtags)} */}
      <ChannelWrapper>
        <ChannelItem
          active={onlyDeletable}
          onClick={() => setOnlyDeletable(!onlyDeletable)}
        >
          Deletable
        </ChannelItem>
        <Button active={onlyDeletable} onClick={() => setDeletable([])}>
          Refresh
        </Button>
      </ChannelWrapper>
      {onlyDeletable && (
        <ChannelWrapper>
          <Button
            active={true}
            onClick={deleteAllDeletable}
          >
            Delete all Deletable
          </Button>
        </ChannelWrapper>
      )}
      <ChannelWrapper>
        {deletable.map((v) => (
          <ChannelItem active={true}>{v}</ChannelItem>
        ))}
      </ChannelWrapper>
      <PostsCardWrapper
        className={"my-gallery-class"} // default ''
        elementType={"div"} // default 'div'
        options={{
          transitionDuration: 1000,
          gutter: 20,
          resize: true,
          fitWidth: false,
          // columnWidth:"30%"
        }} // default {}
        // disableImagesLoaded={false} // default false
        // updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        // imagesLoadedOptions={imagesLoadedOptions} // default {}
      >
        {posts?.data
          // ?.sort((a, b) => a?.text?.length - b?.text?.length)
          ?.map((v, i) => (
            <PostCard
              {...v}
              setMakePost={setMakePost}
              filter={filter}
              key={i}
              deletable={deletable}
              setDeletable={setDeletable}
              onlyDeletable={onlyDeletable}
              // hashtags={hashtags}
              // setHashtags={setHashtags}
            />
          ))}
      </PostsCardWrapper>
      <Button
        onClick={() => {
          dispatch(
            getPosts({ ...filter, createdBefore, page: page + 1, size })
          );
          setPage((prev) => prev + 1);
        }}
      >
        More
      </Button>
      {makePost.popUp && (
        <EditEventPopUp makePost={makePost} setMakePost={setMakePost} />
      )}
    </PostPageWrapper>
  );
}

export default PostPage;
