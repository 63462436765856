import styled from "styled-components";

export const DashboardWrapper = styled.div`
  width: 100%;
  display: grid;
  padding: 15px 25px;
  gap: 20px;
  grid-template-areas:
    "telegram telegram instagram instagram tg-bot tg-bot"
    "amount-stat-chart amount-stat-chart amount-stat-chart amount-stat-chart bot-user-stat bot-user-stat";
`;

export const UserAmountStat = styled.div`
  grid-area: ${({ grid }) => grid};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: white;
  background-color: #182335;
  padding: 15px;
  border-radius: 20px;
  .recharts-pie-labels g.recharts-layer:first-child{
    display: none;
  }
`;

export const StatImgPart = styled.div`
  width: 60px;
  height: 60px;
  img {
    width: 100%;
    height: fit-content;
  }
`;

export const StatTextPart = styled.div`
  width: calc(100% - 70px);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  p.amount {
    width: fit-content;
    font-size: 50px;
    line-height: 100%;
    font-weight: bold;
  }
  p.diff {
    width: fit-content;
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    img {
      height: 20px;
      margin-right: 5px;
    }
  }
`;

export const UserAmountChart = styled.div`
  grid-area: ${({ grid }) => grid};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 400px;
  color: white;
  background-color: #182335;
  padding: 15px;
  border-radius: 20px;

  svg g text tspan {
    color: "white" !important;
  }
`;
