import React from "react";
import {
  LogoBox,
  NavBox,
  NavsBox,
  NavsWrapper,
  SidebarWrapper,
} from "./styles";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as ChannelsIcon } from "../../assets/icons/channels.svg";
import { ReactComponent as EventIcon } from "../../assets/icons/event.svg";
import { ReactComponent as PostIcon } from "../../assets/icons/like-shapes.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as MapIcon } from "../../assets/icons/map.svg";
import { ReactComponent as BuildIcon } from "../../assets/icons/buildings.svg";
import { ReactComponent as MessagesIcon } from "../../assets/icons/messages.svg";
import { ReactComponent as JamesBondIcon } from "../../assets/icons/james-bond.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function Sidebar() {
  const events = useSelector((state) => state.events);

  const navs = [
    {
      icon: <DashboardIcon />,
      text: "Dashboard",
      link: "/",
    },
    {
      icon: <ChannelsIcon />,
      text: "Channels",
      link: "/channels",
    },
    {
      icon: <EventIcon />,
      text: "Tadbirlar " + (events?.data?.length || 0),
      link: "/events",
    },
    {
      icon: <PostIcon />,
      text: "Postlar",
      link: "/post-list",
    },
    {
      icon: <UsersIcon />,
      text: "Foydalanuvchilar",
      link: "/users",
    },
    {
      icon: <MapIcon />,
      text: "Manzillar",
      link: "/addresses",
    },
    {
      icon: <BuildIcon />,
      text: "Tashkilotchilar",
      link: "/organizers",
    },
    {
      icon: <MessagesIcon />,
      text: "Xabarlar",
      link: "/messages",
    },
    {
      icon: <JamesBondIcon />,
      text: "JamesBond",
      link: "/james-bond",
    },
    {
      icon: <JamesBondIcon />,
      text: "AnalyzePosts",
      link: "/analyze-posts",
    },
  ];

  const { pathname } = useLocation();

  return (
    <SidebarWrapper>
      <LogoBox to={"/"}>IT Events</LogoBox>
      <NavsWrapper>
        <NavsBox>
          {navs.map(({ icon, text, link }, index) => (
            <NavBox key={index} active={link === pathname ? 1 : 0} to={link}>
              {icon} {text}
            </NavBox>
          ))}
        </NavsBox>
      </NavsWrapper>
    </SidebarWrapper>
  );
}

export default Sidebar;
