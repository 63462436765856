import styled, { keyframes } from "styled-components";

export const EditEventPopUpBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
`;

export const EventWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  /* max-height: 90vh; */
  display: flex;
  align-items: center;
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  div.close-pop-up-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

export const EditEventPopUpWrapper = styled.div`
  width: 400px;
  height: fit-content;
  max-height: 90vh;
  background-color: #182335;
  color: white;
  padding: 20px;
  border-radius: 20px;
  overflow-y: auto;
  /* position: relative; */
  margin: auto 0;
  z-index: 2;
  @media only screen and (max-height: 700px) {
    /* max-height: 90%; */
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    max-height: 100vh;
    border-radius: 0;
  }
`;

export const CloseBox = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  /* z-index: 999999999999999; */
  /* transform: scale(2); */
  svg {
    width: fit-content;
  }
`;

export const ImageBoxWrapper = styled.div`
  /* height: 200px; */
  height: fit-content;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid white;
  border-radius: 20px;
  position: relative;
  flex-wrap: wrap;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 30px;
    svg,
    path {
      width: 100px;
      height: 100px;
      stroke: white;
    }
    input {
      display: none;
    }
  }
`;

export const InputBox = styled.div`
  display: ${({ item }) => (item === 2 ? "flex" : "block")};
  width: 100%;
  margin: 10px 0;
  color: white;
  span.lang {
    color: white;
  }
  .ant-form-item-control-input {
    min-height: fit-content;
  }
`;

export const ListBox = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  li {
    display: flex;
    align-items: center;
    margin: 2px 0;
    button {
      background-color: red;
      border-radius: 50% 0 0 50%;
      border: 0;
      width: 20px !important;
      height: 20px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      svg {
        width: 17px;
      }
    }
    p {
      width: calc(100% - 25px);
    }
  }
`;

export const CategoryWrapper = styled.div`
  width: 100%;
  max-height: 200px;
  overflow: auto;
`;

export const CategoryList = styled.div`
  width: 300px;
  /* margin: auto; */
`;

export const CategoryItem = styled.div`
  width: 100%;
  /* border: 1px solid black; */
  padding: 5px 10px;
  &:hover {
    background-color: lightgray;
  }
`;

export const ImageListWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
`;

export const SingleImageBox = styled.div`
  width: 100%;
  /* height: 150px; */
  /* margin: 1%; */
  padding-bottom: 90%;
  position: relative;
  background-image: url(${({ img }) => (img ? img : "")});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  &:first-child {
    border-radius: 15px 15px 0 0;
  }
  &:last-child {
    border-radius: 0 0 15px 15px;
  }
  &:hover {
    .cancel-icon {
      display: flex;
    }
  }
  .cancel-icon {
    width: 50px;
    height: 50px;
    background-color: white;
    border: 2px solid red;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    /* display: none; */
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: red;
      svg,
      path {
        stroke: white;
      }
    }
    svg {
      width: 100%;
      height: 100%;
    }
    svg,
    path {
      stroke: red;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 15px;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ImgLoadingBox = styled.div`
  /* width: 48%; */
  width: 100%;
  height: 150px;
  /* margin: 1%; */
  padding-top: 90%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid white; */
  div {
    /* content: ""; */
    /* text-align: center; */
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 65px;
    height: 65px;
    border: 3px solid white;
    border-bottom-color: transparent;
    /* border-right-color: transparent;
    border-left-color: transparent; */
    border-radius: 50%;
    /* background-color: white; */
    animation-name: ${rotate};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

export const DFlex = styled.div`
  display: flex;
`;
