import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

export const getInstagramProfile = () => ({
  type: actionTypes.GET_INSTAGRAM_PROFILE,
  payload: httpRequest({
    path: "instagram/profile",
    method: "GET",
  }),
});
