import React, { useEffect, useState } from "react";
import {
  CategoryItem,
  CategoryList,
  CategoryWrapper,
  DFlex,
  EditEventPopUpBackground,
  EditEventPopUpWrapper,
  EventWrapper,
  ImageBoxWrapper,
  ImageListWrapper,
  ImgLoadingBox,
  InputBox,
  ListBox,
  SingleImageBox,
} from "./styles";

import { ReactComponent as AddImage } from "../../assets/icons/add-image.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Descriptions,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Popover,
  Radio,
  Row,
  Select,
  TimePicker,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import moment from "moment";
import useHttpRequest from "../../hooks/useHttpRequest";
import { parseToJson } from "../../utils/json";
import { getEvents } from "../../redux/modules/getEvents";
import regex from "../../constants/regex";
import { empty } from "../../constants/empty";
import emptyEvent from "../../constants/emptyEventForm";

import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import { getAddresses } from "../../redux/modules/getAddresses";
import { BASE_URL } from "../../constants/link";
import { getOrganizers } from "../../redux/modules/getOrganizers";
import { format as eventFormat } from "../../constants/format";
import PostCard, { dateInNumRegExp, dateRegExp } from "../postCard";
import { getDate } from "date-fns";
import { getCategories } from "../../redux/modules/getCategories";
import { getDateData } from "../../utils/dateTimeManager";

const { Option } = Select;
const { TextArea } = Input;

function EditEventPopUp({
  addPopUp,
  setAddPopUp,
  edit,
  setEdit,
  addEvent,
  chatId,
  setSubmitProcess,
  makePost,
  setMakePost,
}) {
  const state = useSelector((state) => state);
  const categories = state.categories;
  const addresses = state.addresses;
  const organizerList = state.organizers;

  const dispatch = useDispatch();

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const { from, to } = selectedDayRange;

  const [sProtocol, setSProtocol] = useState("https://");
  const [oProtocol, setOProtocol] = useState("https://");
  const [rProtocol, setRProtocol] = useState("https://");
  const [eProtocol, setEProtocol] = useState("https://");
  const [format, setFormat] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState(null);
  const [category, setCategory] = useState("");
  const [speakers, setSpeakers] = useState([]);
  const [speakerName, setSpeakerName] = useState("");
  const [speakerLink, setSpeakerLink] = useState("");
  const [speakerDescription, setSpeakerDescription] = useState("");
  const [organizers, setOrganizers] = useState([]);
  const [organizerName, setOrganizerName] = useState("");
  const [organizerLink, setOrganizerLink] = useState("");
  const [langs, setLangs] = useState([]);
  const [address, setAddress] = useState("");
  const [addressLink, setAddressLink] = useState("");
  const [addressLongitude, setAddressLongitude] = useState("");
  const [addressLatitude, setAddressLatitude] = useState("");
  const [linkToEvent, setLinkToEvent] = useState("");
  const [linkToRegistration, setLinkToRegistration] = useState("");
  const [prices, setPrices] = useState([]);
  // const [price, setPrice] = useState(null);
  const [price, setPrice] = useState(5000);
  const [priceText, setPriceText] = useState("");
  const [isFree, setIsFree] = useState(true);
  const [filesIds, setFilesIds] = useState([]);
  const [coords, setCoords] = useState([41.31439136799405, 69.2611792755127]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [categoriesVisible, setCategoriesVisible] = useState(false);
  const [organizersVisible, setOrganizersVisible] = useState(false);
  const [calcVisibility, setCalcVisibility] = useState(false);

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    console.log(e.type);
    if (e.type === "" || e.type === "") {
    }
  };

  const [emptyInputs, setEmptyInputs] = useState([]);

  const makeEmptyIfNeeded = (text) => {
    if (text === "NOT_PROVIDED" || text === "NO_NEED") {
      return "";
    }
    return text;
  };

  const [saveProcess, makeSaveRequest] = useHttpRequest();

  useEffect(() => {
    // console.log(edit);
    dispatch(getAddresses());
    dispatch(getOrganizers());
    dispatch(getCategories());
    if (edit?.edit) {
      const {
        createdAt,
        description,
        descriptionEntity,
        endDate,
        endTime,
        eventCreatorId,
        eventTypeId,
        format,
        geoLocation,
        id,
        igPostCode,
        isActive,
        isAdminReviewing,
        isPublished,
        language,
        link_to_event,
        link_to_registration,
        location_text,
        messageIdInChannel,
        organizers,
        poster_id,
        price,
        reminderId,
        speakers,
        startDate,
        startTime,
        subscribersCount,
        tenMinAgoMentioned,
        thirtyMinAgoMentioned,
        title,
        updatedAt,
        zeroMinAgoMentioned,
        eventType,
        eventTypeName,
        channelPostUrl,
        addPopUp,
        setAddPopUp,
        addressId,
        // edit,
        // setEdit,
      } = edit?.data;

      const coordinate = parseToJson(geoLocation);

      if (
        !empty.isNotEmpty(geoLocation) ||
        geoLocation === null ||
        geoLocation === ""
      ) {
        setAddressLink("");
        setAddressLongitude("");
        setAddressLatitude("");
      } else if (regex.link.test(geoLocation)) {
        setAddressLink(geoLocation);
        setAddressLongitude("");
        setAddressLatitude("");
      } else if (coordinate?.longitude && coordinate?.latitude) {
        setAddressLink("");
        setAddressLongitude(coordinate?.longitude);
        setAddressLatitude(coordinate?.latitude);
      } else {
        setAddressLink("");
        setAddressLongitude("");
        setAddressLatitude("");
      }

      // console.log(startTime.slice(0, -3));
      console.log(format);
      setFormat(format);
      setTitle(title);
      setDescription(makeEmptyIfNeeded(description));
      setFromTime(makeEmptyIfNeeded(startTime) || "");
      setToTime(endTime || "");
      console.log(eventTypeId);
      setCategory(eventTypeName);
      setSelectedLocation(addressId);
      setSpeakers(parseOldSpeakerToNew(speakers));
      // setSpeaker("");
      setOrganizers(parseOldOrganizerToNew(organizers));
      // setOrganizer();
      setLangs(parseToJson(language));
      setAddress(makeEmptyIfNeeded(location_text));
      // setAddressCoordinateOrLink(makeEmptyIfNeeded(geoLocation));
      setLinkToEvent(separateLink(link_to_event, setEProtocol));
      setLinkToRegistration(separateLink(link_to_registration, setRProtocol));
      setPrice(price);
      setFilesIds(
        makeEmptyIfNeeded(poster_id)
          ? parseToJson(makeEmptyIfNeeded(poster_id)) || [
              makeEmptyIfNeeded(poster_id),
            ]
          : []
      );
      const [sYear, sMonth, sDay] = startDate?.split("-") || [1, 2, 3];
      const [eYear, eMonth, eDay] = endDate?.split("-") || [1, 2, 3];
      setSelectedDayRange({
        from: startDate
          ? {
              day: Number(sDay),
              month: Number(sMonth),
              year: Number(sYear),
            }
          : null,
        to: endDate
          ? {
              day: Number(eDay),
              month: Number(eMonth),
              year: Number(eYear),
            }
          : null,
      });
    }

    const photoId = makePost?.data?.photoId;
    if (photoId) {
      setFilesIds([photoId]);
    }

    // return () => {
    //   // if (addEvent) {
    //   //   const s = selectedDayRange?.from;
    //   //   const e = selectedDayRange?.to;
    //   //   const event = {
    //   //     eventCreatorId: chatId,
    //   //     id: edit?.data?.id,
    //   //     format,
    //   //     title,
    //   //     description,
    //   //     // descriptionEntity,
    //   //     startDate: selectedDayRange?.from
    //   //       ? `${s.year}-${s.month}-${s.day}`
    //   //       : null,
    //   //     endDate: selectedDayRange?.to
    //   //       ? `${e.year}-${e.month}-${e.day}`
    //   //       : null,
    //   //     startTime: fromTime,
    //   //     endTime: toTime,
    //   //     speakers,
    //   //     prices,
    //   //     organizers,
    //   //     link_to_event: linkToEvent ? eProtocol + linkToEvent : linkToEvent,
    //   //     link_to_registration: linkToRegistration
    //   //       ? rProtocol + linkToRegistration
    //   //       : linkToRegistration,
    //   //     poster_id: filesIds,
    //   //     geoLocation:
    //   //       addressLongitude && addressLatitude
    //   //         ? { longitude: addressLongitude, latitude: addressLatitude }
    //   //         : // : addressLink
    //   //           // ? addressLink
    //   //           "",
    //   //     location_text: address,
    //   //     selectedLocation,
    //   //     language: langs,
    //   //     eventType: category,
    //   //   };
    //   //   makeSaveRequest({
    //   //     method: "PUT",
    //   //     path: "events/save",
    //   //     data: {
    //   //       chatId,
    //   //       ...event,
    //   //     },
    //   //   });
    //   // }
    // };
  }, []);

  const parseOldSpeakerToNew = (speakers) => {
    if (empty.isNotEmpty(speakers)) {
      speakers = parseToJson(speakers);
      if (Array.isArray(speakers) && speakers.length > 0) {
        // console.log(
        //   "type of speakers",
        //   typeof speakers[0] === "string",
        //   typeof speakers[0]
        // );
        if (typeof speakers[0] === "string") {
          return speakers.map((speaker) => {
            const speakerSplit = speaker.split("-");
            const name = speakerSplit[0]?.trim();
            const description = speakerSplit[1]?.trim();
            // console.log({
            //   speakerName: name,
            //   speakerLink: "",
            //   speakerDescription: description,
            // });
            return {
              speakerName: name,
              speakerLink: "",
              speakerDescription: description,
            };
          });
        }
        return speakers;
      }
      return [];
    }
    return [];
  };

  const parseOldOrganizerToNew = (organizers) => {
    if (empty.isNotEmpty(organizers)) {
      organizers = parseToJson(organizers);
      if (Array.isArray(organizers) && organizers.length > 0) {
        // console.log(
        //   "type of speakers",
        //   typeof organizers[0] === "string",
        //   typeof organizers[0]
        // );
        if (typeof organizers[0] === "string") {
          return organizers.map((organizer) => {
            const organizerSplit = organizer.split("-");
            const name = organizerSplit[1]?.trim();
            const link = organizerSplit[0]?.trim().startsWith("@")
              ? "https://t.me/" + organizerSplit[0].trim().slice(1)
              : organizerSplit[0];
            // console.log({
            //   organizerName: name,
            //   organizerLink: link,
            // });
            return {
              organizerName: name,
              organizerLink: link,
            };
          });
        }
        return organizers;
      }
      return [];
    }
    return [];
  };

  const separateLink = (link, setProtocol) => {
    if (link?.startsWith("https://")) {
      setProtocol("https://");
      return link.slice(8);
    } else if (link?.startsWith("http://")) {
      setProtocol("http://");
      return link.slice(7);
    } else {
      return makeEmptyIfNeeded(link);
    }
  };

  const [sendImageProcess, makeSendImageRequest] = useHttpRequest({
    onSuccess: (res) => {
      console.log("image submitted", res.data);
      setFilesIds((prevState) => [...new Set([...prevState, res.data])]);
      console.log(filesIds);
    },
  });

  // const [imagePathProcess, makeImagePathRequest] = useHttpRequest();

  const submitImage = (e) => {
    const files = e.target.files[0];
    console.log("image data", e, files);
    if (!files) {
      return;
    }
    const formData = new FormData();
    formData.append("image", files);
    makeSendImageRequest({
      method: "POST",
      data: formData,
      path: "attachment/tg-url",
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  // useEffect(() => {
  //   if (fileId) {
  //     makeImagePathRequest({
  //       method: "GET",
  //       path: "attachment/tg-url",
  //       params: {
  //         file_id: fileId,
  //       },
  //     });
  //   }
  // }, [fileId]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.Telegram.WebApp.close();
  //   }, 10 * 1000);
  // }, []);

  useEffect(() => {
    if (
      categories.success &&
      !edit?.edit &&
      !edit?.data?.eventTypeId &&
      !category
    ) {
      setCategory("");
    }
  }, [categories.success]);

  const addSpeaker = () => {
    if (
      isNotEmpty(speakerName)
      //  !== ("" && null && undefined)
      // &&
      // speakerLink !== ("" || null || undefined) &&
      // speakerDescription !== ("" || null || undefined)
    ) {
      setSpeakers([
        ...speakers,
        {
          speakerName,
          speakerLink: speakerLink ? sProtocol + speakerLink : speakerLink,
          speakerDescription,
        },
      ]);
      setSpeakerName("");
      setSpeakerLink("");
      setSpeakerDescription("");
    }
  };

  const addOrganizer = () => {
    if (
      isNotEmpty(organizerName) &&
      !organizers.some(
        ({ organizerName: name }) =>
          name.toLowerCase() === organizerName.toLowerCase()
      )
      //  &&
      // organizerLink !== ("" || null || undefined)
    ) {
      setOrganizers([
        ...organizers,
        {
          organizerName,
          organizerLink: organizerLink
            ? oProtocol + organizerLink
            : organizerLink,
        },
      ]);
      setOrganizerName("");
      setOrganizerLink("");
    }
  };

  const addPrice = () => {
    if (
      isNotEmpty(price)
      // &&
      // priceText !== ("" || null || undefined)
    ) {
      setPrices([...prices, { price, priceText }]);
      setPrice("");
      setPriceText("");
    }
  };

  const [submitEventProcess, makeSubmitEventRequest] = useHttpRequest({
    onLoading: () => {
      setSubmitProcess &&
        setSubmitProcess({
          loading: true,
          success: false,
          error: false,
        });
    },
    onSuccess: (r) => {
      dispatch(getEvents({}));
      setEdit?.({ edit: false, data: {} });
      setMakePost?.({ popUp: false, data: {} });
      setAddPopUp?.(false);
      setSubmitProcess &&
        setSubmitProcess({
          loading: false,
          success: true,
          error: false,
        });
    },
    onError: (err) => {
      console.log(err);
      message.error(String(err?.data));
      setSubmitProcess &&
        setSubmitProcess({
          loading: false,
          success: false,
          error: true,
        });
    },
  });

  const addEmptyError = (name) => {
    setEmptyInputs((prevState) => [...new Set([...prevState, name])]);
  };

  const removeEmptyError = (name) => {
    setEmptyInputs((prevState) => prevState.filter((v) => v !== name));
  };

  const isEmpty = (name) => {
    const { TITLE, ADDRESS } = emptyEvent;
    const helpText = {
      [ADDRESS]:
        "Manzil bush bulishi kerak emas. Uni Tanlang yoki qulizbilan kiriting",
      [TITLE]: "",
    };
    if (emptyInputs.includes(name)) {
      return {
        validateStatus: "error",
        help: helpText?.[name] || "Bu input bush bumasligi kerak",
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    // console.log(emptyInputs);
  }, [emptyInputs]);

  useEffect(() => {
    console.log(
      "selectedLocation",
      selectedLocation,
      !Boolean(selectedLocation),
      "address",
      address,
      !Boolean(address),
      "empty",
      !Boolean(address) && !Boolean(selectedLocation)
    );
    if (!Boolean(address) && !Boolean(selectedLocation)) {
      addEmptyError(emptyEvent.ADDRESS);
    } else {
      removeEmptyError(emptyEvent.ADDRESS);
    }
  }, [selectedLocation, address]);

  const submitEvent = () => {
    const s = selectedDayRange?.from;
    const e = selectedDayRange?.to;

    const event = {
      format,
      title,
      description,
      // descriptionEntity,
      startDate: s ? `${s.year}-${s.month}-${s.day}` : null,
      endDate: e ? `${e.year}-${e.month}-${e.day}` : null,
      startTime: fromTime || null,
      endTime: toTime || null,
      speakers,
      prices,
      organizers,
      link_to_event: linkToEvent ? eProtocol + linkToEvent : linkToEvent,
      link_to_registration: linkToRegistration
        ? rProtocol + linkToRegistration
        : linkToRegistration,
      poster_id: filesIds,
      geoLocation:
        addressLongitude && addressLatitude
          ? { longitude: addressLongitude, latitude: addressLatitude }
          : // : addressLink
            // ? addressLink
            "",
      location_text: address,
      selectedLocation,
      language: langs,
      eventType: category,
      fromMsgId: makePost?.data?.id,
    };
    console.log("====================================");
    console.log(makePost);
    console.log("====================================");

    console.log(emptyInputs);

    if (
      !category ||
      !format ||
      !title ||
      // !langs.length ||
      // !s &&
      !from ||
      // !fromTime &&
      (format !== eventFormat.ONLINE
        ? !Boolean(address) && !Boolean(selectedLocation)
        : false)
    ) {
      if (!category) {
        addEmptyError(emptyEvent.CATEGORY);
      } else {
        removeEmptyError(emptyEvent.CATEGORY);
      }

      if (!format) {
        addEmptyError(emptyEvent.FORMAT);
      } else {
        removeEmptyError(emptyEvent.FORMAT);
      }

      if (!title) {
        addEmptyError(emptyEvent.TITLE);
      } else {
        removeEmptyError(emptyEvent.TITLE);
      }

      // if (!langs.length) {
      //   addEmptyError(emptyEvent.LANGS);
      // } else {
      //   removeEmptyError(emptyEvent.LANGS);
      // }

      if (!from) {
        addEmptyError(emptyEvent.START_TIME);
      } else {
        removeEmptyError(emptyEvent.START_TIME);
      }

      // if (!fromTime) {
      //   addEmptyError(emptyEvent.FROM_TIME);
      // } else {
      //   removeEmptyError(emptyEvent.FROM_TIME);
      // }

      if (
        !Boolean(address) &&
        !Boolean(selectedLocation) &&
        format !== eventFormat.ONLINE
      ) {
        addEmptyError(emptyEvent.ADDRESS);
      } else {
        removeEmptyError(emptyEvent.ADDRESS);
      }
      console.log(emptyInputs);
      message.error("Hamma inputlarni tekshirib kuring bush qomasin");
    } else {
      // if (emptyInputs.length === 0) {
      if (addEvent) {
        makeSubmitEventRequest({
          method: "POST",
          path: `events/add-or-update-then-send-to-admin`,
          data: {
            id: edit?.data?.id,
            chatId,
            ...event,
          },
        });
      } else if (edit?.edit) {
        makeSubmitEventRequest({
          method: "PUT",
          path: `events/${edit?.data?.id}`,
          data: event,
        });
      } else {
        makeSubmitEventRequest({
          method: "POST",
          path: "events",
          data: event,
        });
      }
      // }
    }
  };

  const isNotEmpty = (text) => {
    return text !== "" && text !== null && text !== undefined && text !== 0;
  };

  const removeImg = (index) => {
    // setTimeout(() => {
    setFilesIds([]);
    // }, 0);
    setTimeout(() => {
      setFilesIds([...new Set([...filesIds.filter((v, i) => i !== index)])]);
    }, 1);
  };

  const changeCategory = (c) => {
    setCategory(c);
    !c && addEmptyError(emptyEvent.CATEGORY);
    c && removeEmptyError(emptyEvent.CATEGORY);
  };

  const changeRegistrationLink = (text) => {
    if (text?.startsWith("@")) {
      setLinkToRegistration(`t.me/${text?.slice(1)}`);
      setRProtocol("https://");
    } else if (text?.startsWith("https://")) {
      setLinkToRegistration(text?.slice(8));
      setRProtocol("https://");
    } else if (text?.startsWith("http://")) {
      setLinkToRegistration(text?.slice(7));
      setRProtocol("http://");
    } else {
      setLinkToRegistration(text);
    }
  };

  const changeSpeakerLink = (text) => {
    if (text.startsWith("@")) {
      setSpeakerLink(`t.me/${text?.slice(1)}`);
      setSProtocol("https://");
    } else if (text?.startsWith("https://")) {
      setSpeakerLink(text?.slice(8));
      setSProtocol("https://");
    } else if (text?.startsWith("http://")) {
      setSpeakerLink(text?.slice(7));
      setSProtocol("http://");
    } else {
      setSpeakerLink(text);
    }
  };

  const changeEventLink = (text) => {
    if (text.startsWith("@")) {
      setLinkToEvent(`t.me/${text?.slice(1)}`);
      setEProtocol("https://");
    } else if (text?.startsWith("https://")) {
      setLinkToEvent(text?.slice(8));
      setEProtocol("https://");
    } else if (text?.startsWith("http://")) {
      setLinkToEvent(text?.slice(7));
      setEProtocol("http://");
    } else {
      setLinkToEvent(text);
    }
  };

  const getTimeData = (time) => {
    const hour = Number(time);
    if (regex.time.test(time)) {
      return time;
    } else if (hour && hour > 0 && hour < 23) {
      return `${hour < 10 ? `0${hour}` : hour}:00`;
    }
  };

  const changePayment = (amount) => {
    amount = amount.split(" ").join("");
    amount = Number(amount);
    console.log(amount);
    if (amount >= 1000) {
      setIsFree(false);
      setPrice(String(amount));
    }
  };

  const textSelection = ({ type, text }) => {
    // console.log("====================================");
    // console.log(type, text);
    // console.log("====================================");
    switch (type) {
      case "title":
        if (/^["«»“”]/.test(text)) {
          text = text.slice(1);
        }
        if (/["«»“”]$/.test(text)) {
          text = text.slice(0, text.length - 1);
        }
        setTitle(text);
        break;
      case "type":
        changeCategory(text);
        break;
      case "date_from":
        console.log(text);
        text = String(text);
        const from = getDateData(text, "fromDate");
        // console.log("====================================");
        // console.log("fromDate: ", from, text);
        // console.log("====================================");
        const { day, month, year, currentYear } = from;
        if (day !== -1 && month !== -1 && year !== -1) {
          setSelectedDayRange((prev) => ({
            ...prev,
            from: { day, month, year },
          }));
        }
        break;
      case "date_to":
        setSelectedDayRange((prev) => ({
          ...prev,
          to: getDateData(text),
        }));
        break;
      case "time_from":
        setFromTime(getTimeData(text));
        break;
      case "time_to":
        setToTime(getTimeData(text));
        break;
      case "speaker_name":
        setSpeakerName(text);
        break;
      case "speaker_desc":
        setSpeakerDescription(text);
        break;
      case "organizer":
        if (
          !organizers.filter(
            ({ organizerLink, organizerName }) =>
              organizerLink === (text?.url || "") &&
              organizerName === (text?.name || "")
          ).length
        ) {
          setOrganizers((prev) => [
            ...prev,
            { organizerLink: text?.url || "", organizerName: text?.name || "" },
          ]);
        }
        // setOrganizerName(text);
        break;
      case "organizer_name":
        setOrganizerName(text);
        break;
      case "address_name":
        // setAddressName(text);
        break;
      case "registration_link":
        changeRegistrationLink(text);
        break;
      case "event_link":
        changeEventLink(text);
        break;
      case "payment":
        changePayment(text);
        break;
      case "description":
        setDescription(text);
        break;
      case "format":
        if (/online|onlayn/gi.test(text)) {
          setFormat("ONLINE");
        } else if (/offline|oflayn|offlayn/gi.test(text)) {
          setFormat("OFFLINE");
        } else if (/hybrid/gi.test(text)) {
          setFormat("HYBRID");
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <EditEventPopUpBackground
        onClick={() => {
          setEdit?.({ edit: false, data: {} });
          setMakePost?.({ popUp: false, data: {} });
          setAddPopUp?.(false);
        }}
      />
      <EventWrapper>
        <div
          className="close-pop-up-bg"
          onClick={() => {
            setEdit?.({ edit: false, data: {} });
            setMakePost?.({ popUp: false, data: {} });
            setAddPopUp?.(false);
          }}
        />
        <EditEventPopUpWrapper>
          <ImageBoxWrapper>
            <ImageListWrapper>
              {filesIds.map((imgId, index) => (
                <ImageBox imgId={imgId} removeImg={() => removeImg(index)} />
              ))}
              {sendImageProcess.loading ? <ImgLoading /> : ""}
            </ImageListWrapper>

            {/* <>
            <div className="cancel-icon" onClick={() => setFileId("")}>
              <CloseIcon />
            </div>
            <img src={imagePathProcess.data} alt="" />
          </> */}
            {/* ) : ( */}
            {!sendImageProcess.loading && filesIds.length === 0 && (
              <label>
                <input type="file" onChange={submitImage} />
                <AddImage />
              </label>
            )}
            {/* )} */}
          </ImageBoxWrapper>
          {/* <b>{JSON.stringify(sendImageProcess)}</b> */}
          <InputBox>
            <Form.Item style={{ margin: 0 }} {...isEmpty(emptyEvent.TITLE)}>
              <Input
                value={title}
                placeholder="Sarlavha"
                onChange={(e) => {
                  setTitle(e.target.value);
                  e.target.value && removeEmptyError(emptyEvent.TITLE);
                  !e.target.value && addEmptyError(emptyEvent.TITLE);
                }}
              />
            </Form.Item>
          </InputBox>
          <InputBox>
            <Form.Item style={{ margin: 0 }} {...isEmpty(emptyEvent.CATEGORY)}>
              <AutoComplete
                options={categories.data
                  ?.filter(
                    ({ active, name }) =>
                      active &&
                      (category
                        ? name?.toLowerCase().includes(category?.toLowerCase())
                        : true)
                  )
                  .map((v) => ({ ...v, value: v.name }))}
                style={{
                  width: "100%",
                }}
                onSelect={(c) => {
                  changeCategory(c);
                }}
                value={category}
                onSearch={(c) => {
                  changeCategory(c);
                }}
                placeholder="Event type"
              />
            </Form.Item>
          </InputBox>
          {/* <InputBox>
          <Dropdown
            placement="bottom"
            style={{ padding: 0 }}
            visible={categoriesVisible}
            onVisibleChange={(v) => {
              !v && setCategoriesVisible(v);
            }}
            overlay={
              <Menu
                onClick={(e) => {
                  setCategory(e.key);
                  setCategoriesVisible(false);
                }}
                items={categories.data
                  .filter(
                    ({ active, name }) =>
                      active &&
                      (category
                        ? name?.toLowerCase().includes(category?.toLowerCase())
                        : true)
                  )
                  .map(({ name }) => ({
                    key: name,
                    label: name,
                  }))}
              />
            }
          >
            <Input
              value={category}
              placeholder="event type"
              onChange={(e) => {
                setCategory(e.target.value);
                setCategoriesVisible(true);
              }}
              onFocus={() => setCategoriesVisible(true)}
              onClick={() => setCategoriesVisible(true)}
              onBlur={() => setCategoriesVisible(false)}
            />
          </Dropdown>
        </InputBox> */}
          <InputBox>
            <Form.Item style={{ margin: 0 }} {...isEmpty(emptyEvent.FORMAT)}>
              <Radio.Group
                value={format}
                style={{ margin: "auto", width: "fit-content" }}
                options={[
                  { label: "Online", value: "ONLINE" },
                  { label: "Offline", value: "OFFLINE" },
                  { label: "Hybrid", value: "HYBRID" },
                ]}
                onChange={(e) => {
                  e.target.value && removeEmptyError(emptyEvent.FORMAT);
                  !e.target.value && addEmptyError(emptyEvent.FORMAT);
                  switch (e.target.value) {
                    case "ONLINE":
                      setFormat("ONLINE");
                      setAddress("");
                      setAddressLink("");
                      setAddressLongitude("");
                      setAddressLatitude("");
                      break;
                    case "OFFLINE":
                      setFormat("OFFLINE");
                      setLinkToEvent("");
                      break;
                    case "HYBRID":
                      setFormat("HYBRID");
                      break;
                    default:
                      break;
                  }
                }}
                // value={value4}
                optionType="button"
                buttonStyle="solid"
              >
                {/* <Radio value={1}>A</Radio>
              <Radio value={2}>B</Radio>
              <Radio value={3}>C</Radio>
              <Radio value={4}>D</Radio> */}
              </Radio.Group>
            </Form.Item>
          </InputBox>

          <Form.Item
            style={{ width: "fit-content", margin: 0 }}
            {...isEmpty(emptyEvent.START_TIME)}
          >
            <Popover
              content={
                <Calendar
                  value={selectedDayRange}
                  onChange={(e) => {
                    console.log(e);
                    const from = e.from;
                    const to = e.to;
                    console.log(e);
                    if (
                      !(
                        from?.day === to?.day &&
                        from?.month === to?.month &&
                        from?.year === to?.year
                      )
                    ) {
                      setSelectedDayRange(e);
                    }

                    if (from) {
                      removeEmptyError(emptyEvent.START_TIME);
                    } else {
                      addEmptyError(emptyEvent.START_TIME);
                    }
                  }}
                  shouldHighlightWeekends
                />
              }
              visible={calcVisibility}
              onVisibleChange={(e) => {
                setCalcVisibility(e);
              }}
              // trigger="click"
            >
              {/* <DateTimeDisplay> */}
              {/* {text({ id: months[Number(sMonth - 1)], lang })} {sDay}-
            {text({ id: months[Number(eMonth - 1)], lang })} {eDay} */}
              {/* </DateTimeDisplay> */}
              <Button>
                {from ? `${from?.day}/${from?.month}/${from?.year}` : ""}
                {to ? ` - ${to?.day}/${to?.month}/${to?.year}` : ""}
                {!from && !to ? "📅" : ""}
              </Button>
            </Popover>
          </Form.Item>
          <InputBox>
            <Form.Item style={{ margin: 0 }} {...isEmpty(emptyEvent.FROM_TIME)}>
              <TimePicker
                placeholder="from"
                // defaultValue={moment("12:00", "HH:mm")}
                format={"HH:mm"}
                value={fromTime ? moment(fromTime, "HH:mm") : ""}
                onChange={(e, time) => {
                  console.log(e, time);
                  setFromTime(time);
                  time && removeEmptyError(emptyEvent.FROM_TIME);
                  !time && addEmptyError(emptyEvent.FROM_TIME);
                }}
                onSelect={(e, time) => {
                  console.log(e, time);
                  setFromTime(e.format("HH:mm"));
                  e && removeEmptyError(emptyEvent.FROM_TIME);
                  !e && addEmptyError(emptyEvent.FROM_TIME);
                }}
                // allowClear={false}
              />
              <span style={{ color: "white" }}>{" -> "}</span>
              <TimePicker
                placeholder="to"
                // defaultValue={moment("12:00", "HH:mm")}
                format={"HH:mm"}
                value={toTime ? moment(toTime, "HH:mm") : ""}
                onChange={(e, time) => {
                  setToTime(time);
                }}
                onSelect={(e, time) => {
                  setToTime(e.format("HH:mm"));
                }}
              />
            </Form.Item>
          </InputBox>
          <ListBox>
            {speakers?.map(
              ({ speakerName, speakerLink, speakerDescription }, i) => {
                console.log(
                  speakerName,
                  Boolean(speakerName),
                  speakerLink,
                  Boolean(speakerLink),
                  speakerDescription,
                  Boolean(speakerDescription)
                );
                return (
                  <li key={i}>
                    <button
                      key={i}
                      onClick={() =>
                        setSpeakers(speakers.filter((s, index) => index !== i))
                      }
                    >
                      <CloseIcon />
                    </button>
                    <p>
                      {Boolean(speakerName) &&
                      Boolean(speakerLink) &&
                      Boolean(speakerDescription) ? (
                        <>
                          <a
                            href={speakerLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {speakerName}
                          </a>{" "}
                          - {speakerDescription}
                        </>
                      ) : Boolean(speakerName) &&
                        Boolean(speakerLink) &&
                        !Boolean(speakerDescription) ? (
                        <a href={speakerLink} target="_blank" rel="noreferrer">
                          {speakerName}
                        </a>
                      ) : Boolean(speakerName) &&
                        Boolean(speakerDescription) &&
                        !Boolean(speakerLink) ? (
                        <>
                          {speakerName} - {speakerDescription}
                        </>
                      ) : Boolean(speakerName) &&
                        !Boolean(speakerLink) &&
                        !Boolean(speakerDescription) ? (
                        speakerName
                      ) : (
                        ""
                      )}
                    </p>
                    {/* {speakerName} */}
                  </li>
                );
              }
            )}
          </ListBox>
          <InputBox>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "calc(100% - 70px)" }}>
                <Input
                  onKeyPress={(e) => e.key === "Enter" && addSpeaker()}
                  onChange={(e) => setSpeakerName(e.target.value)}
                  value={speakerName}
                  style={
                    {
                      // width: "calc(100% - 100px)",
                    }
                  }
                  // defaultValue="https://ant.design"
                  placeholder="Spiker Name"
                />
                <Input
                  onKeyPress={(e) => e.key === "Enter" && addSpeaker()}
                  onChange={(e) => {
                    changeSpeakerLink(e.target.value);
                    // if (e.target.value.startsWith("https://")) {
                    //   setSpeakerLink(e.target.value.slice(8));
                    //   setSProtocol("https://");
                    // } else if (e.target.value.startsWith("http://")) {
                    //   setSpeakerLink(e.target.value.slice(7));
                    //   setSProtocol("http://");
                    // } else {
                    //   setSpeakerLink(e.target.value);
                    // }
                  }}
                  value={speakerLink}
                  style={
                    {
                      // width: "calc(100% - 100px)",
                    }
                  }
                  type="link"
                  addonBefore={
                    <Select
                      value={sProtocol}
                      onChange={(e) => {
                        setSProtocol(e);
                      }}
                      className="select-before"
                    >
                      <Option value="https://">https://</Option>
                      <Option value="http://">http://</Option>
                    </Select>
                  }
                  // defaultValue="https://ant.design"
                  placeholder="Spiker Link (Ixtiyoriy)"
                />

                <TextArea
                  // onKeyPress={(e) => e.key === "Enter" && addSpeaker()}
                  onChange={(e) => {
                    setSpeakerDescription(e.target.value);
                  }}
                  value={speakerDescription}
                  style={
                    {
                      // width: "calc(100% - 100px)",
                    }
                  }
                  // defaultValue="https://ant.design"
                  placeholder="Spiker Description (Ixtiyoriy)"
                />
              </div>
              {/* <div style={{ height: "100%" }}> */}
              <Button type="primary" onClick={addSpeaker}>
                Add
              </Button>
              {/* </div> */}
            </div>
          </InputBox>
          <ListBox>
            {organizers.map(({ organizerName, organizerLink }, i) => (
              <li key={i}>
                <button
                  onClick={() =>
                    setOrganizers(organizers.filter((o, index) => index !== i))
                  }
                >
                  <CloseIcon />
                </button>
                <p>
                  {Boolean(organizerName) && Boolean(organizerLink) ? (
                    <a href={organizerLink} target="_blank" rel="noreferrer">
                      {organizerName}
                    </a>
                  ) : Boolean(organizerName) && !Boolean(organizerLink) ? (
                    organizerName
                  ) : !Boolean(organizerName) && Boolean(organizerLink) ? (
                    <a href={organizerLink} target="_blank" rel="noreferrer">
                      {organizerLink}
                    </a>
                  ) : (
                    ""
                  )}
                </p>
              </li>
            ))}
          </ListBox>
          <InputBox>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "calc(100% - 70px)" }}>
                {/* <Dropdown
                placement="bottom"
                style={{ padding: 0 }}
                visible={organizersVisible}
                onVisibleChange={(v) => {
                  !v && setOrganizersVisible(v);
                }}
                overlay={
                  <Menu
                    onClick={(e) => {
                      console.log(e);
                      const o = organizerList.data.find(
                        ({ id }) => id === e.key
                      );
                      setOrganizerName(o.name);
                      if (o.link.startsWith("https://")) {
                        setOrganizerLink(o.link.slice(8));
                        setOProtocol("https://");
                      } else if (o.link.startsWith("http://")) {
                        setOrganizerLink(o.link.slice(7));
                        setOProtocol("http://");
                      } else {
                        setOrganizerLink(o.link);
                      }
                      setOrganizersVisible(false);
                    }}
                    items={organizerList.data
                      ?.filter(
                        ({ isActive: active, name }) =>
                          active &&
                          !organizers.some(
                            ({ organizerName: oName }) => name === oName
                          ) &&
                          (organizerName
                            ? name
                                .toLowerCase()
                                .includes(organizerName?.toLowerCase())
                            : true)
                      )
                      ?.map(({ id, name }) => ({
                        key: id,
                        label: name,
                      }))}
                  />
                }
              >
                <Input
                  onKeyPress={(e) => e.key === "Enter" && addOrganizer()}
                  onChange={(e) => {
                    setOrganizerName(e.target.value);
                    setOrganizersVisible(true);
                  }}
                  onClick={() => setOrganizersVisible(true)}
                  onFocus={() => setOrganizersVisible(true)}
                  onBlur={() => setOrganizersVisible(false)}
                  value={organizerName}
                  style={
                    {
                      // width: "calc(100% - 70px)",
                    }
                  }
                  // defaultValue="https://ant.design"
                  placeholder="Organizer Name"
                />
              </Dropdown> */}
                <AutoComplete
                  options={organizerList.data
                    ?.filter(
                      ({ isActive: active, name }) =>
                        active &&
                        !organizers.some(
                          ({ organizerName: oName }) => name === oName
                        ) &&
                        (organizerName
                          ? name
                              .toLowerCase()
                              .includes(organizerName?.toLowerCase())
                          : true)
                    )
                    .map((v) => ({
                      value: v.name,
                    }))}
                  style={{
                    width: "100%",
                  }}
                  onSelect={(oName) => {
                    const o = organizerList.data.find(
                      ({ name }) => name === oName
                    );
                    setOrganizers([
                      ...organizers,
                      {
                        organizerName: o.name,
                        organizerLink: o.link,
                      },
                    ]);
                    // console.log(o);
                    // console.log(oName);
                    // setOrganizerName(o.name);
                    // if (o.link.startsWith("https://")) {
                    //   setOrganizerLink(o.link.slice(8));
                    //   setOProtocol("https://");
                    // } else if (o.link.startsWith("http://")) {
                    //   setOrganizerLink(o.link.slice(7));
                    //   setOProtocol("http://");
                    // } else {
                    //   setOrganizerLink(o.link);
                    // }
                  }}
                  onSearch={(o) => setOrganizerName(o)}
                  value={organizerName}
                  placeholder="Organizer Name"
                />
                <Input
                  onKeyPress={(e) => e.key === "Enter" && addOrganizer()}
                  onChange={(e) => {
                    if (e.target.value.startsWith("https://")) {
                      setOrganizerLink(e.target.value.slice(8));
                      setOProtocol("https://");
                    } else if (e.target.value.startsWith("http://")) {
                      setOrganizerLink(e.target.value.slice(7));
                      setOProtocol("http://");
                    } else {
                      setOrganizerLink(e.target.value);
                    }
                  }}
                  value={organizerLink}
                  style={
                    {
                      // width: "calc(100% - 70px)",
                    }
                  }
                  type="link"
                  // defaultValue="https://ant.design"
                  placeholder="Organizator Link (Ixtiyoriy)"
                  addonBefore={
                    <Select
                      value={oProtocol}
                      onChange={(e) => {
                        setOProtocol(e);
                      }}
                      className="select-before"
                    >
                      <Option value="https://">https://</Option>
                      <Option value="http://">http://</Option>
                    </Select>
                  }
                />
              </div>
              <Button type="primary" onClick={addOrganizer}>
                Add
              </Button>
            </div>
          </InputBox>
          <InputBox>
            <Form.Item style={{ margin: 0 }} {...isEmpty(emptyEvent.LANGS)}>
              <Checkbox.Group
                style={{
                  width: "100%",
                  height: "fit-content",
                }}
                value={langs}
                onChange={(e) => {
                  console.log(e);
                  setLangs(e);
                  e.length && removeEmptyError(emptyEvent.LANGS);
                  !e.length && addEmptyError(emptyEvent.LANGS);
                }}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox value="uz">
                      <span className="lang">🇺🇿 Uzbek</span>
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="ru">
                      <span className="lang">🇷🇺 Russian</span>
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="en">
                      <span className="lang">🇬🇧 English</span>
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </InputBox>
          {format === "OFFLINE" || format === "HYBRID" ? (
            <>
              <InputBox>
                {/* Address */}
                <Form.Item
                  style={{ margin: 0 }}
                  {...isEmpty(emptyEvent.ADDRESS)}
                >
                  <Input
                    placeholder="Address name"
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setSelectedLocation("");
                      // e.target.value && removeEmptyError(emptyEvent.ADDRESS);
                      // !e.target.value && addEmptyError(emptyEvent.ADDRESS);
                    }}
                    value={address}
                  />
                </Form.Item>
              </InputBox>
              {/* <InputBox>
              <Input
                placeholder="Address link"
                onChange={(e) => setAddressLink(e.target.value)}
                value={addressLink}
              />
            </InputBox> */}
              <InputBox item={2}>
                <Input
                  placeholder="Longitude"
                  onChange={(e) => {
                    setAddressLongitude(e.target.value);
                    setSelectedLocation("");
                  }}
                  value={addressLongitude}
                />
                <Input
                  placeholder="Latitude"
                  onChange={(e) => {
                    setAddressLatitude(e.target.value);
                    setSelectedLocation("");
                  }}
                  value={addressLatitude}
                />
                <Button
                  onClick={() => {
                    setAddressLatitude("");
                    setAddressLongitude("");
                  }}
                >
                  Clean
                </Button>
              </InputBox>
              <Form.Item style={{ margin: 0 }} {...isEmpty(emptyEvent.ADDRESS)}>
                <InputBox style={{ margin: 0 }} item={2}>
                  <Select
                    style={{ width: "calc(100% - 67px)" }}
                    value={selectedLocation}
                    onChange={(e, obj) => {
                      console.log(e);
                      setSelectedLocation(e);
                      setAddressLatitude("");
                      setAddressLongitude("");
                      setAddress("");
                      setCoords(obj.coords);
                      // console.log(e);
                    }}
                  >
                    {addresses?.data
                      ?.filter(({ isActive }) => isActive)
                      .map(({ id, name, longitude, latitude }) => (
                        <Option
                          key={id}
                          value={id}
                          coords={[latitude, longitude]}
                        >
                          {name}
                        </Option>
                      ))}
                  </Select>
                  <Button
                    onClick={() => {
                      setSelectedLocation("");
                    }}
                  >
                    Clear
                  </Button>
                </InputBox>
              </Form.Item>
              <InputBox>
                <YMaps
                  query={{
                    apikey: "7446be07-f1bc-4ce3-a5fe-0a423e51a168",
                    lang: "uz_UZ",
                    coordorder: "latlong",
                  }}
                >
                  <div>
                    <Map
                      // onClick={console.log}
                      width={"100%"}
                      height={200}
                      state={{
                        center: coords,
                        zoom: 10,
                      }}
                      defaultState={{
                        center: coords,
                        // [
                        //   // 41.31439136799405, 69.2611792755127,
                        // ] /*[55.75, 37.57]*/,
                        zoom: 10,
                      }}
                      onClick={(e) => {
                        const coordinates = e.get("coords");
                        // setCoords(coordinates);
                        setAddressLatitude(coordinates?.[0]);
                        setAddressLongitude(coordinates?.[1]);
                        console.log(coordinates);
                        setSelectedLocation("");
                      }}
                    >
                      {addresses?.data?.map(
                        ({ id, name, longitude, latitude, logoImgId }) => (
                          <AddressPlaceMark
                            id={id}
                            key={id}
                            latitude={latitude}
                            longitude={longitude}
                            name={name}
                            logoImgId={logoImgId}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            // edit
                            // addressLatitude,
                            setAddressName={setAddress}
                            setAddressLatitude={setAddressLatitude}
                            // addressLongitude,
                            setAddressLongitude={setAddressLongitude}
                          />
                        )
                      )}
                      <Placemark
                        geometry={[addressLatitude, addressLongitude]}
                        options={{ draggable: true }}
                        onDragEnd={(e) => {
                          const coordinates =
                            e.get("target").geometry._coordinates;
                          setCoords(coordinates);
                          setAddressLatitude(coordinates?.[0]);
                          setAddressLongitude(coordinates?.[1]);
                          console.log(coordinates);
                        }}
                      />
                      <ZoomControl />
                      <FullscreenControl />
                      <SearchControl />
                      <GeolocationControl />
                    </Map>
                  </div>
                </YMaps>
              </InputBox>
            </>
          ) : (
            ""
          )}
          {format === "ONLINE" || format === "HYBRID" ? (
            <InputBox>
              <Input
                placeholder="Link to event"
                onChange={(e) => {
                  changeEventLink(e.target.value);
                  // if (e.target.value.startsWith("https://")) {
                  //   setLinkToEvent(e.target.value.slice(8));
                  //   setEProtocol("https://");
                  // } else if (e.target.value.startsWith("http://")) {
                  //   setLinkToEvent(e.target.value.slice(7));
                  //   setEProtocol("http://");
                  // } else {
                  //   setLinkToEvent(e.target.value);
                  // }
                }}
                value={linkToEvent}
                addonBefore={
                  <Select
                    value={eProtocol}
                    onChange={(e) => {
                      setEProtocol(e);
                    }}
                    className="select-before"
                  >
                    <Option value="https://">https://</Option>
                    <Option value="http://">http://</Option>
                  </Select>
                }
              />
            </InputBox>
          ) : (
            ""
          )}
          <InputBox>
            <Input
              placeholder="Link to registration"
              onChange={(e) => {
                changeRegistrationLink(e.target.value);
                // if (e.target.value.startsWith("https://")) {
                //   setLinkToRegistration(e.target.value.slice(8));
                //   setRProtocol("https://");
                // } else if (e.target.value.startsWith("http://")) {
                //   setLinkToRegistration(e.target.value.slice(7));
                //   setRProtocol("http://");
                // } else {
                //   setLinkToRegistration(e.target.value);
                // }
              }}
              value={linkToRegistration}
              addonBefore={
                <Select
                  value={rProtocol}
                  onChange={(e) => {
                    setRProtocol(e);
                  }}
                  className="select-before"
                >
                  <Option value="https://">https://</Option>
                  <Option value="http://">http://</Option>
                </Select>
              }
            />
          </InputBox>
          <ListBox>
            {prices.map(({ price, priceText }, i) => (
              <li key={i}>
                <button
                  onClick={() =>
                    setPrices(prices.filter((o, index) => index !== i))
                  }
                >
                  <CloseIcon />
                </button>
                {price && priceText ? (
                  <>
                    {isNaN(Number(price)) ? (
                      <b>{price}</b>
                    ) : (
                      <>
                        <b>{price}</b> so'm
                      </>
                    )}{" "}
                    - {priceText}
                  </>
                ) : price && !priceText ? (
                  <>
                    {isNaN(Number(price)) ? (
                      <b>{price}</b>
                    ) : (
                      <>
                        <b>{price}</b> so'm
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ListBox>
          <InputBox item={2}>
            {!isFree ? (
              <>
                <InputNumber
                  onKeyPress={(e) => e.key === "Enter" && addPrice()}
                  style={{ width: "100%" }}
                  placeholder="Price"
                  onChange={(e) => {
                    console.log(e);
                    setPrice(Number(e));
                  }}
                  min={1000}
                  value={price}
                  type="number"
                />
                <Input
                  onKeyPress={(e) => e.key === "Enter" && addPrice()}
                  value={priceText}
                  onChange={(e) => {
                    setPriceText(e.target.value);
                  }}
                  placeholder="Description"
                />
                <Button type="primary" onClick={addPrice}>
                  Add
                </Button>
              </>
            ) : (
              ""
            )}
            <Button
              onClick={() => {
                setIsFree(!isFree);
                setPrice(null);
                setPriceText("");
                setPrices([]);
              }}
            >
              {isFree ? "Payment" : "FREE"}
            </Button>
          </InputBox>
          <InputBox>
            <TextArea
              value={description}
              rows={5}
              placeholder="Description"
              // value={description}
              // value={JSON.stringify(window.Telegram)}
              onChange={(e) => setDescription(e.target.value)}
            />
          </InputBox>
          {/* <InputBox> */}
          {/* <YMaps query={{ lang: 'en_RU' }}>Application with YMaps Provider</YMaps> */}
          {/* </InputBox> */}
          <Button
            style={{ width: addEvent ? "100%" : "fit-content" }}
            type="primary"
            onClick={submitEvent}
          >
            {addEvent ? "Adminga junatish" : edit?.edit ? "Edit" : "Submit"}
          </Button>
          {!addEvent && (
            <Button
              onClick={() => {
                setEdit?.({ edit: false, data: {} });
                setMakePost?.({ popUp: false, data: {} });
                setAddPopUp?.(false);
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          )}
        </EditEventPopUpWrapper>
        {makePost && (
          <PostCard {...makePost.data} preview textSelection={textSelection} />
        )}
        {edit?.data?.fwdMsgEntity && (
          <PostCard
            {...edit?.data?.fwdMsgEntity}
            preview
            textSelection={textSelection}
          />
        )}
      </EventWrapper>
    </>
  );
}

export const ImageBox = ({ imgId, removeImg }) => {
  const [imagePathProcess, makeImagePathRequest] = useHttpRequest();

  useEffect(() => {
    console.log(imagePathProcess);
  }, [imagePathProcess]);

  return (
    <SingleImageBox
      img={
        // imagePathProcess.data
        `${BASE_URL}/attachment/tg-url/${imgId}`
      }
    >
      <div className="cancel-icon" onClick={removeImg}>
        <CloseIcon />
      </div>
      {/* <img src={imagePathProcess.data} alt="" /> */}
    </SingleImageBox>
  );
};

export const ImgLoading = () => {
  return (
    <ImgLoadingBox>
      <div></div>
    </ImgLoadingBox>
  );
};

export const AddressPlaceMark = ({
  id,
  latitude,
  longitude,
  name,
  logoImgId,
  selectedLocation,
  setSelectedLocation,
  edit,
  // addressLatitude,
  setAddressLatitude,
  // addressLongitude,
  setAddressLongitude,
  setAddressName,
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Placemark
      // key={id}
      onClick={() => {
        // setHovered(true);
        setSelectedLocation(id);
        setAddressName("");
        setAddressLatitude("");
        setAddressLongitude("");
      }}
      // onMouseEnter={() => {
      //   // setHovered(true);
      //   setSelectedLocation(id);
      // }}
      // onMouseLeave={() => {
      //   // setHovered(false);
      //   setSelectedLocation("");
      // }}
      // options={{ draggable: true }}
      // onDragEnd={(e) => {
      //   const coordinates = e.get("target").geometry._coordinates;
      //   setAddressLatitude(coordinates?.[0]);
      //   setAddressLongitude(coordinates?.[1]);
      //   console.log(coordinates);
      // }}
      geometry={[latitude, longitude]}
      options={{
        preset: `islands#${
          edit ? "green" : selectedLocation === id ? "black" : "red"
        }Icon`,
        hintLayout: "hint layout",
        draggable: edit ? true : false,
        // iconShape: "Circle",
        // iconLayout: "default#imageWithContent",
        // iconImageSize: [50, 50],
        // iconImageHref: `${BASE_URL}/attachment/${logoImgId}`,
        // iconImageHref: `https://img.icons8.com/ios-filled/2x/marker-a.png`,
        hideIconOnBalloonOpen: false,
        ...(edit
          ? { zIndex: 25 }
          : selectedLocation === id
          ? { zIndex: 30 }
          : { zIndex: 20 }),
      }}
      properties={{
        iconContent: `
        <div class="myIconContentBox ${
          edit ? "edit" : selectedLocation === id ? "active" : ""
        }">
          <p class="myIconContentPopover">${name}</p>
          <img title=${name} style='width:100%; height:100%; border-radius:50%; object-fit:cover;' src='${BASE_URL}/attachment/${logoImgId}'/>
        </div>`,

        // balloonContent: "balloonContent",
        // hintContent: "hintContent",
      }}
    />
  );
};

export default EditEventPopUp;
