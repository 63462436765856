import React, { useEffect, useState } from "react";
import { empty } from "../../constants/empty";
import { BASE_URL } from "../../constants/link";
import useHttpRequest from "../../hooks/useHttpRequest";
import { displayDays, displayTime } from "../../utils/dateTimeManager";
import { httpRequest } from "../../utils/httpRequest";
import { parseToJson } from "../../utils/json";
import { thousandSeparator } from "../../utils/numberManager";
import {
  Action,
  ActionBox,
  AvatarBox,
  Card,
  Comments,
  CreatorBox,
  CreatorData,
  Data,
  DataWrapper,
  Description,
  EventPosterImg,
  Instagram,
  LikeCommentSubscribers,
  Likes,
  MenuBox,
  MenuButton,
  MenuCenterButton,
  Poster,
  Status,
  StatusBell,
  Subscribers,
  Telegram,
  Text,
  Title,
} from "./styles";
import { format as formatConst } from "../../constants/format";
import { langs } from "../../constants/langs";
import bell from "../../assets/photo/bell.png";
import comment from "../../assets/photo/chat.png";
import like from "../../assets/photo/heart.png";
import telegramLogo from "../../assets/photo/telegram.png";
import instagramLogo from "../../assets/photo/instagram.png";
import CountUp from "react-countup";
import { Planet } from "react-planet";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../../redux/modules/getEvents";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/trash.svg";
import { Popover, Tooltip, message } from "antd";
import EditEventPopUp from "../editEventPopUp";

const displaySpeaker = (speakers) => {
  // console.log("speakers type", typeof speakers, "speakers", speakers);

  if (speakers && speakers.length > 0 && typeof speakers[0] === "object") {
    // const { speakerDescription, speakerLink, speakerName } = speakers;

    return (
      <>
        🎙{" "}
        <>
          {speakers.length > 1 ? (
            <>
              Spikerlar: <br />
            </>
          ) : (
            ""
          )}
          {speakers.map(({ speakerDescription, speakerLink, speakerName }) => {
            // const sSpeaker = speaker.split("-").map((s) => s.trim());
            return (
              <>
                {speakers.length > 1 ? <b> • </b> : ""}{" "}
                {speakerLink ? (
                  <a href={speakerLink}>
                    <b>{speakerName}</b>
                  </a>
                ) : (
                  <b>{speakerName}</b>
                )}
                {speakerDescription ? <> - {speakerDescription}</> : ""}
                <br />
              </>
            );
          })}
        </>
        {/* )} */}
      </>
    );
  } else {
    const fSpeaker = speakers?.[0].split("-").map((s) => s.trim());
    return (
      <>
        {speakers && speakers.length > 0 ? (
          <>
            🎙{" "}
            {speakers.length === 1 ? (
              <>
                <b>{fSpeaker[0]}</b>
                {fSpeaker?.[1] ? ` - ${fSpeaker?.[1]}` : ""}
                <br />
              </>
            ) : (
              <>
                Spikerlar: <br />
                {speakers.map((speaker) => {
                  const sSpeaker = speaker.split("-").map((s) => s.trim());
                  return (
                    <>
                      <b> • </b> <b>{sSpeaker[0]}</b>
                      {sSpeaker?.[1] ? <> - {sSpeaker[1]}</> : ""}
                      <br />
                    </>
                  );
                })}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  }
};

// const displayOrganizer = (organizers) => {
//   if (organizers.length > 0) {
//     if (typeof organizers?.[0] === "string") {
//       const fOrganizer = organizers?.[0].split("-").map((s) => s.trim());
//       return `${
//         organizers && organizers.length > 0
//           ? `🎯 ${
//               organizers.length === 1
//                 ? `${fOrganizer?.[1] ? `<b>${fOrganizer?.[1]}</b> - ` : ""}${
//                     fOrganizer[0]
//                   }\n`
//                 : `
//                 Tashkilotchilar
//                 \n${organizers
//                     .map((organizer) => {
//                       const sOrganizer = organizer
//                         .split("-")
//                         .map((s) => s.trim());
//                       return ` <b>•</b> ${
//                         sOrganizer?.[1] ? `<b>${sOrganizer[1]}</b> - ` : ""
//                       }${sOrganizer[0]}\n`;
//                     })
//                     .join("")}`
//             }`
//           : ""
//       }`;
//     } else if (typeof organizers?.[0] === "object") {
//       return (
//         organizers
//           .map(({ organizerName, organizerLink }, i) =>
//             Boolean(organizerName) && Boolean(organizerLink)
//               ? `<a href=${organizerLink}>${organizerName}</a>`
//               : Boolean(organizerName) && !Boolean(organizerLink)
//               ? `${organizerName}`
//               : !Boolean(organizerName) && Boolean(organizerLink)
//               ? `<a href=${organizerLink}>${organizerLink}</a>`
//               : ""
//           )
//           .join("\n") + "\n"
//       );
//     }
//   }

//   // `${organizers ? (`🎯 ${organizers.length === 1 ? `${organizers[0]}\n` : organizers.map((organizer) => (` -${organizer}\n`))}`) : ""}`
// };

const displayOrganizer = (organizers) => {
  // console.log("organizers: ".toUpperCase(), organizers);
  if (organizers?.length > 0) {
    if (typeof organizers?.[0] === "string") {
      const fOrganizer = organizers?.[0].split("-").map((s) => s.trim());
      return (
        <>
          {organizers && organizers.length > 0 ? (
            <>
              🎯{" "}
              {organizers.length === 1 ? (
                <>
                  {fOrganizer?.[1] ? (
                    <>
                      <b>{fOrganizer[1]}</b> -{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {fOrganizer[0]}
                  <br />
                </>
              ) : (
                <>
                  Tashkilotchilar: <br />
                  {organizers.map((organizer) => {
                    const sOrganizer = organizer
                      .split("-")
                      .map((s) => s.trim());
                    return (
                      <>
                        {" "}
                        <b> • </b>
                        {sOrganizer?.[1] ? (
                          <>
                            <b>{sOrganizer[1]}</b> -{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {sOrganizer[0]}
                        <br />
                      </>
                    );
                  })}
                </>
              )}
            </>
          ) : (
            ""
          )}
        </>
      );
    } else if (typeof organizers?.[0] === "object") {
      return (
        <>
          🎯{" "}
          {organizers.length > 1 ? (
            <>
              Tashkilotchilar: <br />
            </>
          ) : (
            ""
          )}
          {organizers.map(({ organizerName, organizerLink }, i) => (
            <>
              {organizers.length > 1 ? <b> • </b> : ""}
              {Boolean(organizerName) && Boolean(organizerLink) ? (
                <>
                  <a href={organizerLink}>{organizerName}</a> <br />
                </>
              ) : Boolean(organizerName) && !Boolean(organizerLink) ? (
                <>
                  {organizerName} <br />
                </>
              ) : !Boolean(organizerName) && Boolean(organizerLink) ? (
                <>
                  <a href={organizerLink}>{organizerLink}</a> <br />
                </>
              ) : (
                ""
              )}
            </>
          ))}
        </>
      );
    }
  }
};

function EventCard(props) {
  let {
    createdAt,
    description,
    descriptionEntity,
    endDate,
    endTime,
    eventCreatorId,
    eventTypeId,
    format,
    geoLocation,
    id,
    igPostCode,
    isActive,
    isAdminReviewing,
    isPublished,
    language,
    link_to_event,
    link_to_registration,
    location_text,
    messageIdInChannel,
    organizers,
    poster_id,
    price,
    prices,
    reminderId,
    speakers,
    startDate,
    startTime,
    subscribersCount,
    tenMinAgoMentioned,
    thirtyMinAgoMentioned,
    title,
    updatedAt,
    zeroMinAgoMentioned,
    eventType,
    eventTypeName,
    channelPostUrl,
    addPopUp,
    setAddPopUp,
    edit,
    setEdit,
    getNewEvents,
  } = props;

  const state = useSelector((state) => state);
  const me = state.me;

  const [postersIds, setPostersIds] = useState([]);

  // subscribersCount = 0;
  const dispatch = useDispatch();
  const [postToIgProcess, makePostToIgRequest] = useHttpRequest({
    onSuccess: () => {
      // dispatch(getEvents());
      getNewEvents();
    },
    onError: (err) => {
      // console.log(err?.response?.data);
      message.error(String(err?.response?.data));
    },
  });
  const [postToTgProcess, makePostToTgRequest] = useHttpRequest({
    onSuccess: () => {
      // dispatch(getEvents());
      getNewEvents();
    },
    onError: (err) => {
      message.error(String(err?.response?.data));
    },
  });

  // const [imgUrlProcess, makeImgUrlRequest] = useHttpRequest();
  // const [avatarProcess, makeAvatarRequest] = useHttpRequest();
  const [profileProcess, makeProfileRequest] = useHttpRequest();
  const [likeCommentProcess, makeLikeCommentRequest] = useHttpRequest();

  // useEffect(() => {
  //   console.log("likes-comments", likeCommentProcess);
  // }, [likeCommentProcess.success]);

  useEffect(() => {
    if (eventCreatorId) {
      makeProfileRequest({
        method: "GET",
        path: `telegram/profile`,
        params: {
          chatId: eventCreatorId,
        },
      });
    }
    if (igPostCode) {
      makeLikeCommentRequest({
        method: "GET",
        path: `instagram/like-comment`,
        params: {
          shortcode: igPostCode,
        },
      });
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     me?.data?.role === "SUPER_ADMIN" &&
  //     profileProcess?.data?.photo?.small_file_id
  //   ) {
  //     makeAvatarRequest({
  //       method: "GET",
  //       path: `attachment/tg-url`,
  //       params: {
  //         file_id: profileProcess.data.photo.small_file_id,
  //       },
  //     });
  //   }
  // }, [profileProcess.success]);

  const profile = profileProcess.data;

  speakers = parseToJson(speakers);
  organizers = parseToJson(organizers);
  geoLocation = parseToJson(geoLocation);
  language = parseToJson(language);
  prices = parseToJson(prices);

  const displayAddress = (f, geoLocation, location_text) => {
    if (f === formatConst.OFFLINE || f === formatConst.HYBRID) {
      if (geoLocation && empty.isNotEmpty(location_text)) {
        const { latitude, longitude } = geoLocation;
        return (
          <>
            📍{" "}
            <a href={`https://www.google.com/maps?q=${latitude},${longitude}`}>
              {location_text}
            </a>
          </>
        );
      } else if (geoLocation) {
        const { latitude, longitude } = geoLocation;
        return (
          <>
            📍{" "}
            <a href={`https://www.google.com/maps?q=${latitude},${longitude}`}>
              Manzil
            </a>
          </>
        );
      } else if (empty.isNotEmpty(location_text)) {
        return <>📍 {location_text}</>;
      }
    }
    return "";
  };

  const [deleteEventProcess, makeDeleteEventRequest] = useHttpRequest({
    onSuccess: () => {
      // dispatch(getEvents());
      getNewEvents();
    },
  });
  const deleteEvent = (id) => {
    makeDeleteEventRequest({
      method: "DELETE",
      path: `events/${id}`,
    });
  };

  //   bio
  //   first_name
  //   id
  //   photo
  //   type
  //   username

  useEffect(() => {
    if (poster_id) {
      const toJson = parseToJson(poster_id);
      if (toJson) {
        setPostersIds(toJson);
      } else if (empty.isNotEmpty(poster_id)) {
        setPostersIds([poster_id]);
      } else {
        setPostersIds([]);
      }
    } else {
      setPostersIds([]);
    }
    // console.log("POSTER ID: ", poster_id, postersIds);
  }, [poster_id]);

  // useEffect(() => {
  //   // console.log("POSTERs IDs: ", postersIds);
  // }, [postersIds]);

  return (
    <Card key={id}>
      <Poster
      // img={imgUrlProcess.data}
      >
        {postersIds.map((id) => {
          // console.log("MAPPING POSTER", id);
          return <EventPoster length={postersIds.length} poster_id={id} />;
        })}
        {/* <EventPoster src /> */}
        {/* <img src="" alt="" /> */}
        <ActionBox className="action-box">
          <Action
            editIcon
            onClick={() => {
              setEdit({
                edit: true,
                data: props,
              });
            }}
          >
            <Edit />
          </Action>
          <Popover
            // color={"black"}
            content={
              <div>
                <div>rostanham buni uchirmoqchimisiz</div>
                <button
                  onClick={() => {
                    deleteEvent(id);
                  }}
                >
                  delete
                </button>
              </div>
            }
          >
            <Action deleteIcon>
              <Delete />
            </Action>
          </Popover>
        </ActionBox>
        {eventCreatorId && (
          <CreatorBox className="creator">
            <AvatarBox>
              <img
                src={`${BASE_URL}/attachment/tg-url/${profileProcess?.data?.photo?.small_file_id}`}
                alt=""
              />
            </AvatarBox>
            <CreatorData>
              <p>{profile?.fullName}</p>
              <p>{profile?.username && `@${profile?.username}`}</p>
              <p>{profile?.phoneNumber}</p>
            </CreatorData>
          </CreatorBox>
        )}
      </Poster>
      <Text>
        <StatusBell>
          {isPublished ? (
            <Status published={isPublished}>
              {isPublished ? "Published" : "Editing"}
            </Status>
          ) : (
            <Popover
              content={
                <div>
                  post to tg channel <br />
                  <button
                    onClick={() =>
                      makePostToTgRequest({
                        method: "GET",
                        path: `events/post-event/${id}`,
                      })
                    }
                  >
                    post
                  </button>
                  <br />
                  send to yourself to test <br />
                  <button
                    onClick={() => {
                      makePostToTgRequest({
                        method: "GET",
                        path: `events/post-event/${id}`,
                        params: {
                          me: true,
                        },
                      });
                    }}
                  >
                    Test
                  </button>
                </div>
              }
            >
              <Status published={isPublished}>
                {isPublished ? "Published" : "Editing"}
              </Status>
            </Popover>
          )}
          <LikeCommentSubscribers style={{ marginLeft: "auto" }}>
            {/* {messageIdInChannel && ( */}
            <Telegram
              {...(messageIdInChannel
                ? {
                    target: "_blank",
                    href: `tg://resolve?domain=${channelPostUrl?.split("/")[3]}&post=${messageIdInChannel}&single`,
                    posted: 1,
                  }
                : {
                    posted: 0,
                    onClick: () => {
                      makePostToTgRequest({
                        method: "GET",
                        path: "telegram/post-event",
                        params: {
                          eventId: id,
                        },
                      });
                    },
                  })}
            >
              <img src={telegramLogo} alt="" />
              {/* <div className="number">
                <CountUp end={likeCommentProcess?.data?.like} duration={0.5} />
              </div> */}
            </Telegram>
            {/* )} */}
            {/* {igPostCode && ( */}
            <Instagram
              // posted={igPostCode ? 1 : 0}
              {...(igPostCode
                ? {
                    target: "_blank",
                    href: `https://www.instagram.com/p/${igPostCode.split("|")[0]}/`,
                    posted: 1,
                  }
                : {
                    posted: 0,
                    onClick: () => {
                      makePostToIgRequest({
                        method: "GET",
                        path: "instagram/post-event",
                        params: {
                          eventId: id,
                        },
                      });
                    },
                  })}
              // target={"_blank"}
              // href={`https://www.instagram.com/p/${igPostCode}/`}
            >
              <img src={instagramLogo} alt="" />
              {/* <div className="number">
                <CountUp end={likeCommentProcess?.data?.like} duration={0.5} />
              </div> */}
            </Instagram>
            {/* )} */}
            {igPostCode && (
              <>
                <Likes
                  target={"_blank"}
                  href={`https://www.instagram.com/p/${igPostCode.split("|")[0]}/`}
                >
                  <img src={like} alt="" />
                  <div className="number">
                    <CountUp
                      end={likeCommentProcess?.data?.like}
                      duration={0.5}
                    />
                  </div>
                </Likes>
                <Comments
                  target={"_blank"}
                  href={`https://www.instagram.com/p/${igPostCode.split("|")[0]}/`}
                >
                  <img src={comment} alt="" />
                  <div className="number">
                    <CountUp
                      end={likeCommentProcess?.data?.comment}
                      duration={0.5}
                    />
                  </div>
                </Comments>
              </>
            )}
            <Subscribers>
              <img src={bell} alt="" />
              <div className="number">
                <CountUp end={subscribersCount} duration={0.5} />
              </div>
            </Subscribers>
          </LikeCommentSubscribers>
        </StatusBell>
        <Title>{title}</Title>
        <DataWrapper>
          <Data>
            #{eventTypeName} #{format}
          </Data>
          {startDate && <Data>🗓 {displayDays({ startDate, endDate })}</Data>}
          {startTime && <Data>⏰ {displayTime({ startTime, endTime })}</Data>}
          <Data>
            {price >= 1000
              ? `💰 ${thousandSeparator(price)} so'm\n`
              : prices && Array.isArray(prices) && prices.length > 0
              ? prices.map(({ price, priceText }) =>
                  price && priceText ? (
                    <>
                      {isNaN(Number(price)) ? (
                        <b>{price}</b>
                      ) : (
                        <b>{price} so'm</b>
                      )}{" "}
                      - {priceText} <br />
                    </>
                  ) : price && !priceText ? (
                    <>
                      {isNaN(Number(price)) ? (
                        <b>{price}</b>
                      ) : (
                        <b>{price} so'm</b>
                      )}
                    </>
                  ) : (
                    ""
                  )
                )
              : "💰 Bepul"}
          </Data>
          <Data>{displaySpeaker(speakers)}</Data>
          <Data>{displayOrganizer(organizers)}</Data>
          <Data>
            {language
              ? `${
                  language.length === 1
                    ? `${langs[language[0]]}\n`
                    : language.map((l) => `${langs[l]}`).join(", ")
                } \n`
              : ""}
          </Data>
          <Data>{displayAddress(format, geoLocation, location_text)}</Data>
        </DataWrapper>
        <Description>{description}</Description>
      </Text>
    </Card>
  );
}

const EventPoster = ({ poster_id, length }) => {
  // const [imgUrlProcess, makeImgUrlRequest] = useHttpRequest();
  // const state = useSelector((state) => state);
  // const me = state.me;
  // useEffect(() => {
  //   console.log(me);
  //   if (me?.data?.role === "SUPER_ADMIN" && poster_id) {
  //     makeImgUrlRequest({
  //       method: "GET",
  //       path: `attachment/tg-url`,
  //       params: {
  //         file_id: poster_id,
  //       },
  //     });
  //   }
  // }, [poster_id]);
  return (
    <>
      {/* <b>{poster_id}</b> */}
      <EventPosterImg
        itemsLength={length}
        img={
          // poster_id
          // imgUrlProcess.data
          `${BASE_URL}/attachment/tg-url/${poster_id}`
        }
        alt="poster image"
      />
    </>
  );
};

export default EventCard;
