export const actionTypes = {
  GET_CHANNELS_MEMBERS_AMOUNT_STAT: "GET_CHANNELS_MEMBERS_AMOUNT_STAT",
  GET_EVENTS: "GET_EVENTS",
  GET_USERS: "GET_USERS",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_INSTAGRAM_PROFILE: "GET_INSTAGRAM_PROFILE",
  GET_RESERVED_WORDS:"GET_RESERVED_WORDS",
  GET_ADDRESSES:"GET_ADDRESSES",
  GET_ORGANIZERS:"GET_ORGANIZERS",
  GET_ME:"GET_ME",
  GET_POSTS:"GET_POSTS",
  GET_CHANNEL_LIST:"GET_CHANNEL_LIST",
  GET_BOT_USERS_STAT:"GET_BOT_USERS_STAT",

  _PENDING: "_PENDING",
  _FULFILLED: "_FULFILLED",
  _REJECTED: "_REJECTED",
};
