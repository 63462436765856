import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

export const getReservedWords = () => ({
  type: actionTypes.GET_RESERVED_WORDS,
  payload: httpRequest({
    method: "GET",
    path: "reserved-words",
  }),
});
