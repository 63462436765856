import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

export const getUsers = () => ({
  type: actionTypes.GET_USERS,
  payload: httpRequest({
    method: "GET",
    path: "users",
  }),
});
