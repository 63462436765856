const emptyEvent = {
  CATEGORY: "CATEGORY",
  FORMAT: "FORMAT",
  TITLE: "TITLE",
  LANGS: "LANGS",
  START_TIME: "START_TIME",
  FROM_TIME: "FROM_TIME",
  ADDRESS: "ADDRESS",
  SELECTED_LOCATION: "SELECTED_LOCATION",
};

export default emptyEvent;
