import { Button, Input, Popover } from "antd";
import useSelection from "antd/lib/table/hooks/useSelection";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttpRequest from "../../hooks/useHttpRequest";
import { getChannelList } from "../../redux/modules/getChannelList";
import { httpRequest } from "../../utils/httpRequest";
import {
  Content,
  Wrapper,
  InputBtnWrapper,
  ListWrapper,
  ListItem,
  FoundChannel,
} from "./styles";

function ListItemRow({
  id,
  title,
  username,
  strippedThumb,
  searchChannel,
  index,
}) {
  const dispatch = useDispatch();
  const leaveChannel = () => {
    httpRequest({
      method: "GET",
      path: "leave-channel",
      params: { username },
    })
      .then((res) => {
        dispatch(getChannelList());
        searchChannel();
      })
      .catch((err) => {});
  };

  return (
    <ListItem>
      <div className="text">
        <b>{index + 1}) </b>
        <a
          href={`tg://resolve?domain=${username}`}
          target="_blank"
          className="text"
          title={`@${username}`}
          rel="noreferrer"
        >
          {title}
        </a>
      </div>
      <Popover
        // color={"black"}
        trigger="click"
        content={
          <div>
            <div>Rostanham bu kanaldan chiqib ketmoqchimisiz</div>
            <div>{title}</div>
            <div>@{username}</div>
            <button
              onClick={() => {
                leaveChannel(id);
              }}
            >
              Leave
            </button>
          </div>
        }
      >
        <div className="button-wrapper">leave</div>
      </Popover>
    </ListItem>
  );
}

function TgChannelList() {
  const dispatch = useDispatch();
  const channels = useSelector((state) => state.channels);

  const [username, setUsername] = useState("");
  const [data, setData] = useState();
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    dispatch(getChannelList());
  }, []);

  const [searchChannelProcess, searchChannelRequest, searchChannelClear] =
    useHttpRequest();

  const searchChannel = () => {
    setFilterText("");
    searchChannelRequest({
      method: "GET",
      path: "telegram-channel",
      params: {
        username,
      },
    })
      .then((res) => {
        console.log("====================================");
        console.log(res);
        console.log("====================================");
        setData(res);
      })
      .catch(() => {});
  };
  const joinChannel = (username) => {
    httpRequest({
      method: "GET",
      path: "join-channel",
      params: {
        username,
      },
    })
      .then((res) => {
        searchChannel();
        dispatch(getChannelList());
      })
      .catch((err) => {});
  };

  const leaveChannel = (username) => {
    httpRequest({
      method: "GET",
      path: "leave-channel",
      params: {
        username,
      },
    })
      .then((res) => {
        searchChannel();
        dispatch(getChannelList());
      })
      .catch((err) => {});
  };

  return (
    <>
      <Wrapper>
        <Content>
          <InputBtnWrapper>
            <Input
              placeholder="Type username of telegram channel"
              onChange={(e) => setUsername(e?.target?.value)}
            />
            <Button
              onClick={() => {
                setFilterText(username);
                searchChannelClear();
              }}
              type="primary"
            >
              local
            </Button>
            <Button onClick={searchChannel} type="primary">
              global
            </Button>
          </InputBtnWrapper>
          {searchChannelProcess?.data?.data?.title && (
            <FoundChannel>
              <a
                href={`https://t.me/${searchChannelProcess?.data?.data?.username}`}
                className="channel-name"
                target="_blank"
                rel="noreferrer"
              >
                {searchChannelProcess?.data?.data?.title}
              </a>
              {searchChannelProcess?.data?.data?.left && (
                <div
                  className="join-channel"
                  onClick={() => joinChannel(username)}
                >
                  Join
                </div>
              )}
            </FoundChannel>
          )}
          <ListWrapper>
            {channels?.data
              ?.filter(
                ({ title, username }) =>
                  title?.includes(filterText) || username?.includes(filterText)
              )
              .map((v, index) => {
                // const base64String = btoa(String.fromCharCode(...new Uint8Array(strippedThumb?.data)));
                return (
                  <ListItemRow
                    {...v}
                    searchChannel={searchChannel}
                    index={index}
                  />
                );
              })}
          </ListWrapper>
        </Content>
      </Wrapper>
    </>
  );
}

export default TgChannelList;
