import React, { useEffect, useState } from "react";
import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import {
  ActionBox,
  AddressBox,
  AddressesPageWrapper,
  AddressesWrapper,
  CloseBox,
  DeleteButton,
  EditButton,
  EditIcon,
  Form,
  ImageBox,
  ImageBoxWrapper,
  Label,
  MapRightBar,
  MapWrapper,
  SwitchBox,
} from "./styles";
import { Button, Checkbox, Input, Popover, Switch } from "antd";
import { InputBox } from "./styles";

import { ReactComponent as AddImg } from "../../assets/icons/add-image.svg";
import { ReactComponent as X } from "../../assets/icons/x.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import Resizer from "react-image-file-resizer";
import useHttpRequest from "../../hooks/useHttpRequest";
import { BASE_URL } from "../../constants/link";
import { getAddresses } from "../../redux/modules/getAddresses";
import { useDispatch, useSelector } from "react-redux";

function AddressesPage() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const addresses = state.addresses;
  const [addressName, setAddressName] = useState("");
  const [addressLongitude, setAddressLongitude] = useState("");
  const [addressLatitude, setAddressLatitude] = useState("");
  const [coords, setCoords] = useState([41.31439136799405, 69.2611792755127]);

  const [edit, setEdit] = useState({ edit: false, data: {} });

  const [selectedLocation, setSelectedLocation] = useState("");

  const [imgUrl, setImgUrl] = useState("");

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
          // console.log(uri);
          setImgUrl(uri);
        },
        "file"
      );
    });

  const [submitImageProcess, makeSubmitImageRequest] = useHttpRequest({
    onSuccess: (res) => {
      setImgUrl(res.data);
    },
  });

  const submitImage = (file) => {
    const formData = new FormData();
    formData.append("image", file);

    makeSubmitImageRequest({
      method: "POST",
      path: "attachment",
      data: formData,
    });
  };

  const [submitAddressProcess, makeSubmitAddressRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(getAddresses());
      setEdit({ edit: false, data: {} });
      setAddressLatitude("");
      setAddressLongitude("");
      setAddressName("");
      setImgUrl("");
    },
  });

  const submitAddress = () => {
    if (addressName && addressLatitude && addressLongitude) {
      const data = {
        name: addressName,
        latitude: addressLatitude,
        longitude: addressLongitude,
        imgId: imgUrl,
      };
      if (edit.edit) {
        makeSubmitAddressRequest({
          method: "PUT",
          path: `address/${edit.data.id}`,
          data,
        });
      } else {
        makeSubmitAddressRequest({
          method: "POST",
          path: "address",
          data,
        });
      }
    }
  };

  useEffect(() => {
    dispatch(getAddresses());
  }, []);

  const [switchAddressProcess, makeSwitchAddressRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(getAddresses());
    },
  });

  const switchAddress = (id, toggle) => {
    makeSwitchAddressRequest({
      method: "PUT",
      path: `address/${!toggle ? "in" : ""}activate/${id}`,
    });
  };

  return (
    <AddressesPageWrapper>
      <MapWrapper>
        <YMaps
          query={{
            apikey: "7446be07-f1bc-4ce3-a5fe-0a423e51a168",
            lang: "uz_UZ",
            coordorder: "latlong",
          }}
        >
          <Map
            width={"100%"}
            height={"calc(100vh - 40px)"}
            defaultState={{
              center: [
                // coords
                41.31439136799405, 69.2611792755127,
              ] /*[55.75, 37.57]*/,
              zoom: 12.5,
            }}
            onClick={(e) => {
              const coordinates = e.get("coords");
              setCoords(coordinates);
              setAddressLatitude(coordinates?.[0]);
              setAddressLongitude(coordinates?.[1]);
              // console.log(coordinates);
            }}
            onChange={(e) => {
              // console.log(e.get("coords"));
            }}
          >
            {!edit.edit && (
              <Placemark
                geometry={[addressLatitude, addressLongitude]}
                options={{ draggable: true }}
                onDragEnd={(e) => {
                  const coordinates = e.get("target").geometry._coordinates;
                  setCoords(coordinates);
                  setAddressLatitude(coordinates?.[0]);
                  setAddressLongitude(coordinates?.[1]);
                  // console.log(coordinates);
                }}
              />
            )}
            {addresses?.data?.map(
              ({ id, name, longitude, latitude, logoImgId }) => {
                // console.log(logoImgId);
                return (
                  <AddressPlaceMark
                    id={id}
                    name={name}
                    longitude={
                      edit.edit && edit.data.id === id
                        ? addressLongitude
                        : longitude
                    }
                    latitude={
                      edit.edit && edit.data.id === id
                        ? addressLatitude
                        : latitude
                    }
                    logoImgId={logoImgId}
                    setSelectedLocation={setSelectedLocation}
                    selectedLocation={selectedLocation}
                    edit={edit.edit && edit.data.id === id}
                    // addressLongitude={addressLongitude}
                    setAddressLongitude={setAddressLongitude}
                    // addressLatitude={addressLatitude}
                    setAddressLatitude={setAddressLatitude}
                  />
                );
              }
            )}

            <ZoomControl />
            <FullscreenControl />
            <SearchControl />
            <GeolocationControl />
          </Map>
        </YMaps>
      </MapWrapper>
      <MapRightBar>
        <Form>
          <p className="title">Add new address</p>
          <InputBox>
            <Input
              placeholder="Address"
              value={addressName}
              onChange={(e) => {
                setAddressName(e.target.value);
              }}
            />
          </InputBox>

          <InputBox>
            <Input
              placeholder="Latitude"
              onChange={(e) => {
                setAddressLatitude(e.target.value);
              }}
              value={addressLatitude}
            />
            <Input
              placeholder="Longitude"
              onChange={(e) => {
                setAddressLongitude(e.target.value);
              }}
              value={addressLongitude}
            />
          </InputBox>

          <InputBox>
            <ImageBoxWrapper>
              {imgUrl ? (
                <div className="img-wrapper">
                  <CloseBox
                    onClick={() => {
                      setImgUrl("");
                    }}
                  >
                    <X />
                  </CloseBox>
                  <img src={`${BASE_URL}/attachment/${imgUrl}`} alt="" />
                </div>
              ) : (
                <Label>
                  <AddImg />
                  <input
                    type="file"
                    onChange={async (e) => {
                      submitImage(await resizeFile(e.target.files[0]));
                    }}
                  />
                </Label>
              )}
            </ImageBoxWrapper>
          </InputBox>
          <Button onClick={submitAddress}>
            {edit.edit ? "Edit" : "Submit"}
          </Button>
          {edit.edit && (
            <Button
              onClick={() => {
                setEdit({ edit: false, data: {} });
                setAddressName("");
                setAddressLatitude("");
                setAddressLongitude("");
                setImgUrl("");
              }}
            >
              Cancel
            </Button>
          )}
        </Form>
        <AddressesWrapper>
          {addresses?.data?.map(
            ({ id, logoImgId, longitude, latitude, name, isActive }) => (
              <AddressBox
                onMouseOver={() => {
                  setSelectedLocation(id);
                }}
                onMouseOut={() => {
                  setSelectedLocation("");
                }}
                active={
                  selectedLocation === id || (edit.edit && edit.data.id === id)
                }
              >
                <SwitchBox>
                  <Checkbox
                    checked={isActive}
                    onChange={(e) => {
                      // console.log(e);
                      switchAddress(id, e.target.checked)}}
                  />
                </SwitchBox>
                <ActionBox className="action">
                  <EditButton
                    onClick={() => {
                      setEdit({
                        edit: true,
                        data: { id, logoImgId, longitude, latitude, name },
                      });
                      setAddressLatitude(latitude);
                      setAddressLongitude(longitude);
                      setAddressName(name);
                      setImgUrl(logoImgId);
                    }}
                  >
                    <Edit />
                  </EditButton>
                  <DeleteButton>
                    <X />
                  </DeleteButton>
                </ActionBox>
                <img
                  className="logo"
                  src={`${BASE_URL}/attachment/${logoImgId}`}
                  alt=""
                />
                <div className="address-name">{name}</div>
              </AddressBox>
            )
          )}
        </AddressesWrapper>
      </MapRightBar>
    </AddressesPageWrapper>
  );
}

export const AddressPlaceMark = ({
  id,
  latitude,
  longitude,
  name,
  logoImgId,
  selectedLocation,
  setSelectedLocation,
  edit,
  // addressLatitude,
  setAddressLatitude,
  // addressLongitude,
  setAddressLongitude,
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Placemark
      onMouseEnter={() => {
        // setHovered(true);
        setSelectedLocation(id);
      }}
      onMouseLeave={() => {
        // setHovered(false);
        setSelectedLocation("");
      }}
      // options={{ draggable: true }}
      onDragEnd={(e) => {
        const coordinates = e.get("target").geometry._coordinates;
        setAddressLatitude(coordinates?.[0]);
        setAddressLongitude(coordinates?.[1]);
        // console.log(coordinates);
      }}
      geometry={[latitude, longitude]}
      options={{
        preset: `islands#${
          edit ? "green" : selectedLocation === id ? "black" : "red"
        }Icon`,
        hintLayout: "hint layout",
        draggable: edit ? true : false,
        // iconShape: "Circle",
        // iconLayout: "default#imageWithContent",
        // iconImageSize: [50, 50],
        // iconImageHref: `${BASE_URL}/attachment/${logoImgId}`,
        // iconImageHref: `https://img.icons8.com/ios-filled/2x/marker-a.png`,
        hideIconOnBalloonOpen: false,
        ...(edit
          ? { zIndex: 25 }
          : selectedLocation === id
          ? { zIndex: 30 }
          : { zIndex: 20 }),
      }}
      properties={{
        iconContent: `
        <div class="myIconContentBox ${
          edit ? "edit" : selectedLocation === id ? "active" : ""
        }">
          <p class="myIconContentPopover">${name}</p>
          <img title=${name} style='width:100%; height:100%; border-radius:50%; object-fit:cover;' src='${BASE_URL}/attachment/${logoImgId}'/>
        </div>`,

        // balloonContent: "balloonContent",
        // hintContent: "hintContent",
      }}
    />
  );
};

export default AddressesPage;
