import React, { useState } from "react";
import {
  ActionBtn,
  ActionBtnDots,
  ActionListBtn,
  ActionWrapperCorner,
  MainWrapper,
  MakePostBtn,
  Poster,
  SelectionHeader,
  SelectionWrapper,
  SelectItem,
  TextBox,
  ViewBtn,
  Wrapper,
} from "./styles";

import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as YesIcon } from "../../assets/icons/add-circle.svg";
import { ReactComponent as NoIcon } from "../../assets/icons/minus-circle.svg";
import { ReactComponent as RepeatIcon } from "../../assets/icons/refresh-circle.svg";

// import img from "../../assets/photo/chat.png";
import { BASE_URL } from "../../constants/link";
import useHttpRequest from "../../hooks/useHttpRequest";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../../redux/modules/getPosts";
import { Button, Popover, Switch } from "antd";
import { useEffect } from "react";
import { useRef } from "react";
import moment from "moment";
// import { getDateData } from "../editEventPopUp";
import { httpRequest } from "../../utils/httpRequest";
import stringSimilarity from "string-similarity";
import { parseToJson } from "../../utils/json";
import { getDateData } from "../../utils/dateTimeManager";
import LanguageDetect from "languagedetect";
const cyrillicAlphabetLowerCase = /[абвгдеёжзийклмнопрстуфхцчшщъыьэюя]/gi;
const cyrillicAlphabetUpperCase = /АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ/gi;
const cyrillicAlphabet =
  /АаБбВвГгДдЕеЁёЖжЗзИиЙйКкЛлМмНнОоПпРрСсТтУуФфХхЦцЧчШшЩщЪъЫыЬьЭэЮюЯя/gi;

const latinAlphabetLowerCase = /[abcdefghijklmnopqrcuvwxyz]/gi;
const latinAlphabetUpperCase = /[ABCDEFGHIJKLMNOPQRSUVWXYZ]/g;
const latinAlphabet = /AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrScUuVvWwXxYyZz/gi;

const hashtag = /#[a-z_-]+/gi;
const pastSimple = /[a-z]{3,20}di/gi;
const noneEventHashtags =
  /#((success[_-\s]story)|(tashrif)|(tayinlov)|(jurnal)|(imkoniyat)([Vv]isit)|(ITParkDigest)|([a-z-_]{5,20}me[hx]monda)|(sarhisob)|(qiziq)|(fotoreportaj)|(muhim_sana)|(dayjest)|(statistika)|(cooperation)|(iTechLife_vacancy)|(TEAMStars)|(photoreport)|(startup_story))/gi;
const noneEventWords =
  /(o'tkazildi)|(hosted)|(was held)|(held)|(bo[‘'’ʻʻ]lib o[‘'’ʻʻ]tdi)|(bo[‘'’ʻʻ]lib o[‘'’ʻʻ]tmoqda)|(fotoreportaj)|(((daqiqa)|(minut)) qoldi)|(tashrif buyurdi)|(mu[xh]okama qilindi)|(premyera boshlandi)|(Eslatib o'tamiz,)|(hisoboti fotosuratlarda)|(qabul[A-Za-z ]{2,20}boshlandi)/gi;
const timeRegExp = /(0[0-9]|1[0-9]|2[0-4]|[0-9])[:.][0-5][0-9]/g;
export const dateInNumRegExp =
  /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})|(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))|(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/g;
export const dateRegExp =
  /(((([0-3(0️⃣)(1️⃣)(2️⃣)(3️⃣)][0-9(0️⃣)(1️⃣)(2️⃣)(3️⃣)(4️⃣)(5️⃣)(6️⃣)(7️⃣)(8️⃣)(9️⃣)])|[1-9(0️⃣)(1️⃣)(2️⃣)(3️⃣)(4️⃣)(5️⃣)(6️⃣)(7️⃣)(8️⃣)(9️⃣)])[–-\s–−])+(yanv|fev|mar|apr|may|iyun|iyul|avg|sen|okt|noy|dek|янв|фев|март)[a-zа-я]+)/gi;
// /(((((([1-9])|([0-3][0-9]))-(([1-9])|([0-3][0-9])))|(([1-9])|([0-3][0-9])))[-\s](yanv|fev|mar|apr|may|iyun|iyul|avg|sen|okt|noy|dek|янв|фев)[a-zа-я]+))/gi;
const year = /\b(20[0-9][0-9])\b/g;
const timeRegExpText =
  /b(o.|u)g(o.|u)n[^(gi)]|ertaga|dushanba|seshanba|сегодня/gi;
const registrationRegExp =
  /([rR](o.|u)yxatdan (o.|u)tis[a-z]+)|(registration+)|(регистрация)/gi;
const event = /((tadbi[a-z]+)|(event))[\s\n,.<]/gi;
const fullName =
  /([A-Z][A-Za-z'`‘’]+[\s][A-Z][A-Za-z'`‘’]+)|([А-ЯЁ][А-Яа-яЁё'`‘ʼ]+[\s ][А-ЯЁ][А-Яа-яЁё'`‘ʼ]+)/g;
const quotation =
  /(("[0-9A-Za-zА-Яа-яЁё,.?&'`‘’\s-<>/ +][^://][^.com][^href][^class=][^tel:]+")|(«[0-9A-Za-zА-Яа-яЁё:_"=,.?&'`‘’\s-<>/ +]+»)|(“[0-9A-Za-zА-Яа-яЁё:_"=,.?&'`‘’\s-<>/ +]+”))/gi;
// /("[A-Za-zА-Яа-я'`‘’\s<>/ ]+")|(«(.*?)»)|(«(.*?)»)|(“[A-Za-zА-Яа-я'`‘’\s<>/ ]+”)/g;
// const upperCases = /[A-ZА-Я]{2,}/
// const quotation = /("")|(«[A-Za-zа-я‘'`\s]+)»/;
//  const fullName = /([A-Z])([a-zа-я‘'`])+\s(([A-Z])([a-zа-я‘'`])+[\n\s,])+/g;
// const fullName = /([A-Z])([A-Za-zа-я‘'`])+\s([A-Z][A-Za-zа-я‘'`]+[\n\s,<])+/g;
// const fullName = /([A-Z][a-zа-я‘'`]\s])+[\n\s,]/g;
const suffixes = [
  "ov",
  "ev",
  "ova",
  "eva",
  "ovlar",
  "evlar",
  "ovalar",
  "evalar",
  "ов",
  "ев",
  "ова",
  "ева",
];
const excludeSuffixes = ["mohirdev"];

const online = "online|onlayn";
const offline = "offline|ofline|oflayn|offlayn|qayerda:|manzil";
const format = new RegExp(`${online}|${offline}`, "gi");

function highlightPattern(
  text,
  actions,
  setActions,
  foundItems,
  setFoundItems
  // hashtags,
  // setHashtags
) {
  // return text;
  // const splitText = text.split(pattern);

  // if (splitText.length <= 1) {
  //   return text;
  // }

  text = text.replace(/<\/b> <b>/g, " ");

  const list = [
    {
      regExp: quotation,
      type: "quotation",
    },
    {
      regExp: dateRegExp,
      type: "date",
    },
    {
      regExp: dateInNumRegExp,
      type: "date",
    },
    {
      regExp: timeRegExp,
      type: "time",
    },
    {
      regExp: timeRegExpText,
      type: "date",
    },
    {
      regExp: registrationRegExp,
      type: "registration",
    },
    // {
    //   regExp: year,
    //   type: "year",
    // },
    {
      regExp: fullName,
      type: "fullName",
    },
    {
      regExp: event,
      type: "e_vent",
    },
    // {
    //   regExp: hashtag,
    //   type: "hashtags",
    // },
    {
      regExp: noneEventHashtags,
      type: "none_event_hashtag",
    },
    {
      regExp: noneEventWords,
      type: "none_event_word",
    },
    {
      regExp: format,
      type: "format",
    },
    // {
    //   regExp: pastSimple,
    //   type: "past_simple",
    // },

    // {
    //   regExp:upperCases,
    //   type:"uppercase"
    // }
    // {
    //   regExp: latinAlphabetLowerCase,
    //   type: "latin",
    // },
    // {
    //   regExp: cyrillicAlphabetLowerCase,
    //   type: "cyrillic",
    // },
  ];

  list.forEach(({ regExp, type }) => {
    const matches = text.match(regExp);
    // ?.reduce((t, v, i) => (!t.includes(v) ? [...t, v] : t), [])
    // ?.filter((v) => v);
    if (matches?.length) {
      // if (
      //   type === "hashtags" &&
      //   matches.filter((v) => !hashtags.includes(v)).length
      // ) {
      //   setHashtags([
      //     ...hashtags,
      //     ...matches.filter((v) => !hashtags.includes(v)),
      //   ]);
      // }
      if (type !== "fullName") {
        if (!foundItems[type]) {
          setFoundItems((prev) => ({ ...prev, [type]: matches?.length }));
        }
        // console.log({ type, amount: matches?.length });
      }

      text = matches?.reduce((t, v, i) => {
        if (v) {
          // console.log("====================================");
          // console.log(v, matches, regExp, type, text);
          // console.log("====================================");
          if (type === "date") {
            const { day, month, year } = getDateData(v);
            // console.log("DateDataInPost: ", { day, month, year });
            // console.log("====================================");
            // console.log(v, `${day}/${month}/${year}`);
            // console.log("====================================");
            if (
              day &&
              month &&
              year &&
              day !== -1 &&
              month !== -1 &&
              year !== -1
            ) {
              const currentDate = new Date(Date.now());
              const eventAt = new Date(Date.now());
              eventAt.setMonth(month - 1);
              eventAt.setFullYear(year);
              eventAt.setDate(day);
              // console.log("====================================");
              // console.log(currentDate, eventAt);
              // console.log("====================================");
              if (eventAt < currentDate) {
                if (!foundItems.expired) {
                  setFoundItems((prev) => ({
                    ...prev,
                    expired: prev.expired ? prev.expired + 1 : 1,
                  }));
                }
              }
            }
          }
          if (type === "fullName") {
            let fullName = localStorage.getItem("fullName");
            fullName = JSON.parse(fullName) || [];
            const word = v.endsWith(" ")
              ? v.slice(0, v?.length - 1)
              : v.slice(0, v.length - 2);
            if (fullName.indexOf(word) === -1) {
              fullName.push(word);
              // localStorage.removeItem("fullName", JSON.stringify(fullName));
              localStorage.setItem("fullName", JSON.stringify(fullName));
            }
          }
          let word = v;
          let space = "";
          if (["fullName", "time", "e_vent", "quotation"].includes(type)) {
            word =
              v &&
              (v?.endsWith(" ") ||
                v?.endsWith("\n") ||
                v?.endsWith(",") ||
                v?.endsWith("-") ||
                v?.endsWith("<") ||
                v?.endsWith("."))
                ? v?.slice(0, v.length - 1)
                : v;
            space =
              v &&
              (v?.endsWith(" ") ||
                v?.endsWith("\n") ||
                v?.endsWith(",") ||
                v?.endsWith("-") ||
                v?.endsWith("<") ||
                v?.endsWith("."))
                ? v.slice(v.length - 1)
                : "";
          }
          if (type === "fullName") {
            const [n1, n2] = word.split(" ");
            if (
              !excludeSuffixes.filter(
                (eS) =>
                  n1?.toLowerCase() === eS?.toLowerCase() ||
                  n2?.toLowerCase() === eS?.toLowerCase()
              ).length
            ) {
              const isFullName = suffixes.filter(
                (suffix) =>
                  n1?.length > 3 &&
                  n2?.length > 3 &&
                  (n1?.endsWith(suffix) || n2?.endsWith(suffix))
              ).length;
              if (isFullName && !foundItems[type]) {
                setFoundItems((prev) => ({ ...prev, [type]: matches?.length }));
              }
              return t
                ?.split(v)
                ?.join(
                  `${
                    isFullName
                      ? `<mark class=${type}>${word}</mark>`
                      : `<mark class="capitalized">${word}</mark>`
                  }${space}`
                );
            }
            return t;
          }
          return t.split(v).join(`<mark class=${type}>${word}</mark>${space}`);
        }
        return t;
      }, text);
    }
  });

  return text;

  // return splitText.reduce((arr, element, index) => (matches[index] ? [
  //   ...arr,
  //   element,
  //   `<mark key=${index}>${matches[index]}</mark>`,
  // ] : [...arr, element]), []).join("");
}

function predictPostLang(text) {
  const latinMatches = text
    ?.replace(/<(.|\n)*?>/g, "")
    ?.match(latinAlphabetLowerCase);
  const cyrillicMatches = text
    ?.replace(/<(.|\n)*?>/g, "")
    ?.match(cyrillicAlphabetLowerCase);
  const latinPercent = Math.round(
    ((latinMatches?.length || 0) /
      ((latinMatches?.length || 0) + (cyrillicMatches?.length || 0)) || 0) * 100
  );
  const cyrillicPercent = Math.round(
    ((cyrillicMatches?.length || 0) /
      ((latinMatches?.length || 0) + (cyrillicMatches?.length || 0)) || 0) * 100
  );
  const diff = Math.abs(latinPercent - cyrillicPercent);

  const ld = new LanguageDetect();
  const detectLng = ld.detect(text);
  // console.log(detectLng);
  const lng = detectLng?.[0];
  // console.log(lng);
  return {
    lang: {
      name: "Language",
      value: lng?.[0],
      prediction: lng?.[1],
    },
    length: {
      name: "Length",
      value: text?.replace(/<(.|\n)*?>/g, "").length,
    },
    latin: {
      name: "Latin",
      value: latinMatches?.length || 0,
      checkbox: diff <= 20 || latinPercent > cyrillicPercent,
      percent: latinPercent,
    },
    cyrillic: {
      name: "Cyrillic",
      value: cyrillicMatches?.length || 0,
      checkbox: diff <= 20 || latinPercent < cyrillicPercent,
      percent: cyrillicPercent,
    },
  };
  // `<small style="line-height:0.5">
  //     Length: ${text?.replace(/<(.|\n)*?>/g, "").length}\n${
  //   diff <= 20 || latinPercent > cyrillicPercent ? "✅" : ""
  // }Latin: ${latinMatches?.length || 0}  (${latinPercent}%)\n${
  //   diff <= 20 || latinPercent < cyrillicPercent ? "✅" : ""
  // }Cyrillic: ${
  //   cyrillicMatches?.length || 0
  // }  (${cyrillicPercent}%)</small> \n\n`;
}

function analyzeText(textStr) {
  const text = textStr.split(/[\W\d]/gi).filter((v) => v);
  // const text = textStr.split(/[\s,.-]/gi).filter((v) => v);
  // return [...new Set([...text])];
  return text;
}

function predictOrganizers(textStr, organizers) {
  const text = textStr.split(/[\W\d]/gi).filter((v) => v);
  const orgs = [];
  organizers?.forEach((o) => {
    const { name } = o;
    const oo = name
      ?.split(/[\W\d]/gi)
      .filter((v) => v && v.toLowerCase() !== "uz");

    for (let i = 0; i < text.length - oo.length; i++) {
      let isOrg = 0;
      for (let j = 0; j < oo.length; j++) {
        const orgPart = oo[j];
        const textPart = text[i + j];
        const similarity = stringSimilarity.compareTwoStrings(
          textPart,
          orgPart
        );
        if (similarity >= 0.6) {
          // console.log(textPart, orgPart, similarity);
          isOrg++;
        }
        // textPart.includes(orgPart);
        console.log();
      }
      // isOrg =
      //   isOrg ||
      //   Math.round(stringSimilarity.compareTwoStrings(text[i], oo.join("")));
      if (
        (isOrg === oo.length || (oo.length >= 3 && oo.length - isOrg <= 1)) &&
        !orgs.find(({ id }) => id === o?.id)
      ) {
        // console.log("Found ORG: ", o);
        orgs.push(o);
      }
    }
  });

  // console.log("====================================");
  // console.log(orgs);
  // console.log("====================================");
  return orgs;
  // return (
  //   organizers?.map(({ name }) => name).join(", ") + "\n\n" + text.join(", ")
  // );
}

// function getHighlightedText(text, highlight) {
//   // Split text on highlight term, include term itself into parts, ignore case
//   const parts = text.split(highlight);
//   return `<span>${parts.map(part => part === highlight ? `<b>${part}</b>` : part)}</span>`;
// }

function PostCard({
  createdAt,
  fromId,
  id,
  isActive,
  msgId,
  photoId,
  senderId,
  statusType,
  text,
  updatedAt,
  videoId,
  setMakePost,
  preview,
  filter,
  textSelection,
  html,
  markdown,
  entities,
  // hashtags,
  // setHashtags,
  deletable,
  setDeletable,
  onlyDeletable,
}) {
  entities = parseToJson(entities);
  const dispatch = useDispatch();
  const [actionOpen, setActionOpen] = useState(false);

  const [actions, setActions] = useState([]);
  const [foundItems, setFoundItems] = useState({});

  const [markUp, setMarkUp] = useState(false);

  const [dragStart, setDragStart] = useState({
    offsetTop: 0,
    offsetLeft: 0,
    x: 0,
    y: 0,
  });
  const [mouseDown, setMouseDown] = useState({
    offsetTop: 0,
    offsetLeft: 0,
    x: 0,
    y: 0,
  });

  const [translate, setTranslate] = useState({
    translate: false,
    text: "",
  });

  const selectionRef = useRef();

  const [selection, setSelection] = useState({
    popUp: false,
    text: "",
    x: 0,
    y: 0,
  });

  const [changeStatusTypeProcess, makeChangeStatusTypeRequest] = useHttpRequest(
    {
      onSuccess: () => {
        dispatch(getPosts(filter));
      },
    }
  );

  // const [channelDataProcess, channelDataRequest] = useHttpRequest();

  const changeStatusType = ({ id, statusType }) => {
    makeChangeStatusTypeRequest({
      method: "PUT",
      path: `post-list/${id}`,
      data: { statusType },
    });
  };

  const [deletePostProcess, makeDeletePostRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(getPosts(filter));
    },
  });

  const deletePost = (id) => {
    makeDeletePostRequest({
      method: "DELETE",
      path: `post-list/${id}`,
    });
  };

  const mouseUp = (e) => {
    const list = [
      { text: "Title", icon: "", value: "title" },
      { text: "Type", icon: "", value: "type" },
      // {
      //   text: "Date",
      //   icon: "",
      //   value: "date_from",
      //   sub: [
      //     { text: "from", icon: "", value: "date_from" },
      //     { text: "to", icon: "", value: "date_to" },
      //   ],
      // },
      // {
      //   text: "Time",
      //   icon: "",
      //   value: "time_from",
      //   sub: [
      //     { text: "from", icon: "", value: "time_from" },
      //     { text: "to", icon: "", value: "time_to" },
      //   ],
      // },
      { text: "Speaker", icon: "", value: "speaker_name" },
      { text: "Speaker Desc", icon: "", value: "speaker_desc" },
      { text: "Organizer", icon: "", value: "organizer_name" },
      { text: "Address Name", icon: "", value: "address_name" },
      { text: "Registration Link", icon: "", value: "registration_link" },
      { text: "Event Link", icon: "", value: "event_link" },
      // { text: "Payment", icon: "", value: "payment" },
      { text: "Description", icon: "", value: "description" },
    ];

    const numberList = [
      {
        text: "Date",
        icon: "",
        value: "date_from",
        sub: [
          { text: "from", icon: "" },
          { text: "to", icon: "" },
        ],
      },
      {
        text: "Time",
        icon: "time_from",
        value: "time_from",
        sub: [
          { text: "from", icon: "" },
          { text: "to", icon: "" },
        ],
      },
      { text: "Payment", icon: "", value: "payment" },
    ];

    const text = window.getSelection().toString();
    if (text && text !== selection.text) {
      const x = e?.clientX;
      const y = e?.clientY;
      // console.log(window.getSelection());
      const number = Number(text);
      if (number) {
        setSelection({ popUp: true, text, x, y, list: numberList });
      } else {
        setSelection({ popUp: true, text, x, y, list });
      }
    } else {
      setSelection({ popUp: false, text: "", x: 0, y: 0 });
    }
  };
  const state = useSelector((state) => state);
  const { data: channels } = state.channels;

  const channel = channels?.find(({ id: cId }) => cId === fromId);

  // const onMouseMove = (e) => {
  //   console.log("mouseMove", e, mouseDown);
  //   if (
  //     mouseDown.x ||
  //     mouseDown.y ||
  //     mouseDown.offsetLeft ||
  //     mouseDown.offsetTop
  //   ) {
  //     const x = e.clientX;
  //     const y = e.clientY;
  //     const { offsetTop, offsetLeft } = selectionRef.current;
  //     console.log("Mouse Move: ", e, x, y, offsetTop, offsetLeft);
  //     setSelection((prev) => ({
  //       ...prev,
  //       x: x - (mouseDown.x - mouseDown.offsetLeft),
  //       y: y - (mouseDown.y - mouseDown.offsetTop),
  //     }));
  //   }
  // };
  // let mouseActive = false;
  // useEffect(() => {
  //   if (preview) {
  //     if (
  //       !mouseActive &&
  //       mouseDown.x &&
  //       mouseDown.y &&
  //       mouseDown.offsetLeft &&
  //       mouseDown.offsetTop
  //     ) {
  //       window.addEventListener("mousemove", onMouseMove);
  //       mouseActive = true;
  //     } else if (mouseActive) {
  //       window.removeEventListener("mousemove", onMouseMove);
  //       mouseActive = false;
  //       console.log("Removed");
  //     }
  //   }
  // }, [mouseDown]);

  useEffect(() => {
    // channelDataRequest({
    //   path: "telegram/profile",
    //   params: {
    //     chatId: fromId,
    //   },
    // });
    // console.log("====================================");
    // console.log(html);
    // console.log("====================================");
    setFoundItems({});
  }, [id]);

  // const timeRegExp = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  useEffect(() => {
    setActions([]);
    if (Object.entries(foundItems).length >= 0) {
      const textLength = text?.length <= 150 ? -10 : 0;
      const none_event_hashtag = !!foundItems.none_event_hashtag ? -10 : 0;
      const none_event_word = !!foundItems.none_event_word ? -10 : 0;
      const expired = !!foundItems.expired ? -10 : 0;
      const time = !!foundItems.time ? 10 : 0;
      const date = !!foundItems.date ? 9 : 0;
      const year = !!foundItems.year ? 2 : 0;
      const quotation = !!foundItems.quotation ? 4 : 0;
      const registration = !!foundItems.registration ? 5 : 0;
      const fullName = !!foundItems.fullName ? 4 : 0;
      const e_vents = !!foundItems.e_vents ? 9 : 0;
      const result =
        textLength +
        none_event_hashtag +
        none_event_word +
        expired +
        time +
        date +
        year +
        quotation +
        registration +
        fullName +
        e_vents;

      if (result < 5) {
        setActions({ action: ["DELETE"], number: result });
      } else if (result >= 5 && result < 10) {
        setActions({ action: ["NOT_EVENT"], number: result });
      } else if (result >= 10 && result <= 20) {
        setActions({ action: ["EVENT", "NOT_EVENT"], number: result });
      } else if (result > 20) {
        setActions({ action: ["EVENT"], number: result });
      }
      // console.log("====================================");
      // console.log("fountItems: ", foundItems, result);
      // console.log("====================================");
    }
  }, [foundItems]);

  useEffect(() => {
    // setActions([]);
    if (
      deletable &&
      !deletable?.includes(id) &&
      Object.entries(foundItems).length >= 0
    ) {
      const textLength = text?.length <= 150 ? -10 : 0;
      const none_event_hashtag = !!foundItems.none_event_hashtag ? -10 : 0;
      const none_event_word = !!foundItems.none_event_word ? -10 : 0;
      const expired = !!foundItems.expired ? -10 : 0;
      const time = !!foundItems.time ? 10 : 0;
      const date = !!foundItems.date ? 9 : 0;
      const year = !!foundItems.year ? 2 : 0;
      const quotation = !!foundItems.quotation ? 4 : 0;
      const registration = !!foundItems.registration ? 5 : 0;
      const fullName = !!foundItems.fullName ? 4 : 0;
      const e_vents = !!foundItems.e_vents ? 9 : 0;
      const result =
        textLength +
        none_event_hashtag +
        none_event_word +
        expired +
        time +
        date +
        year +
        quotation +
        registration +
        fullName +
        e_vents;
      const lang = predictPostLang(text);
      const lng = lang?.lang?.value;

      if (
        (result < 5 && !(lng === "russian" || lng === "english")) ||
        (result <= 0 && (lng === "russian" || lng === "english"))
      ) {
        // console.log(lng);
        setDeletable((prev) => [...prev, ...(!prev?.includes(id) ? [id] : [])]);
      } else {
        setDeletable((prev) => prev.filter((dId) => dId !== id));
      }
      // if (result < 5) {
      //   setActions({ action: ["DELETE"], number: result, lang });
      // } else if (result >= 5 && result < 10) {
      //   setActions({ action: ["NOT_EVENT"], number: result });
      // } else if (result >= 10 && result <= 20) {
      //   setActions({ action: ["EVENT", "NOT_EVENT"], number: result });
      // } else if (result > 20) {
      //   setActions({ action: ["EVENT"], number: result });
      // }
      // console.log("====================================");
      // console.log("fountItems: ", foundItems, result);
      // console.log("====================================");
    }
  }, [foundItems, deletable]);

  const addEventToClasses = () => {
    document.querySelectorAll("mark.time").forEach((v) => {
      v.addEventListener("click", () =>
        textSelection({ type: "time_from", text: v.innerText })
      );
    });
    document.querySelectorAll("mark.quotation").forEach((v) => {
      v.addEventListener("click", () =>
        textSelection({ type: "title", text: v.innerText })
      );
    });
    document.querySelectorAll("mark.format").forEach((v) => {
      v.addEventListener("click", () =>
        textSelection({ type: "format", text: v.innerText })
      );
    });
    document.querySelectorAll("mark.fullName").forEach((v) => {
      v.addEventListener("click", () =>
        textSelection({ type: "speaker_name", text: v.innerText })
      );
    });
    document.querySelectorAll("mark.date").forEach((v) => {
      v.addEventListener("click", () => {
        // console.log("====================================");
        // console.log(
        //   "innerText, test, createdAt: ",
        //   v.innerText,
        //   /(b(o.|u)g(o.|u)n[^(gi)])|(сегодня)/gi.test(v.innerText),
        //   createdAt
        // );
        // console.log("====================================");
        textSelection({
          type: "date_from",
          text: /(b(o.|u)g(o.|u)n[^(gi)])|(сегодня)/gi.test(v.innerText)
            ? createdAt
            : v.innerText,
        });
      });
    });
  };

  useEffect(() => {
    if (preview) {
      addEventToClasses();
    }
  }, [translate.translate]);

  const translateText = (text) => {
    //  const url = `https://clients5.google.com/translate_a/t?client=dict-chrome-ex&sl=auto&tl=en&q=${text}`;
    //  const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=en&q=${text}`;
    // const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=uz&dt=t&q=${text}`;
    httpRequest({
      method: "GET",
      path: "translate",
      params: {
        client: "gtx",
        sl: "auto",
        tl: "uz",
        dt: "t",
        q: text,
      },
    }).then((res) => {
      const text = res.data[0].reduce(
        (t, [translatedText, v]) => t + translatedText,
        ""
      );
      console.log("====================================");
      console.log(text);
      console.log("====================================");
      setTranslate((prev) => ({
        ...prev,
        text,
      }));
    });
  };

  const extractLink = () => {};

  const registrationLink = (text, entities) => {
    if (entities) {
      // console.log(entities);
      const types = ["mention", "url", "email", "text_link", "text_mention"];
      entities = entities?.filter(
        ({ type, offset, length, url, user, language, custom_emoji_id }) =>
          types.includes(type)
      );

      if (entities?.length > 0) {
        let matches = text.match(registrationRegExp);
        matches = [...new Set(matches)];
        let result = [];
        if (matches?.length) {
          matches.forEach((m) => {
            const splits = text.split(m);
            splits.slice(0, splits.length - 1).forEach((s, index) => {
              const startsAt =
                splits.slice(0, index + 1).join("").length + m?.length * index;
              const endsAt = startsAt + m.length;

              const extra = {
                startsAt,
                sliceLength: splits.slice(0, index + 1).join("").length,
                index,
              };

              const rations = entities.reduce((t, v, i) => {
                if (v.offset + v.length <= startsAt) {
                  return [
                    ...t,
                    {
                      ration: startsAt - v?.offset + v.length,
                      entity: v,
                      extra,
                    },
                  ];
                } else if (v.offset >= endsAt) {
                  return [
                    ...t,
                    { ration: v?.offset - endsAt, entity: v, extra },
                  ];
                } else {
                  return [...t, { ration: 0, entity: v, extra }];
                }
              }, []);
              const link = rations.sort((a, b) => a.ration - b.ration)?.[0];
              if (link && link?.ration < 15) {
                const { ration, entity } = link;
                // result.push(link);
                if (entity?.url) {
                  result.push(entity?.url);
                } else {
                  result.push(text.substr(entity?.offset, entity?.length));
                }
                // return rations;
              }
            });
          });
          // console.log(matches);
        }

        return result;
        // return matches || [];
      }
    }
  };

  const predictFormat = (text) => {
    const offlines = text.match(new RegExp(offline, "gi"));
    const onlines = text.match(new RegExp(online, "gi"));
    const offLen = offlines?.length || 0;
    const onLen = onlines?.length || 0;
    const format = [];
    if (offLen) {
      format.push("OFFLINE");
    }
    if (onLen) {
      format.push("ONLINE");
    }
    if (offLen && onLen) {
      format.push("HYBRID");
    }
    return format;
  };

  const predictTitle = (text, entities) => {
    const titles = [];
    const quotations = text
      ?.match(quotation)
      ?.map((q) => {
        return q &&
          (q?.endsWith(" ") ||
            q?.endsWith("\n") ||
            q?.endsWith(",") ||
            q?.endsWith("-") ||
            q?.endsWith("<") ||
            q?.endsWith("."))
          ? q?.slice(0, q.length - 1)
          : q;
      })
      ?.filter((q) => {
        return q?.split(" ")?.length > 3 && !q?.includes("\n");
      });
    titles.concat(quotations);
    entities
      ?.filter(({ type }) => type === "bold")
      ?.forEach(({ offset, length }) => {
        const t = text.substr(offset, length);

        const isValid = !(
          dateInNumRegExp.test(t) ||
          dateRegExp.test(t) ||
          timeRegExp.test(t) ||
          timeRegExpText.test(t) ||
          registrationRegExp.test(t) ||
          t?.split(" ")?.length <= 3 ||
          /bog.laning/gi.test(t) ||
          /[0-9][.]/gi.test(t)
        );

        // console.log(
        //   String(t),
        //   isValid,
        //   dateInNumRegExp.test(t),
        //   dateRegExp.test(t),
        //   timeRegExp.test(t),
        //   timeRegExpText.test(t),
        //   t?.split(" ")?.length <= 3,
        //   t?.split("\n")?.filter((v) => {
        //     return Boolean(v) && analyzeText(v)?.filter((e) => e)?.length;
        //   })?.length !== 1
        // );

        if (isValid) {
          const takeTitle = t
            ?.split("\n")
            ?.filter(
              (v) => Boolean(v) && analyzeText(v)?.filter((e) => e)?.length
            );
          if (takeTitle?.length === 1) {
            titles.push(takeTitle[0]);
          } else if (takeTitle?.length < 1) {
            titles.push(t);
          }
        }
      });
    // return format;
    return [...new Set(titles)];
  };

  useEffect(() => {
    if (preview) {
      // PREDICT_TITLE
      const titles = predictTitle(text, entities);
      console.log("====================================");
      console.log(titles);
      console.log("====================================");

      let titleText = titles.find((v) => {
        if (/^["«»“”]/.test(v)) {
          v = v.slice(1);
        }
        if (/["«»“”]$/.test(v)) {
          v = v.slice(0, v.length - 1);
        }
        console.log("====================================");
        console.log(v, latinAlphabetUpperCase.test(v.charAt(0)));
        console.log("====================================");
        const titleSplits = v.split(" ");

        // const isExcludedExist = !excludeSuffixes.filter(
        //   (eS) =>
        //     titleSplits.filter((t) => t?.toLowerCase() === eS?.toLowerCase())
        //       .length
        // ).length;

        const isFullName = suffixes.filter(
          (suffix) =>
            titleSplits.filter((t) => t.length > 3 && t?.endsWith(suffix))
              .length
          // n1?.length > 3 &&
          //   n2?.length > 3 &&
          //   (n1?.endsWith(suffix) || n2?.endsWith(suffix))
        ).length;
        // return Boolean(isFullName);

        return (
          !isFullName &&
          (latinAlphabetUpperCase.test(v.charAt(0)) || titles?.length === 1)
        );
      });
      if (titleText) {
        textSelection({
          type: "title",
          text: titleText,
        });
      }

      // PREDICT_REG_LINK
      const regLink = registrationLink(text, entities)?.[0];
      if (regLink) {
        textSelection({
          type: "registration_link",
          text: regLink,
        });
      }
      // ORGANIZER
      textSelection({
        type: "organizer",
        text: {
          name: channel?.title,
          url: `https://t.me/${channel?.username}`,
        },
      });
      // FORMAT
      const format = predictFormat(text);
      if (format) {
        textSelection({
          type: "format",
          text: format,
        });
      }
    }
  }, [id]);

  const organizers = useSelector((state) => state.organizers);

  return !onlyDeletable || (onlyDeletable && deletable?.includes(id)) ? (
    <MainWrapper preview={preview}>
      <Wrapper preview={preview}>
        {photoId && (
          <Poster>
            <img src={`${BASE_URL}/attachment/tg-url/${photoId}`} alt="" />
          </Poster>
        )}
        {/* {JSON.stringify(entities || [])} */}
        <TextBox
          onMouseUp={(e) => preview && mouseUp(e)}
          // onMouseUpCapture={console.log}
        >
          {actions?.action?.length > 0 && (
            <ActionListBtn
              style={{
                width: "fit-content",
                display: "flex",
                flexDirection: "row",
                backgroundColor: deletable?.includes(id)
                  ? "lightblue"
                  : "transparent",
              }}
            >
              {actions?.action?.map((action, index) => (
                <div key={index}>
                  {action === "EVENT" ? (
                    <ActionBtn
                      onClick={() => {
                        !changeStatusTypeProcess.loading &&
                          changeStatusType({ id, statusType: "EVENT" });
                      }}
                    >
                      <YesIcon />
                    </ActionBtn>
                  ) : action === "NOT_EVENT" ? (
                    <ActionBtn
                      onClick={() => {
                        !changeStatusTypeProcess.loading &&
                          changeStatusType({ id, statusType: "NOT_EVENT" });
                      }}
                    >
                      <NoIcon />
                    </ActionBtn>
                  ) : action === "REPEAT" ? (
                    <ActionBtn
                      onClick={() => {
                        !changeStatusTypeProcess.loading &&
                          changeStatusType({ id, statusType: "REPEAT" });
                      }}
                    >
                      <RepeatIcon />
                    </ActionBtn>
                  ) : action === "DELETE" ? (
                    <ActionBtn
                      onClick={() => {
                        deletePost(id);
                      }}
                    >
                      <TrashIcon />
                    </ActionBtn>
                  ) : (
                    ""
                  )}
                </div>
              ))}
              <ActionBtn>{actions?.number}</ActionBtn>
            </ActionListBtn>
          )}
          <small
            style={{ lineHeight: 1.2, margin: "10px 0", display: "block" }}
          >
            {Object.values(predictPostLang(text)).map(
              ({ name, value, checkbox, percent, prediction }) => (
                <span style={{ display: "block" }}>
                  {checkbox ? "✅" : ""} {name}: {value}{" "}
                  {prediction?.toFixed(2)} {percent ? `(${percent}%)` : ""}
                </span>
              )
            )}
          </small>
          <ul>
            {predictTitle(text, entities)?.map((v) => (
              <li>
                {/* <span> */}
                <span style={{ backgroundColor: "lightblue", color: "black" }}>
                  {v}
                </span>{" "}
                {preview && (
                  <button
                    onClick={() => {
                      textSelection({
                        type: "title",
                        text: v,
                      });
                    }}
                    style={{ display: "block", color: "black" }}
                  >
                    title
                  </button>
                )}
                {/* </span> */}
              </li>
            ))}
          </ul>
          <ul>
            {registrationLink(text, entities)?.map((v) => (
              <li>
                <span>
                  {v}{" "}
                  {preview && (
                    <button
                      onClick={() => {
                        textSelection({
                          type: "registration_link",
                          text: v,
                        });
                      }}
                      style={{ display: "block", color: "black" }}
                    >
                      RegLink
                    </button>
                  )}
                </span>
              </li>
            ))}
          </ul>
          <ul>
            {predictOrganizers(text, organizers?.data).map(({ name, link }) => (
              <li>
                <span>
                  {name}
                  {preview && (
                    <button
                      onClick={() => {
                        textSelection({
                          type: "organizer",
                          text: {
                            name,
                            url: link,
                          },
                        });
                      }}
                      style={{ display: "block", color: "black" }}
                    >
                      Organizer
                    </button>
                  )}
                </span>
              </li>
            ))}
          </ul>
          <ul>
            {predictFormat(text).map((format) => (
              <li>
                <span>
                  {format}
                  {preview && (
                    <button
                      onClick={() => {
                        textSelection({
                          type: "format",
                          text: format,
                        });
                      }}
                      style={{ display: "block", color: "black" }}
                    >
                      format
                    </button>
                  )}
                </span>
              </li>
            ))}
          </ul>
          <ul>
            {Object.entries(foundItems).map(([type, amount]) => (
              <li>
                {type}: {amount}
              </li>
            ))}
          </ul>
          <small>{id}</small> <br />
          <small>{moment(createdAt).calendar()}</small> <br />
          {preview && html && (
            <Switch
              checked={markUp}
              checkedChildren={"Text"}
              unCheckedChildren={"HTML"}
              onClick={(e) => {
                setMarkUp(e);
              }}
            />
          )}
          {preview && (
            <Switch
              checked={translate.e}
              checkedChildren={"Uz"}
              unCheckedChildren={"Auto"}
              onClick={(e) => {
                // const text = "Hello World";
                if (!translate.text && e) {
                  translateText(text);
                }
                setTranslate({
                  ...translate,
                  translate: e,
                });
              }}
            />
          )}
          <br />
          {preview && markUp ? (
            html || text
          ) : (
            <div
            // style={{ display: "flex" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    // (text.length > 250 && !preview
                    //   ? `${(html || text)?.slice(0, 250)} ...`
                    //   : html || text) +
                    highlightPattern(
                      translate.translate ? translate.text : html || text,
                      actions,
                      setActions,
                      foundItems,
                      setFoundItems
                      // hashtags,
                      // setHashtags
                    ) +
                    `\n\nKanal: <a href="https://t.me/${channel?.username}" target="_blank" rel="noreferrer">${channel?.title}</a>`,
                }}
              />
              {preview && (
                <button
                  style={{ display: "block", color: "black" }}
                  onClick={() => {
                    textSelection({
                      type: "organizer",
                      text: {
                        name: channel?.title,
                        url: `https://t.me/${channel?.username}`,
                      },
                    });
                  }}
                >
                  Organizer
                </button>
              )}
            </div>
          )}
          {/* {(html || text).length > 250 && !preview
            ? `${(html || text)?.slice(0, 250)} ...`
            : html || text} */}
          <br />
          <ViewBtn
            // href={`tg://c/${fromId?.slice(4)}/${msgId}`}
            href={`tg://privatepost?channel=${fromId?.slice(
              4
            )}&post=${msgId}&single`}
            target={"_blank"}
            rel="noreferrer"
          >
            View
          </ViewBtn>
          {!preview && (
            <MakePostBtn
              onClick={() =>
                setMakePost({
                  popUp: true,
                  data: {
                    createdAt,
                    fromId,
                    id,
                    isActive,
                    msgId,
                    photoId,
                    senderId,
                    statusType,
                    text,
                    updatedAt,
                    videoId,
                    html,
                    markdown,
                    entities: JSON.stringify(entities),
                  },
                })
              }
            >
              Post yasash
            </MakePostBtn>
          )}
        </TextBox>
        {!preview && (
          <ActionWrapperCorner
            onMouseOver={() => {
              setActionOpen(true);
            }}
            onMouseOut={() => {
              setActionOpen(false);
            }}
          >
            <ActionBtnDots
              onClick={() => {
                setActionOpen(true);
              }}
            >
              {statusType === "EVENT" ? (
                <YesIcon />
              ) : statusType === "NOT_EVENT" ? (
                <NoIcon />
              ) : statusType === "REPEAT" ? (
                <RepeatIcon />
              ) : (
                <MenuIcon />
              )}
            </ActionBtnDots>
            {actionOpen && (
              <ActionListBtn>
                <ActionBtn
                  onClick={() => {
                    !changeStatusTypeProcess.loading &&
                      changeStatusType({ id, statusType: "EVENT" });
                  }}
                >
                  <YesIcon />
                </ActionBtn>
                <ActionBtn
                  onClick={() => {
                    !changeStatusTypeProcess.loading &&
                      changeStatusType({ id, statusType: "NOT_EVENT" });
                  }}
                >
                  <NoIcon />
                </ActionBtn>
                <ActionBtn
                  onClick={() => {
                    !changeStatusTypeProcess.loading &&
                      changeStatusType({ id, statusType: "REPEAT" });
                  }}
                >
                  <RepeatIcon />
                </ActionBtn>
                <Popover
                  // color={"black"}
                  content={
                    <div style={{ textAlign: "center" }}>
                      <div>rostanham buni uchirmoqchimisiz</div>
                      <button
                        onClick={() => {
                          deletePost(id);
                          setActionOpen(false);
                        }}
                      >
                        delete
                      </button>
                    </div>
                  }
                >
                  {/* <Action deleteIcon>
                  <Delete />
                </Action> */}
                  <ActionBtn>
                    <TrashIcon />
                  </ActionBtn>
                </Popover>
              </ActionListBtn>
            )}
          </ActionWrapperCorner>
        )}
        {selection.popUp && (
          <SelectionWrapper
            ref={selectionRef}
            // onDrag={(e) => {
            //   selectionRef.current.style.display = "none";
            //   const x = e.clientX;
            //   const y = e.clientY;
            //   // const { offsetTop, offsetLeft } = e.target;
            //   if (x && y) {
            //     setSelection((prev) => ({
            //       ...prev,
            //       x: x - (dragStart.x - dragStart.offsetLeft),
            //       y: y - (dragStart.y - dragStart.offsetTop),
            //     }));
            //     console.log(
            //       "drag: ",
            //       // offsetTop, offsetLeft,
            //       y,
            //       x
            //     );
            //   }
            // }}
            // onDragEnd={(e) => {
            //   selectionRef.current.style.display = "flex";
            //   //   const x = e.clientX;
            //   //   const y = e.clientY;
            //   //   const { offsetTop, offsetLeft } = e.target;
            //   //   console.log("dragEnd: ", offsetTop, offsetLeft, y, x);
            //   //   setSelection((prev) => ({
            //   //     ...prev,
            //   //     x: x - (dragStart.x - dragStart.offsetLeft),
            //   //     y: y - (dragStart.y - dragStart.offsetTop),
            //   //   }));
            // }}
            // onDragStart={(e) => {
            //   const x = e.clientX;
            //   const y = e.clientY;
            //   const { offsetTop, offsetLeft } = e.target;
            //   setDragStart({
            //     offsetTop,
            //     offsetLeft,
            //     x,
            //     y,
            //   });
            //   console.log("dragStart: ", offsetTop, offsetLeft, y, x);
            //   // selectionRef.current.style.display = "none";
            // }}
            // draggable
            x={selection.x}
            y={selection.y}
            onMouseDown={(e) => {
              const x = e.clientX;
              const y = e.clientY;
              const { offsetTop, offsetLeft } = selectionRef.current;
              setMouseDown({ x, y, offsetLeft, offsetTop });
              // console.log("Mouse Down: ", e, x, y, offsetTop, offsetLeft);
            }}
            onMouseUp={(e) => {
              // const x = e.clientX;
              // const y = e.clientY;
              // const { offsetTop, offsetLeft } = selectionRef.current;
              setMouseDown({ x: 0, y: 0, offsetLeft: 0, offsetTop: 0 });
              // console.log("Mouse Up: ", e, x, y, offsetTop, offsetLeft);
            }}
            onMouseMove={(e) => {
              if (
                mouseDown.x ||
                mouseDown.y ||
                mouseDown.offsetLeft ||
                mouseDown.offsetTop
              ) {
                const x = e.clientX;
                const y = e.clientY;
                // const { offsetTop, offsetLeft } = selectionRef.current;
                setSelection((prev) => ({
                  ...prev,
                  x: x - (mouseDown.x - mouseDown.offsetLeft),
                  y: y - (mouseDown.y - mouseDown.offsetTop),
                }));
                // console.log("Mouse Move: ", e, x, y, offsetTop, offsetLeft);
              }
            }}
          >
            {/* <SelectionHeader></SelectionHeader> */}
            {selection?.list?.map(({ text, value }, index) => (
              <SelectItem
                key={index}
                onClick={() => {
                  textSelection({ type: value, text: selection?.text });
                  setSelection({ popUp: false, text: "", x: 0, y: 0 });
                }}
              >
                {text}
              </SelectItem>
            ))}
          </SelectionWrapper>
        )}
        {/* Number of words: {analyzeText(text)?.length} <br />
        {analyzeText(text)?.map((v) => (
          <small>{v} </small>
        ))} */}
      </Wrapper>
    </MainWrapper>
  ) : (
    <></>
  );
}

export default PostCard;
