import React from "react";

import { ReactComponent as ErrorSign } from "../../assets/icons/error-sign.svg";
import { ErrorSignBox, ErrorText, ErrorWrapper } from "./styles";

function ErrorBox({ close }) {
  return (
    <ErrorWrapper>
      <ErrorSignBox>
        <ErrorSign />
      </ErrorSignBox>
      <ErrorText>Xatolik yuzaga keldi</ErrorText>
      {close && <button onClick={close}>Close</button>}
    </ErrorWrapper>
  );
}

export default ErrorBox;
