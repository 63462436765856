import axios from "axios";
import { BASE_URL } from "../constants/link";

export const httpRequest = ({ method, data, path, url, params, headers }) => {
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");
  return axios({
    method,
    data,
    url: url ? url : `${BASE_URL}/${path}`,
    params,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
