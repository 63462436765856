import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

export const getEvents = ({ fromDate, toDate, format, status }) => ({
  type: actionTypes.GET_EVENTS,
  payload: httpRequest({
    method: "GET",
    path: "event/list",
    params: {
      fromDate,
      toDate,
      format,
      status,
    },
  }),
});
