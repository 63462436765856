import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

export const getBotUsersStat = () => ({
  type: actionTypes.GET_BOT_USERS_STAT,
  payload: httpRequest({
    method: "GET",
    path: "dashboard/bot-users-stat",
  }),
});
