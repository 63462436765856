import styled from "styled-components";

export const UsersPageWrapper = styled.div`
  width: 100%;
  padding: 20px;
`;

export const UsersTable = styled.table`
  width: 100%;
  color: white;
  /* border-collapse: collapse; */
  border-spacing: 0px 10px;
  tr {
    margin: 10px 0;
    th,
    td {
      padding: 20px;
      background-color: #182335;
      &:first-child {
        border-radius: 15px 0 0 15px;
      }
      &:last-child {
        border-radius: 0 15px 15px 0;
      }
    }
  }
`;
