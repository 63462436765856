import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

export const getAddresses = () => ({
  type: actionTypes.GET_ADDRESSES,
  payload: httpRequest({
    method: "GET",
    path: "address",
  }),
});
