import { Link } from "react-router-dom";
import styled from "styled-components"

export const SidebarWrapper = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 15px;
`;

export const LogoBox = styled(Link)`
  width: 100%;
  height: 80px;
  font-size: 32px;
  display: flex;
  align-items: center;
  padding: 0px 25px;
  font-weight: bold;
  color: white;
`;

export const NavsWrapper = styled.div`
  width: 100%;
`;

export const NavsBox = styled.div`
  width: 100%;
`;


export const NavBox = styled(Link)`
  width: 100%;
  margin: 5px 0;
  background-color: ${({active})=>active?"#6045e2":"transparent"};
  color: white;
  padding: 15px 25px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg,path{
      margin-right: 10px;
      stroke: white;
  }
  &:hover{
      background-color: ${({active})=>active?"#6045e2":"#6045e277"};
  }
`;