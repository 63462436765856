import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

export const getOrganizers = () => ({
  type: actionTypes.GET_ORGANIZERS,
  payload: httpRequest({
    method: "GET",
    path: "organizer",
  }),
});
