import styled from "styled-components";

export const JamesBondWrapper = styled.div`
  width: 100%;
  padding: 15px 25px;
`;

export const ReservedWordsWrapper = styled.div`
  width: 30%;
  background-color: #182335;
  padding: 10px;
  border-radius: 10px;
`;

export const WordsWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const Word = styled.div`
  margin: 0 8px 0 0;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  /* border: 1px solid black; */
  padding: 0px 10px;
  background-color: lightgrey;
  border-radius: 10px;
  span {
    display: flex;
    align-items: center;
  }
  &:hover {
    border-radius: 10px 0 0 10px;
    .x {
      display: block;
    }
  }
`;

export const DeleteWord = styled.div`
  height: 100%;
  position: absolute;
  right: -26px;
  top: 0;
  bottom: 0;
  background-color: red;
  padding: 1px;
  border-radius: 0 10px 10px 0;
  z-index: 999;
  display: none;
  svg {
    height: 100%;
  }
`;
