import { Radio, Select } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import EditEventPopUp from "../../components/editEventPopUp";
import ErrorBox from "../../components/errorBox";
import LoadingBox from "../../components/loadingBox";
import useHttpRequest from "../../hooks/useHttpRequest";
import { getCategories } from "../../redux/modules/getCategories";

const { Option } = Select;

function AddEventPage() {
  const dispatch = useDispatch();
  const [addPopUp, setAddPopUp] = useState(true);
  const [edit, setEdit] = useState({ edit: false, data: {} });
  const [searchParams] = useSearchParams();


  const [submitProcess, setSubmitProcess] = useState({
    loading: false,
    success: false,
    error: false,
  });

  const [checkUserProcess, makeCheckUserRequest] = useHttpRequest({
    onSuccess: (res) => {
      console.log(res);
      const { userExist, unfinishedEvents: events } = res.data;
      if (userExist && events.length > 0) {
        setEdit({ edit: true, data: events[events.length - 1] });
      }
    },
  });

  useEffect(() => {
    dispatch(getCategories());
    const chatId = searchParams.get("chatId");
    const secretKey = searchParams.get("secretKey");

    if (chatId && secretKey) {
      makeCheckUserRequest({
        method: "GET",
        path: "check-user",
        params: {
          chatId,
          secretKey,
        },
      });
    }
  }, []);

  const onChange = () => {};

  return (
    <div style={{ height: "100vh" }}>
      {submitProcess.loading && <LoadingBox />}
      {submitProcess.error && (
        <ErrorBox
          close={() =>
            setSubmitProcess({
              loading: false,
              success: false,
              error: false,
            })
          }
        />
      )}
      {checkUserProcess.loading ? (
        <LoadingBox />
      ) : checkUserProcess.success ? (
        <>
          {checkUserProcess?.data?.userExist &&
            checkUserProcess?.data?.unfinishedEvents.length > 1 && (
              <>
                <Select
                  style={{
                    position: "relative",
                    zIndex: 99,
                    width: "300px",
                    backgroundColor: "white",
                  }}
                  value={edit.edit ? edit.data.id : null}
                >
                  {checkUserProcess?.data?.unfinishedEvents.map(({ id }) => (
                    <Option value={id} key={id}>
                      {id}
                    </Option>
                  ))}
                </Select>
              </>
            )}
          <EditEventPopUp
            addPopUp={addPopUp}
            setAddPopUp={setAddPopUp}
            edit={edit}
            setEdit={setEdit}
            addEvent
            chatId={searchParams.get("chatId")}
          />
        </>
      ) : checkUserProcess.error ? (
        <ErrorBox />
      ) : (
        ""
      )}
      {/* <EditEventPopUp
        addPopUp={addPopUp}
        setAddPopUp={setAddPopUp}
        edit={edit}
        setEdit={setEdit}
        addEvent
        chatId={searchParams.get("chatId")}
        setSubmitProcess={setSubmitProcess}
      /> */}
    </div>
  );
}

export default AddEventPage;
