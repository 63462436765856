import { Button, Input } from "antd";
import useSelection from "antd/lib/table/hooks/useSelection";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReservedWords } from "../../redux/modules/getReservedWords";
import { httpRequest } from "../../utils/httpRequest";
import {
  DeleteWord,
  ReservedWordsWrapper,
  Table,
  Word,
  WordsWrapper,
  Wrapper,
} from "./styles";

import randomColor from "randomcolor";

import { ReactComponent as X } from "../../assets/icons/x.svg";
import { getPosts } from "../../redux/modules/getPosts";
import useHttpRequest from "../../hooks/useHttpRequest";

import names from "../../assets/data/names.json";
import testFullNames from "../../assets/data/testFullNames.json";

import stringSimilarity from "string-similarity";

function TRow({ channelId, amount, index }) {
  const [channelDataProcess, channelDataRequest] = useHttpRequest({
    onSuccess: (res) => {
      console.log(res.data);
    },
  });
  useEffect(() => {
    channelDataRequest({
      method: "GET",
      path: "telegram/profile",
      params: {
        chatId: channelId,
      },
    });
  }, []);

  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        {channelDataProcess?.data?.title &&
        channelDataProcess?.data?.username ? (
          <a href={`https://t.me/${channelDataProcess?.data?.username}`}>
            {channelDataProcess?.data?.title}
          </a>
        ) : (
          channelId
        )}
      </td>
      <td>{amount}</td>
    </tr>
  );
}

function AnalyzeEventsPage() {
  const dispatch = useDispatch();
  const [word, setWord] = useState();
  const [createdBefore, setCreatedBefore] = useState(new Date(Date.now()));
  const [size, setSize] = useState(1000);

  const state = useSelector((state) => state);
  const posts = state.posts;

  // useEffect(() => {
  //   dispatch(getPosts({ statusType: "EVENT", createdBefore, page: 0, size }));
  // }, [size]);
  const [commonWords, setCommonWords] = useState({});
  const [channels, setChannels] = useState({});

  useEffect(() => {
    setCommonWords({});
    setChannels({});
    posts.data?.forEach(
      ({
        createdAt,
        entities,
        event_datum,
        fromId,
        html,
        id,
        isActive,
        markdown,
        msgId,
        photoId,
        senderId,
        statusType,
        text,
        updatedAt,
        videoId,
      }) => {
        setChannels((prev) => ({
          ...prev,
          [fromId]: prev[fromId] ? prev[fromId] + 1 : 1,
        }));
        const words = text.split(/[|,.-\s]/);
        words.forEach((word) => {
          if (
            !["", "va"].includes(word) &&
            !/[ЁёА-я]/.test(word) &&
            !/:[^:\s]+:\d+>|<a:[^:\s]+:\d+>|(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|\ufe0f)/g.test(
              word
            )
          ) {
            setCommonWords((prev) => ({
              ...prev,
              [word]: prev[word] ? prev[word] + 1 : 1,
            }));
          }
        });
      }
    );
  }, [posts]);

  return (
    <Wrapper>
      <input
        type={"number"}
        value={size}
        onChange={(e) => {
          setSize(e.target.value);
        }}
      />

      <Table>
        <thead>
          <tr>
            <th>Index</th>
            <th>FullName</th>
            <th>PredictionFN</th>
            <th>PredictionORG</th>
          </tr>
        </thead>
        <tbody>
          {testFullNames
            .map((name) => {
              const [n1, n2] = name.split(" ");
              const {bestMatch:{target:target1,rating:rating1}} = stringSimilarity.findBestMatch(n1, names);
              const {bestMatch:{target:target2,rating:rating2}} = stringSimilarity.findBestMatch(n2, names);

              // console.log(
              //   "BestMatch: ",
              //   n1,
              //   bestMatch1.bestMatch,
              //   n2,
              //   bestMatch2.bestMatch,
              //   (
              //     bestMatch1.bestMatch.rating + bestMatch2.bestMatch.rating
              //   ).toFixed(2)
              // );
              return {
                predictedFN: `${target1} ${rating1.toFixed(2)} ${target2} ${rating2.toFixed(2)}`,
                predictedORG: ``,
                name,
              };
            })
            ?.map(({name,predictedFN,predictedORG}, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{name}</td>
                <td>{predictedFN}</td>
                <td>{predictedORG}</td>
              </tr>
            ))}
        </tbody>
      </Table>

      {/* <Table>
        <thead>
          <tr>
            <th>Index</th>
            <th>textLength</th>
            <th>Text</th>
          </tr>
        </thead>
        <tbody>
          {posts?.data
            ?.sort(({ text: tA }, { text: tB }) => tA?.length - tB?.length)
            ?.map(
              (
                {
                  createdAt,
                  entities,
                  event_datum,
                  fromId,
                  html,
                  id,
                  isActive,
                  markdown,
                  msgId,
                  photoId,
                  senderId,
                  statusType,
                  text,
                  updatedAt,
                  videoId,
                },
                index
              ) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{text.length}</td>
                  <td>
                    <p className="text-part">{text}</p>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </Table> */}

      {/* <Table>
        <thead>
          <tr>
            <th>Index {Object.keys(channels).length}</th>
            <th>ChannelId</th>
            <th>Amount {Object.values(channels).reduce((t, v) => t + v, 0)}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(channels)
            .map(([key, value]) => ({
              channelId: key,
              amount: value,
            }))
            .sort((a, b) => b.amount - a.amount)
            .map(({ channelId, amount }, index) => (
              <TRow index={index} channelId={channelId} amount={amount} />
            ))}
        </tbody>
      </Table>
      <Table>
        <thead>
          <tr>
            <th>Index {Object.keys(commonWords)?.length}</th>
            <th>Word</th>
            <th>usedTimes</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(commonWords)
            ?.map(([key, value]) => ({
              word: key,
              usedTimes: value,
            }))
            .sort((a, b) => b.usedTimes - a.usedTimes)
            .map(({ word, usedTimes }, index) => (
              <tr>
                <td>{index}</td>
                <td>{word}</td>
                <td>{usedTimes}</td>
              </tr>
            ))}
        </tbody>
      </Table> 
      <Table>
        <thead>
          <tr>
            <th>Index</th>
            <th>textLength</th>
            <th>Text</th>
          </tr>
        </thead>
        <tbody>
          {posts?.data
            ?.sort(({ text: tA }, { text: tB }) => tA?.length - tB?.length)
            ?.map(
              (
                {
                  createdAt,
                  entities,
                  event_datum,
                  fromId,
                  html,
                  id,
                  isActive,
                  markdown,
                  msgId,
                  photoId,
                  senderId,
                  statusType,
                  text,
                  updatedAt,
                  videoId,
                },
                index
              ) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{text.length}</td>
                  <td>
                    <p className="text-part">{text}</p>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </Table> 
      */}
    </Wrapper>
  );
}

export default AnalyzeEventsPage;
