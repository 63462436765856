import styled from "styled-components";

export const MainPageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const SidebarPart = styled.div`
  width: 300px;
  height: 100%;
  overflow: auto;
  background-color: #182335;
`;

export const ContentPart = styled.div`
  width: calc(100% - 300px);
  height: 100%;
  overflow: auto;
  /* background-color: lightcyan; */
  /* padding: 20px 30px; */
`;
