import React, { useEffect } from "react";

import active from "../../assets/icons/switch-on.png";
import inactive from "../../assets/icons/switch-off.png";
import { Avatar, Row } from "./styles";
import useHttpRequest from "../../hooks/useHttpRequest";
import randomColor from "randomcolor";
import { useState } from "react";
import { BASE_URL } from "../../constants/link";
import moment from "moment";

function UserTableRow({
  changingEventId,
  createdAt,
  creatingEventId,
  fullName,
  id,
  isActive,
  phoneNumber,
  state,
  updatedAt,
}) {
  const [profileProcess, makeProfileRequest] = useHttpRequest();
  // const [avatarProcess, makeAvatarRequest] = useHttpRequest();
  const [color, setColor] = useState("");

  // useEffect(() => {
  //   if (profileProcess?.data?.photo?.small_file_id) {
  //     makeAvatarRequest({
  //       method: "GET",
  //       path: `attachment/tg-url`,
  //       params: {
  //         file_id: profileProcess.data.photo.small_file_id,
  //       },
  //     });
  //   }
  // }, [profileProcess.success]);

  useEffect(() => {
    makeProfileRequest({
      method: "GET",
      path: `telegram/profile`,
      params: {
        chatId: id,
      },
    });
    setColor(randomColor());
  }, []);

  return (
    <Row>
      <td>
        <Avatar style={{ backgroundColor: color }}>
          {
          // avatarProcess?.data
          profileProcess?.data?.photo?.small_file_id
           ? (
            <img src={`${BASE_URL}/attachment/tg-url/${profileProcess?.data?.photo?.small_file_id}`} alt="" />
          ) : (
            <span>{profileProcess?.data?.first_name?.charAt(0)}</span>
          )}
        </Avatar>
      </td>
      <td className="mw-100px">
        {fullName} <br />
        {profileProcess?.data?.first_name} {profileProcess?.data?.last_name} <br />
        {moment(createdAt).calendar()}
      </td>
      <td>
        {profileProcess?.data?.username ? (
          <a
            target={"_blank"}
            href={`https://t.me/${profileProcess?.data?.username}`}
            rel="noreferrer"
          >
            @{profileProcess?.data?.username}
          </a>
        ) : (
          ""
        )}{" "}
        <br />
        {phoneNumber}
      </td>
      <td>
        <img src={isActive ? active : inactive} alt="" />
      </td>
    </Row>
  );
}

export default UserTableRow;
