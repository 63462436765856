import { Button, Input } from "antd";
import useSelection from "antd/lib/table/hooks/useSelection";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReservedWords } from "../../redux/modules/getReservedWords";
import { httpRequest } from "../../utils/httpRequest";
import {
  DeleteWord,
  JamesBondWrapper,
  ReservedWordsWrapper,
  Word,
  WordsWrapper,
} from "./styles";

import randomColor from "randomcolor";

import { ReactComponent as X } from "../../assets/icons/x.svg";

function JamesBondPage() {
  const dispatch = useDispatch();
  const [word, setWord] = useState();

  const state = useSelector((state) => state);
  const reservedWords = state.reservedWords;

  const addWord = (e) => {
    e.preventDefault();
    console.log(word);
    if (word) {
      httpRequest({
        method: "POST",
        path: "reserved-words",
        data: {
          word,
        },
      })
        .then((res) => {
          console.log(res);
          dispatch(getReservedWords());
          setWord("");
        })
        .catch(() => {});
    }
  };

  const deleteById = (id) => {
    httpRequest({
      method: "DELETE",
      path: `reserved-words/${id}`,
    }).then(() => {
      dispatch(getReservedWords());
    });
  };

  useEffect(() => {
    dispatch(getReservedWords());
  }, []);

  return (
    <JamesBondWrapper>
      <ReservedWordsWrapper>
        <form onSubmit={addWord}>
          <Input.Group compact style={{ width: "100%" }}>
            <Input
              value={word}
              onChange={(e) => {
                setWord(e.target.value);
              }}
              placeholder="Type word"
              style={{ width: "calc(100% - 100px)" }}
            />
            <Button type="primary" style={{ width: "100px" }}>
              Submit
            </Button>
          </Input.Group>
        </form>
        {/* <Input value={"hello"} /> */}
        <WordsWrapper>
          {reservedWords?.data?.map(({ id, word }, index) => {
            const backgroundColor = randomColor({ luminosity: "dark" });
            return (
              <Word style={{ backgroundColor }} key={index}>
                <span>{word}</span>
                <DeleteWord onClick={() => deleteById(id)} className="x">
                  <X />
                </DeleteWord>
              </Word>
            );
          })}
        </WordsWrapper>
      </ReservedWordsWrapper>
      JamesBondPage
    </JamesBondWrapper>
  );
}

export default JamesBondPage;
