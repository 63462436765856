import logo from "./logo.svg";
import MainPage from "./pages/mainPage";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import "antd/dist/antd.css";
import Index from "./components/chartLogic";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MainPage />
        {/* <Index /> */}
      </BrowserRouter>
    </Provider>
  );
}

export default App;
