import styled, { keyframes } from "styled-components";

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: lightcoral;
  position: relative;
  z-index: 9999;
`;

const animation = keyframes`
   from{
    transform: rotate(0deg);
   }
   to{
    transform: rotate(360deg);
   }
`;

export const LoadingSignBox = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid white;
  border-top-color: transparent;
  animation-name: ${animation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export const LoadingText = styled.p`
  margin: 30px 0 0;
`;
