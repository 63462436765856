import { useDispatch } from "react-redux";
import { actionTypes } from "../../constants/actionTypes";
import { httpRequest } from "../../utils/httpRequest";

const defaultValue = {
  loading: false,
  error: false,
  success: false,
  data: null,
};

export const promiseReducer =
  (actionConst) =>
  (state = defaultValue, action) => {
    // const dispatch = useDispatch();
    switch (action.type) {
      case `${actionConst}${actionTypes._PENDING}`:
        return {
          loading: true,
          error: false,
          success: false,
          data: state.data,
        };
      case `${actionConst}${actionTypes._FULFILLED}`:
        return {
          loading: false,
          error: false,
          success: true,
          data: action.payload.data,
        };
      case `${actionConst}${actionTypes._REJECTED}`:
        const data = action.payload.response.data;
        const name = data?.name;
        const message = data?.message;
        if (name === "TokenExpiredError" && message === "jwt expired") {
          const refreshToken = localStorage.getItem("refresh_token");
          if (refreshToken) {
            httpRequest({
              method: "PUT",
              path: "refresh-token",
              data: { refreshToken },
            })
              .then((res) => {
                console.log("====================================");
                console.log(res);
                console.log("====================================");
                const { accessToken, refreshToken } = res.data;
                localStorage.setItem("access_token", accessToken);
                localStorage.setItem("refresh_token", refreshToken);
                window.location.reload();
              })
              .catch((err) => {
                console.log("====================================");
                console.log(err);
                console.log("====================================");
              });
          }
          console.log("====================================");
          console.log("Error: ", action);
          console.log("====================================");
        }
        return {
          loading: false,
          error: true,
          success: false,
          data: [],
        };
      default:
        return state;
    }
  };
