import styled from "styled-components";

export const AddressesPageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

export const MapWrapper = styled.div`
  width: calc(100% - 270px);
`;

export const MapRightBar = styled.div`
  width: 250px;
  height: calc(100vh - 40px);
  overflow: auto;
  /* height: fit-content; */
`;

export const Form = styled.form`
  width: 100%;
  background-color: #182335;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 20px;
  p.title {
    font-weight: bold;
    color: white;
  }
`;

export const InputBox = styled.div`
  margin: 10px 0;
  display: flex;
`;

export const ImageBoxWrapper = styled.div`
  width: 100%;
  height: 150px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  .img-wrapper {
    width: fit-content;
    height: fit-content;
    position: relative;
  }
`;

export const CloseBox = styled.div`
  width: 40px;
  height: 40px;
  border: 2px solid red;
  position: absolute;
  top: -20px;
  right: -20px;
  border-radius: 20px;
  background-color: white;
  padding: 5px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    stroke: red;
  }
`;

export const Label = styled.label`
  width: 60px;
  height: 60px;
  /* background-color: red; */
  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: white;
    }
  }
  input {
    display: none;
  }
`;

export const AddressesWrapper = styled.div`
  width: 100%;
  background-color: #182335;
  border-radius: 20px;
  padding: 15px;
`;

export const AddressBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  background-color: ${({ active }) => (active ? "black" : "#00000040")};
  .action {
    display: ${({ active }) => (active ? "flex" : "none")};
  }

  /* &:hover {
    background-color: black;
    .action {
      display: flex;
    }
  } */

  .logo {
    width: 50px;
    height: auto;
    border-radius: 10px;
  }
  .address-name {
    width: calc(100% - 60px);
  }
`;

export const ActionBox = styled.div`
  display: none;
  position: absolute;
  top: -15px;
  right: -15px;
`;

export const ActionButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: white;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid lightgreen;
  display: flex;
  margin-left: 5px;
  svg {
    width: 100%;
    height: 100%;
    margin: auto;
    path {
      stroke: lightgreen;
    }
  }
`;

export const EditButton = styled(ActionButton)`
  border-color: #02b902;
  &:hover {
    background-color: #02b902;
    svg {
      path {
        stroke: white;
      }
    }
  }
  svg {
    path {
      stroke: #02b902;
    }
  }
`;

export const DeleteButton = styled(ActionButton)`
  border-color: red;
  &:hover {
    background-color: red;
    svg {
      path,
      line {
        stroke: white;
      }
    }
  }
  svg {
    path,
    line {
      stroke: red;
    }
  }
`;


export const SwitchBox = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
`;