import { Button, Checkbox, Input } from "antd";
import useSelection from "antd/lib/table/hooks/useSelection";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReservedWords } from "../../redux/modules/getReservedWords";
import { httpRequest } from "../../utils/httpRequest";
import {
  ActionBox,
  CloseBox,
  DeleteButton,
  DeleteWord,
  EditButton,
  Form,
  ImageBoxWrapper,
  InputBox,
  JamesBondWrapper,
  Label,
  OrganizerBox,
  OrganizerListWrapper,
  OrganizerWrapper,
  ReservedWordsWrapper,
  SwitchBox,
  Word,
  WordsWrapper,
} from "./styles";

import randomColor from "randomcolor";

import { ReactComponent as AddImg } from "../../assets/icons/add-image.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as X } from "../../assets/icons/x.svg";
import { BASE_URL } from "../../constants/link";
import Resizer from "react-image-file-resizer";
import useHttpRequest from "../../hooks/useHttpRequest";
import { getOrganizers } from "../../redux/modules/getOrganizers";

function OrganizersPage() {
  const dispatch = useDispatch();
  const [word, setWord] = useState();

  const [organizerName, setOrganizerName] = useState("");
  const [organizerLink, setOrganizerLink] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const [edit, setEdit] = useState({ edit: false, data: {} });

  const state = useSelector((state) => state);
  const organizers = state.organizers;
  // const reservedWords = state.reservedWords;

  const [submitImageProcess, makeSubmitImageRequest] = useHttpRequest({
    onSuccess: (res) => {
      setImgUrl(res.data);
    },
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setImgUrl(uri);
        },
        "file"
      );
    });

  const submitImage = (file) => {
    const formData = new FormData();
    formData.append("image", file);

    makeSubmitImageRequest({
      method: "POST",
      path: "attachment",
      data: formData,
    });
  };

  const [submitOrganizerProcess, makeSubmitOrganizerRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(getOrganizers());
      setEdit({ edit: false, data: {} });
      setOrganizerName("");
      setOrganizerLink("");
      setImgUrl("");
    },
  });

  const submitOrganizer = () => {
    if (organizerName && organizerLink) {
      const data = {
        name: organizerName,
        link: organizerLink,
        logoImgId: imgUrl,
      };
      if (edit.edit) {
        makeSubmitOrganizerRequest({
          method: "PUT",
          path: `organizer/${edit.data.id}`,
          data,
        });
      } else {
        makeSubmitOrganizerRequest({
          method: "POST",
          path: "organizer",
          data,
        });
      }
    }
  };

  const [switchOrganizerProcess, makeSwitchOrganizerRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(getOrganizers());
    },
  });

  const switchOrganizer = (id, toggle) => {
    makeSwitchOrganizerRequest({
      method: "PUT",
      path: `organizer/${!toggle ? "in" : ""}activate/${id}`,
    });
  };

  const deleteById = (id) => {
    httpRequest({
      method: "DELETE",
      path: `reserved-words/${id}`,
    }).then(() => {
      dispatch(getReservedWords());
    });
  };

  useEffect(() => {
    dispatch(getOrganizers());
  }, []);

  return (
    <OrganizerWrapper>
      <ReservedWordsWrapper>
        <Form>
          <p className="title">Add new organizer</p>
          <InputBox>
            <Input
              placeholder="Name"
              value={organizerName}
              onChange={(e) => {
                setOrganizerName(e.target.value);
              }}
            />
          </InputBox>
          <InputBox>
            <Input
              placeholder="Link"
              value={organizerLink}
              onChange={(e) => {
                setOrganizerLink(e.target.value);
              }}
            />
          </InputBox>

          <InputBox>
            <ImageBoxWrapper>
              {imgUrl ? (
                <div className="img-wrapper">
                  <CloseBox
                    onClick={() => {
                      setImgUrl("");
                    }}
                  >
                    <X />
                  </CloseBox>
                  <img src={`${BASE_URL}/attachment/${imgUrl}`} alt="" />
                </div>
              ) : (
                <Label>
                  <AddImg />
                  <input
                    type="file"
                    onChange={async (e) => {
                      submitImage(await resizeFile(e.target.files[0]));
                    }}
                  />
                </Label>
              )}
            </ImageBoxWrapper>
          </InputBox>
          <Button onClick={submitOrganizer}>
            {edit.edit ? "Edit" : "Submit"}
          </Button>
          {edit.edit && (
            <Button
              onClick={() => {
                setEdit({ edit: false, data: {} });
                setOrganizerName("");
                setOrganizerLink("");
                setImgUrl("");
              }}
            >
              Cancel
            </Button>
          )}
        </Form>

        <OrganizerListWrapper>
          {organizers?.data?.map(({ id, logoImgId, name, link, isActive }) => (
            <OrganizerBox
              key={id}
              onMouseOver={() => {
                // setSelectedLocation(id);
              }}
              onMouseOut={() => {
                // setSelectedLocation("");
              }}
              // active={
              //   selectedLocation === id || (edit.edit && edit.data.id === id)
              // }
            >
              <SwitchBox>
                <Checkbox
                  checked={isActive}
                  onChange={(e) => {
                    console.log(e);
                    switchOrganizer(id, e.target.checked);
                  }}
                />
              </SwitchBox>
              <ActionBox className="action">
                <EditButton
                  onClick={() => {
                    setEdit({
                      edit: true,
                      data: { id, logoImgId, name, link },
                    });
                    setOrganizerName(name);
                    setOrganizerLink(link);
                    setImgUrl(logoImgId);
                  }}
                >
                  <Edit />
                </EditButton>
                <DeleteButton>
                  <X />
                </DeleteButton>
              </ActionBox>
              <img
                className="logo"
                src={`${BASE_URL}/attachment/${logoImgId}`}
                alt=""
              />
              <div className="address-name">
                <a href={link} target="_blank" rel="noreferrer">
                  {name}
                </a>
              </div>
            </OrganizerBox>
          ))}
        </OrganizerListWrapper>
      </ReservedWordsWrapper>
    </OrganizerWrapper>
  );
}

export default OrganizersPage;
