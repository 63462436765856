import useSelection from "antd/lib/table/hooks/useSelection";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TgChannelList from "../../components/tgChannelList";
import useHttpRequest from "../../hooks/useHttpRequest";
import { getInstagramProfile } from "../../redux/modules/getInstagramProfile";
import {
  ChannelsPageWrapper,
  IgBio,
  IgEmail,
  IgFullName,
  IgPhoneNumber,
  IgUsername,
  InstagramCardBox,
  LoginButton,
} from "./styles";

function ChannelsPage() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const igProfile = state.igProfile;

  useEffect(() => {
    dispatch(getInstagramProfile());
  }, []);

  const [loginProcess, makeLoginRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(getInstagramProfile());
    },
    onError: () => {
      dispatch(getInstagramProfile());
    },
  });

  const loginToInstagram = () => {
    makeLoginRequest({
      method: "GET",
      path: "instagram/login",
    });
  };

  return (
    <ChannelsPageWrapper>
      <TgChannelList/>

      <InstagramCardBox>
        {igProfile.loading ? (
          <div style={{width:"100%",height:"100px",display:"flex",justifyContent:"center",alignItems:"center"}}>Loading ...</div>
        ) : igProfile?.data ? (
          <>
            <IgFullName>
              {igProfile?.data?.first_name} {igProfile?.data?.last_name}
            </IgFullName>
            <IgUsername
              target={"_blank"}
              href={`https://www.instagram.com/${igProfile?.data?.username}/`}
            >
              @{igProfile?.data?.username}
            </IgUsername>
            <IgEmail href={`mailto:${igProfile?.data?.email}`}>
              {igProfile?.data?.email}
            </IgEmail>
            <IgPhoneNumber href={`tel:${igProfile?.data?.phone_number}`}>
              {igProfile?.data?.phone_number}
            </IgPhoneNumber>
            <IgBio>bio: {igProfile?.data?.biography}</IgBio>
          </>
        ) : (
          <LoginButton onClick={loginToInstagram}>
            {loginProcess.loading ? "Loading" : "Login"}
          </LoginButton>
        )}
      </InstagramCardBox>
    </ChannelsPageWrapper>
  );
}

export default ChannelsPage;