import styled from "styled-components";
import igBg from "../../assets/photo/instagram-stat-bg.png";

export const ChannelsPageWrapper = styled.div`
  width: 100%;
  padding: 15px 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const InstagramCardBox = styled.div`
  width: 30%;
  height: fit-content;
  color: white;
  /* background-color: #182335; */
  padding: 10px;
  border-radius: 10px;
  background-image: url(${igBg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-blend-mode: screen; */
  position: relative;
  z-index: 0;
  transition-duration: 0.5s;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #182335;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.75;
    z-index: -1;
  }
`;

export const IgFullName = styled.div`
 font-weight: bold;
`;

export const IgUsername = styled.a`
  display: block;
  color: white;
  width: fit-content;
`;
export const IgEmail = styled.a`
  display: block;
  color: white;
  width: fit-content;
`;
export const IgPhoneNumber = styled.a`
  display: block;
  color: white;
  width: fit-content;
`;
export const IgBio = styled.div``;

export const LoginButton = styled.div`
  background-color: white;
  color: black;
  padding: 5px 50px;
  width: fit-content;
  margin: auto;
  border-radius: 100px;
  cursor: pointer;
`;
